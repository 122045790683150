import * as React from 'react';
import NarrativeStore from 'store/narrative.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import * as Styled from 'common/listform.desktop.styled';
import { Cancel, Save, Add } from '@material-ui/icons';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { Narrative, Features, TimeKeeperAssignment } from '../../api/types/types';
import { NarrativeField } from 'components/NarrativeField';
import { theme } from 'common/theme';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { TKConsumer } from 'common/TKProvider';

interface Props {
    narrativeStore?: NarrativeStore;
    match: {
        params: {
            id: string;
        }
    }
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        narrativeStore: rootStore.narrativeStore
    };
})
@observer
export default class NarrativeForm extends React.Component<Props> {

    componentWillMount() {
        this.props.narrativeStore!.resetInvalid();
        if (this.props.match.params.id === 'new') {
            this.props.narrativeStore!.newNarrative();
        } else {
            this.props.narrativeStore!.loadNarrative(Number(this.props.match.params.id));
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (!this.props.narrativeStore!.selectedNarrative
            && this.props.match.params.id !== prevProps.match.params.id
            && this.props.match.params.id === 'new') {
            this.props.narrativeStore!.loadNarrative(Number(this.props.match.params.id));
        }
    }
    
    setKey = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        let narrative = this.props.narrativeStore!.selectedNarrative;
        narrative.key = e.target.value;
        this.props.narrativeStore!.onChange(narrative);
        this.props.narrativeStore!.invalid.key = false;
    }

    setReplacement = (text: string) => {
        let narrative = this.props.narrativeStore!.selectedNarrative;
        narrative.replacement = text;
        this.props.narrativeStore!.onChange(narrative);
        this.props.narrativeStore!.invalid.replacement = false;
    }

    onBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        if (e.target.value) {
            this.props.narrativeStore!.isKeyGlobalDuplicate(e.target.value);
        }
    }
   
    onSave = (n: Narrative) => {
        if (this.props.narrativeStore!.validateNarrative(n)) {
            if (n.id) {
                this.props.narrativeStore!.saveNarrative(n)
            } else {
                this.props.narrativeStore!.addNarrative(n, true)
            }
        }
    }

    cancel = () => {
        this.props.narrativeStore!.restoreNarrative();
    }

    render() {
        const {
            selectedNarrative,
            saveNarrative,
            restoreNarrative,
            addNarrative,
            dirty,
            invalid
        } = this.props.narrativeStore!;

        let keyValiator = invalid.key;
        let replacementValidator = invalid.replacement;

        if (!selectedNarrative) {
            return (
                <div style={{textAlign: 'center'}}>
                    <CircularProgress/>
                </div>
            );
        }

        return (
            <TKConsumer>
                { (tk: TimeKeeperAssignment) =>
            <div>
                <div>
                    {selectedNarrative.deleted && (
                        <><div style={{color: theme.error}}>This narrative is marked as deleted.</div><br/></>
                    )}
                    <TextField
                        value={selectedNarrative.key}
                        label="Narrative Code"
                        onBlur={this.onBlur}
                        onChange={this.setKey}
                        disabled={selectedNarrative.global || selectedNarrative.deleted || !tk.writable}
                        style={{marginBottom: '14px'}}
                        fullWidth={true}
                        error={keyValiator ? true : false}
                        helperText={keyValiator ? keyValiator : undefined}
                        inputProps={{
                            maxLength: 10
                        }}
                    />
                    <FeaturesConsumer>
                        { (features: Features) =>
                        <NarrativeField
                            value={selectedNarrative.replacement}
                            placeholder="Narrative Text"
                            onChange={this.setReplacement}
                            disabled={selectedNarrative.global || selectedNarrative.deleted || !tk.writable}
                            error={replacementValidator ? true : false}
                            helperText={replacementValidator ? replacementValidator : undefined}
                            maxLength={features.EpochConfigNarrativesMaximumChars}
                        />
                        }
                    </FeaturesConsumer>
                </div>
                
                <Styled.ButtonsContainer>
                    <Button
                        size="small"
                        disabled={selectedNarrative.global || selectedNarrative.deleted || !dirty || !tk.writable}
                        onClick={e => this.onSave(selectedNarrative)}
                        style={{marginRight: '10px'}}
                    >
                        Save
                        <Save style={{marginLeft: '6px', alignItems: 'center'}} />
                    </Button>
                    <Button
                        disabled={selectedNarrative.global || selectedNarrative.deleted || !dirty || !tk.writable}
                        size="small"
                        onClick={e => this.cancel()}
                    >
                        Cancel
                        <Cancel style={{marginLeft: '6px', alignItems: 'center'}} />
                    </Button>
                </Styled.ButtonsContainer>
            </div>
            }
            </TKConsumer>
        );
    }
}
import ClientAPI from 'api/interfaces/ClientAPI';
import BaseElectronImplementation from './Base.impl';
import { Client } from 'api/types/types';
export default class ClientImpl extends BaseElectronImplementation implements ClientAPI {
    searchClients = async (searchText: string) => {
        try {
            return await this.root.webImpl.Client.searchClients(searchText);
        } catch {
            let curTk = this.root.Session.currentTimeKeeper!;
            let trackedMatters = (await this.root.db.matterTkMappings
                .where({ timeKeeperId: curTk, tracked: 'true' })
                .with({ matter: 'matterId' })).filter(m => m !== undefined);
            const distinctClientIds = Array.from(new Set(trackedMatters.map(m => m.matter!.clientId!))).filter(c => c !== undefined)
            let clients = await this.root.db.clients
                .where('id')
                .anyOf(distinctClientIds).toArray()
            return clients.filter((c) => {
                let comp = `${c.number} - ${c.name}`;
                return comp.toLowerCase().includes(searchText.toLowerCase())
            });
        }
    }
    getClients = async () => {
        return await this.root.db.clients.toArray();
    }

    get = async (id: number) => {
        const client = await this.root.db.clients.get(id);
        return (client !== undefined) ? client : null;
    }
}
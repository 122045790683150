import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import DeleteConfirmationDialogStore from 'store/deleteConfirmationDialog.store';

interface Props {
    deleteConfirmationDialogStore?: DeleteConfirmationDialogStore;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        deleteConfirmationDialogStore: rootStore.deleteConfirmationDialog
    };
})
@observer
export default class DeleteConfirmationDialog extends React.Component<Props> {
    render() {
        let {
            isOpen,
            cancel,
            save
        } = this.props.deleteConfirmationDialogStore!;
        return (
            <Dialog
                open={isOpen}
                onClose={cancel}
                disableRestoreFocus={true}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle id="unsaved-dialog-title">Confirm Delete</DialogTitle>
                <DialogActions>
                    <Button onClick={cancel} color="primary">
                        No
                    </Button>
                    <Button onClick={save} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
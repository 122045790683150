import React, { Component } from 'react';
import { Button, Checkbox, Paper, Typography } from '@material-ui/core';
import { RootStore } from 'store/root.store';
import AppStore from 'store/app.store';
import { inject, observer } from 'mobx-react';
import { FlexDiv } from 'common/flex';
import { Platform } from '../../util/Platform';
import logger from '../../logging/logging';

interface Props {
    rootStore?: RootStore
    appStore?: AppStore
}

interface State {
    shiftKeyPressed: boolean;
    logging: boolean;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        rootStore: rootStore,
        appStore: rootStore.appStore
    };
})

@observer
class GeneralSettings extends Component<Props, State> {
    state: State = {
        shiftKeyPressed: false,
        logging: false
    }
    
    componentDidMount(): void {
        document.addEventListener('keyup', this.keyup)
        document.addEventListener('keydown', this.keydown)
        const enableLogging = localStorage.getItem('enableLogging');
        if (enableLogging) {
            this.setState({
                logging: JSON.parse(enableLogging)
            });
        }
    }
    exportLocalDB = async () => {
        if (Platform.isElectron() && this.props.appStore!.rootStore.api.Session.exportLocalDBToJSON) {
            this.props.appStore!.rootStore.api.Session.exportLocalDBToJSON!();
            this.setState({
                shiftKeyPressed: false
            })
        }
    }

    render() {
        const disableAnimsValue = this.props.appStore!.disableAnimations;
        return (
            <Paper style={{ padding: '15px' }}>
                <FlexDiv style={{ width: '100%' }} direction="column">
                    <Typography variant="headline">
                        General settings
                    </Typography>
                    <FlexDiv direction={'row'} style={{alignItems: 'center'}}>
                        <Checkbox
                            onChange={(evt, checked) => this.setAnimationsValue(checked)}
                            checked={disableAnimsValue}
                        />
                        <Typography variant={'subheading'}>Disable Animations</Typography>
                    </FlexDiv>
                    <FlexDiv direction={'row'} style={{alignItems: 'center'}}>
                        <Checkbox
                            onChange={(evt, checked) => this.setPassiveLogging(checked)}
                            checked={this.state.logging}
                        />
                        <Typography variant={'subheading'}>Enable logging</Typography>
                    </FlexDiv>

                    {this.state.shiftKeyPressed && Platform.isElectron() &&
                    <FlexDiv direction={'column'} style={{alignItems: 'flex-start'}}>
                        <Button onClick={this.exportLocalDB}>
                            Download Epoch Database
                        </Button>
                    </FlexDiv>}
                </FlexDiv>
            </Paper>
        );
    }

    private setPassiveLogging = (val: boolean) => {
        this.setState({
            logging: val
        });
        if (val) {
            // tslint:disable-next-line:no-any
            logger.silent = true;
        } else {
            logger.silent = false;
        }
        localStorage.setItem('enableLogging', JSON.stringify(val));
    }

    private setAnimationsValue(checked: boolean) {
        const store = this.props.appStore!;
        store.setDisableAnimations(checked);
    }

    private keydown = (e: KeyboardEvent) => {
        if (e.shiftKey) {
            this.setState({ shiftKeyPressed: true })
        }
    }

    private keyup = (e: KeyboardEvent) => {
        // `e.ctrlKey` for keyup events is false for some reason
        // that's why we skip the check

        if (e.keyCode === 16) {
            this.setState({ shiftKeyPressed: false })
        }
    }
}

export default GeneralSettings;
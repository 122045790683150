import React from 'react'
import { Checkbox, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { DateTime } from 'luxon'
import styled from 'styled-components'
import SegmentDragSource from 'components/DragNDropTimeCastSegment/SegmentDragSource';
import ImmutableTimeEntry from '../../api/immutables/ImmutableTimeEntry';
import { TimerChunk } from '../../api/types/types';
import ImmutableTimer from '../../api/immutables/ImmutableTimer';
import VirtualizedSegmentsList from 'components/CaptureSegmentsList/VirtualizedSegmentsList';
import { AutoSizer, List } from 'react-virtualized';
import { TimeKeeperAssignment } from '../../api/types/types';
import { TKConsumer } from 'common/TKProvider';

const CustomExpansionPanel = styled(ExpansionPanel)`
  ${p => p.expanded ? `margin: 4px 0;` : ``}
`;

const CustomExpansionPanelSummary = styled(ExpansionPanelSummary)`
    padding: 0;
    margin: 0;
    
    & > div {
        margin: 0 !important;
        padding: 0;
    }
`;

interface Props {
    toggleSelection: (group: TimerChunk) => void;
    isSelected: (group: TimerChunk) => boolean;
    expanded: boolean;
    toggleExpansion: () => void;
    selectedSegmentsToDrag: number;
    onDrop: (timeEntry: ImmutableTimeEntry) => Promise<void>;
    timer: ImmutableTimer;
    timeEntryOpen?: (chunk: TimerChunk) => void;
}

export const SegmentExpansion = (props: Props) => {
    const {
        timer,
        isSelected,
        toggleSelection,
        expanded,
        toggleExpansion,
        selectedSegmentsToDrag,
        onDrop,
        timeEntryOpen
    } = props;
    const groups: TimerChunk[] = timer.chunks;
    const disableSelection = groups.length === 0
        || groups.every(s => !!s.timeEntryId);
    const areAllSelected = groups.length > 0
        && groups.every(s => !!s.timeEntryId || isSelected(s))

    const selectAll = () => {
        if (areAllSelected) {
            // deselect all
            groups.forEach(g => isSelected(g) && toggleSelection(g))
        } else {
            // select all
            groups.forEach(g => !isSelected(g) && toggleSelection(g))
        }
    };
    const appDuration = groups
        .map(s => DateTime.fromISO(s.endTime)
            .diff(DateTime.fromISO(s.startTime), 'seconds')
            .get('seconds'))
        .reduce((previousValue: number, currentValue: number) => {
            return previousValue + currentValue
        }, 0) / 3600
    return (
        <CustomExpansionPanel expanded={expanded} onChange={toggleExpansion}>
            <CustomExpansionPanelSummary
                expandIcon={<ExpandMore />}
            >
                <TKConsumer>
                { (tk: TimeKeeperAssignment) =>
                <Checkbox
                    onMouseDown={(e) => {e.stopPropagation() /* prevent click on expansion panel */}}
                    onClick={(e) => {e.stopPropagation() /* prevent click on expansion panel */}}
                    checked={areAllSelected}
                    onChange={selectAll}
                    disabled={disableSelection || !tk.writable}
                />}
                </TKConsumer>
                <Typography style={{alignSelf: 'center'}}>
                    {`${timer.name} (${groups.length})  -  ${appDuration.toFixed(2)} hrs`}
                </Typography>
            </CustomExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: groups.length * 50,
                        maxHeight: 300
                    }}
                >
                    {/*{groups.map(g => (*/}
                    {/*    <SegmentDragSource*/}
                    {/*        toggleSelection={() => toggleSelection(g)}*/}
                    {/*        isSelected={isSelected(g)}*/}
                    {/*        selectedSegmentsToDrag={selectedSegmentsToDrag}*/}
                    {/*        onDrop={onDrop}*/}
                    {/*        timerSegment={g}*/}
                    {/*        timeEntryOpen={timeEntryOpen}*/}
                    {/*    />*/}
                    {/*))}*/}
                    <div style={{ flex: '1 1 auto', height: 'inherit' }}>
                        <AutoSizer>
                            {({ width, height }) => {
                                return (
                                    <List
                                        height={height}
                                        rowHeight={48}
                                        rowCount={groups.length}
                                        width={width}
                                        rowRenderer={({style, key, index}) => {
                                            return (
                                                <div key={key}>
                                                    <SegmentDragSource
                                                        toggleSelection={() => toggleSelection(groups[index])}
                                                        isSelected={isSelected(groups[index])}
                                                        selectedSegmentsToDrag={selectedSegmentsToDrag}
                                                        onDrop={onDrop}
                                                        timerSegment={groups[index]}
                                                        timeEntryOpen={timeEntryOpen}
                                                        style={style}
                                                    />
                                                </div>
                                            )
                                        }}
                                    />
                                )
                            }}
                        </AutoSizer>
                    </div>
                    {groups.length === 0 && <div>No captured data.</div>}
                </div>
            </ExpansionPanelDetails>
        </CustomExpansionPanel>
    )
}
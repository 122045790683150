import NarrativeAPI from 'api/interfaces/NarrativeAPI';
import BaseWebImplementation from './Base.impl';
import { Narrative } from 'api/types/types';
import Template from '../../immutables/ImmutableTemplate';
import { ApiResult } from '../../util';

export default class NarrativeImpl extends BaseWebImplementation implements NarrativeAPI {
    handlers: (((entries: Narrative[]) => void) | null )[] = [];
    registerReciever = (handler: (narratives: Narrative[]) => void) => {
        this.handlers.push(handler);
        const theIndex = this.handlers.length - 1;
        return  () => {
            this.handlers[theIndex] = null;
        }
    }
    recieve = (narratives: Narrative[]) => {
        this.handlers.filter(h => h !== null).forEach(h => h!(narratives))
    }
    async getAllNarratives() {
        let { data } = await this.http.get(`glossaries`);
        return data.map((d: object) => Object.assign(new Narrative(), d));
    }

    async getNarrative(id: number) {
        const { data } = await this.http.get(`glossaries/${id}`);
        return Object.assign(new Narrative(), data);
    }
    async saveNarrative(n: Narrative) {
        let obj = {
            id: n.id,
            key: n.key,
            replacement: n.replacement.trim(),
            deleted: n.deleted,
            global: n.global,
        };
        
        const { data } = await this.http.put(`glossaries`, [obj]);
        if (!data[0].status.failed) {
            let syntheticSync = {
                templates: [],
                timeEntries: [],
                timers: [],
                glossaries: [data[0].object]
            }
            this.root.Session.tabexClient.emit('sync', syntheticSync);
            return Object.assign(new Narrative(), data[0].object);
        } else {
            throw data[0].status.message;
        }
    }
    updateNarratives = async (entries: Narrative[]): Promise<ApiResult<Narrative>[]> => {
        let entryData = entries.map((n) => {
            return {
                id: n.id,
                key: n.key,
                replacement: n.replacement.trim(),
                deleted: n.deleted,
                global: n.global,
            }
        });
        const { data } = await this.http.put('/glossaries', entryData);
        // tslint:disable-next-line:no-any
        let savedNarratives = data.filter( (r: any) => !r.status.failed).map((r: any) => r.object)
        if (savedNarratives.length > 0) {
            let syntheticSync = {
                templates: [],
                glossaries: savedNarratives,
                timers: [],
                timeEntries: []
            }
            this.root.Session.tabexClient.emit('sync', syntheticSync);
        }
        return data;
    }
}
import * as React from 'react';
import Button from '@material-ui/core/Button';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import MultipleTEConfirmationDialogStore from 'store/multipleTEConfirmation.dialog.store';

interface Props {
    multipleTEConfirmationDialogStore?: MultipleTEConfirmationDialogStore;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        multipleTEConfirmationDialogStore: rootStore.multipleTEConfirmationDialog
    };
})
@observer
export default class MultipleTEConfirmationDialog extends React.Component<Props> {
    render() {
        let {
            isOpen,
            cancel,
            save
        } = this.props.multipleTEConfirmationDialogStore!;
        return (        
        <Dialog
            open={isOpen}
            onClose={cancel}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                Are you sure you want to cancel time entry for remaining days?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel} color="primary">
                    NO
                </Button>
                <Button onClick={save} color="primary" autoFocus={true}>
                    YES
                </Button>
            </DialogActions>
        </Dialog>
        );
    }
}
import CustomDictionaryAPI, { CDResult } from 'api/interfaces/CustomDictionaryAPI';
import { CustomDictionary } from '../../types/types';
import BaseElectronImplementation from './Base.impl';
import { CustomDictionaryI, Template } from './Dexie';
import ImmutableTemplate from '../../immutables/ImmutableTemplate';
import { ApiResult } from '../../util';
import ImmutableBaseEntry from '../../immutables/ImmutableBaseEntry';

export default class CustomDictionaryImpl extends BaseElectronImplementation implements CustomDictionaryAPI {

    getAllUserDictionaries = async () => {
        return (await this.root.db.dictionaries.filter(d => !d.deleted).toArray())
            .map((d) => Object.assign(new CustomDictionary(), d));
    }

    async getUserDictionary (id: number) {
        return Object.assign(new CustomDictionary(), await this.root.db.dictionaries.get(id)!);
    }
    getWriteableId = async (entry: CustomDictionary): Promise<number | undefined> => {
        if (!entry.id) {
            return undefined;
        }
        if (entry.id < 0) {
            return entry.id * -1;
        }
        return (await this.root.db.dictionaries.get({id: entry.id}))!.localId;
    }
    trySaveOne = async (dict: CustomDictionary): Promise<ApiResult<CustomDictionary>> => {
        try {
            let insertKey = await this.getWriteableId(dict);
            let writeableEntry = dict.toWriteable() as CustomDictionaryI;
            if (insertKey) {
                writeableEntry.localId = insertKey;
            }
            let deletedCustomWords = await this.root.db.dictionaries
                .filter(d => d.deleted)
                .toArray();
            const indx = deletedCustomWords
                .findIndex(d => d.dictionary.toLowerCase() === writeableEntry.dictionary.toLowerCase())
            if (indx >= 0) {
                writeableEntry.localId = deletedCustomWords[indx].localId;
                writeableEntry.deleted = false;
            }
            writeableEntry.serverDirty = true;
            let localId = await this.root.db.dictionaries.put(
                writeableEntry
            );
            let safeEntry = (await this.root.db.dictionaries.get(localId))!;
            return {
                status: {
                    failed: false,
                    message: 'Success'
                },
                object: Object.assign(new CustomDictionary(), safeEntry)
            }

        } catch {
            return {
                status: {
                    failed: true,
                    message: 'Failed save'
                },
                object: dict
            }
        }
    }
    async saveUserDictionary (dicts: CustomDictionary[]) {
        let ents = await Promise.all(dicts.map(this.trySaveOne));
        this.root.Session.write();
        return ents;
    }
    recieve = async (dicts: CustomDictionary[]): Promise<void> => {
        const promArray = dicts.map((dict) => this.root.db.dictionaries.get({ id: dict.id! }));
        let localDicts = await Promise.all(promArray);
        dicts.forEach((dic, idx) => {
            if (localDicts[idx]) {
                // tslint:disable-next-line: no-any
                (dic as any).localId = localDicts[idx]!.localId;
            }
        })
        await this.root.db.dictionaries.bulkPut(dicts);
        // let tes = dicts.map(te => Object.assign(new ImmutableTemplate(), te));
        // this.handlers.filter(h => h !== null).forEach(h => h!(tes))
    }
    write = async () => {
        let dirtyDicts = await this.root.db.dictionaries.filter(dict => dict.serverDirty || false).toArray();
        if ( dirtyDicts.length === 0) {
            return;
        }
        const emitDicts: CustomDictionary[] = [];
        const toWrite = dirtyDicts
            .map(dict => {
                    let newdict = Object.assign(new CustomDictionary(), dict)
                    if ( newdict.id! < 0) {
                        newdict.id = undefined;
                    }
                    return newdict;
                }
            )
        const results = await this.root
            .webImpl
            .CustomDictionary.
            saveUserDictionary(
                toWrite
            );
        // tslint:disable-next-line:no-any
        let proms: Promise<any>[] = [];
        for (let i = 0; i < results.length; i++) {
            let curRes = results[i];
            let localDict = dirtyDicts[i]!;
            if (curRes.status.failed) {
                // failed write, do something
            } else {
                (curRes.object as CustomDictionaryI).localId = localDict.localId;
                proms.push(this.root.db.dictionaries.put(curRes.object ));
                if (localDict.id! < 0) {
                    localDict.deleted = true;
                    // id less than 0, only local 
                    emitDicts.push( Object.assign(new CustomDictionary(), localDict ) );
                }
                emitDicts.push( Object.assign(new CustomDictionary(), curRes.object ) )

            }
        }
        await Promise.all(proms);
        // this.handlers.filter(h => h !== null).forEach(h => h!(emitDicts))
    }
}
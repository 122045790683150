import { onOpen } from '@fulcrumgt/mobx-store-utils';
import { action, computed, observable } from 'mobx';
import { DialogRootStore } from 'store/dialog.root.store';
import { TimerChunk } from '../../api/types/types';
import { getDurStringBetween } from 'components/TimerChunkItem/TimerChunkItem';

export default class EditTimerTimeDialogStore extends DialogRootStore {
    @observable startTime: string = '';
    @observable endTime: string = '';
    @observable hours: number;
    @observable minutes: number;
    @observable seconds: number;
    @observable meridiem: string;
    @observable timerChunk: TimerChunk = new class implements TimerChunk {
        deleted: boolean;
        description: string;
        endTime: string;
        id: number;
        startTime: string;
        submitted: boolean;
        timeEntryId: number | null;
        timerId: number;
        used: boolean;
    };
    @observable invalidEndTime: boolean = false;
    
    @onOpen()
    dialogOpen(chunk: TimerChunk) {
        this.timerChunk = chunk;
        this.startTime = chunk.startTime;
        this.endTime = chunk.endTime;
        this.setAllMeasurements(chunk.endTime);
    }
    @action
    setEndTime = (time: string) => {
        this.endTime = time;
        this.setAllMeasurements(time);
    }
    setAllMeasurements(time: string) {
        this.invalidEndTime = false;
        const twFourHours = new Date(time).getHours();
        // this.hours = (twFourHours + 11) % 12 + 1;
        this.hours = twFourHours;
        this.minutes = new Date(time).getMinutes();
        this.seconds = new Date(time).getSeconds();
        if (twFourHours < 12) {
            this.meridiem = 'AM'
        } else {
            this.meridiem = 'PM'
        }
    }
    @action
    saveChunkTime = async () => {
        if (new Date(this.endTime) < new Date(this.startTime)) {
            this.invalidEndTime = true;
            return ;
        }
        this.timerChunk.endTime = this.endTime;
        await this.rootStore.timerStore.debouncedChunkSave(this.timerChunk);
        this.cancel();
    }
    @action
    setHours = (hour: number) => {
        this.invalidEndTime = false;
        this.hours = hour;
        const dateTimeStamp = new Date(this.endTime).setHours(hour);
        if (this.hours >= 0 && this.hours < 24) {
            this.endTime = new Date(dateTimeStamp).toISOString();
        }
    }
    @action
    setMinutes = (minute: number) => {
        this.invalidEndTime = false;
        this.minutes = minute;
        const dateTimeStamp = new Date(this.endTime).setMinutes(minute);
        if (this.minutes >= 0 && this.minutes < 60) {
            this.endTime = new Date(dateTimeStamp).toISOString();
        }
    }
    @action
    setSeconds = (sec: number) => {
        this.invalidEndTime = false;
        this.seconds = sec;
        const dateTimeStamp = new Date(this.endTime).setSeconds(sec);
        if (this.seconds >= 0 && this.seconds < 60) {
            this.endTime = new Date(dateTimeStamp).toISOString();
        }
    }
    @action
    setMeridiem = (val: string) => {
        this.invalidEndTime = false;
        this.meridiem = val;
        if (val === 'PM') {
            let pmHours = new Date(this.endTime).getHours() + 12;
            this.endTime = new Date(new Date(this.endTime).setHours(pmHours)).toISOString();
        } else {
            const amHours = new Date(this.endTime).getHours() - 12;
            this.endTime = new Date(new Date(this.endTime).setHours(amHours)).toISOString();
        }
    }
    @computed get timeDuration(): string {
        if (new Date(this.endTime) < new Date(this.startTime)) {
            return 'Invalid end time'
        }
        return getDurStringBetween(new Date(this.startTime), new Date(this.endTime))
    }
    @computed get invalidHours(): boolean {
        return this.hours > 23 || this.hours < 0;
    }
    @computed get invalidMinutes(): boolean {
        return this.minutes > 59 || this.minutes < 0;
    }
    @computed get invalidSeconds(): boolean {
        return this.seconds > 59 || this.minutes < 0;
    }
}
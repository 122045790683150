import * as React from 'react';
import * as Styled from './styled';
import { ButtonBase, IconButton, Input, Paper, Tooltip, CircularProgress } from '@material-ui/core';
import { Add, Delete, Language, Search } from '@material-ui/icons';
import NarrativeStore from 'store/narrative.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import { Route } from 'react-router';
import NarrativeForm from './NarrativeForm';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import { theme } from 'common/theme';
import { Loading } from '../../containers/TimeEntries/styled';
import { TKConsumer } from 'common/TKProvider';
import { TimeKeeperAssignment } from '../../api/types/types';

interface Props {
    narrativeStore?: NarrativeStore;
    match: {
        url: string;
    };
}

interface GGProps extends ListRowProps {
    narrativeStore?: NarrativeStore;
    match: {
        url: string;
    };
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        narrativeStore: rootStore.narrativeStore
    };
})
@observer
class ListRow extends React.Component<GGProps> {

    render() {
        const {
            filteredNarratives,
            selectedNarrative,
            changeSelectedNarrative,
            deleteNarrative
        } = this.props.narrativeStore!;
        
        const { index, key, style } = this.props;
        const curNarrative = filteredNarratives[index];

        return (
            <div
                key={key}
                style={{ ...style }}
                onClick={() => changeSelectedNarrative(curNarrative.id!)}
            >
                <ButtonBase style={{width: '100%', height: '100%'}}>
                    {curNarrative ? (
                        <Styled.ListItem
                            style={{height: style.height}}
                            selected={selectedNarrative && selectedNarrative.id === curNarrative.id}
                        >
                            <div style={{ minWidth: '20px' }}>
                                <Styled.Key>{curNarrative.key}</Styled.Key>
                                <Styled.Replacement>{curNarrative.replacement}</Styled.Replacement>
                            </div>
                            <TKConsumer>
                                { (tk: TimeKeeperAssignment) =>
                            <div>
                                {!curNarrative.global
                                    ? tk.writable && <Tooltip title="Delete">
                                        <IconButton
                                            onClick={() => deleteNarrative(curNarrative.id!)}
                                        >
                                        <Delete/>
                                        </IconButton>
                                    </Tooltip>
                                    : <Tooltip title="Global">
                                        <IconButton disabled={true}>
                                            <Language style={{ color: theme.primary.main }}/>
                                        </IconButton>
                                    </Tooltip>
                                }
                            </div>}
                            </TKConsumer>
                        </Styled.ListItem>
                    ) : (
                        <Styled.ListItem data-no-narrative={true} />
                    )}
                </ButtonBase>
            </div>
        );
    }
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        narrativeStore: rootStore.narrativeStore
    };
})
@observer
export default class NarrativesDesktop extends React.Component<Props> {

    componentDidMount() {
        this.props.narrativeStore!.loadNarratives();
        this.props.narrativeStore!.newNarrative();
    }

    componentWillUnmount() {
        this.props.narrativeStore!.onSearchChange('');
    }

    render() {
        const {
            filteredNarratives,
            onSearchChange,
            newNarrative,
            searchText,
            loading
        } = this.props.narrativeStore!;
        const { match } = this.props;

        return (
            <Styled.Container>
                <Styled.Header>
                    <Styled.SearchContainer>
                        <Search
                            style={{ marginRight: '6px' }}
                        />
                        <Input
                            placeholder="Search"
                            fullWidth={true}
                            style={{ maxWidth: '60%' }}
                            value={searchText}
                            onChange={e => onSearchChange(e.target.value)}
                        />
                    </Styled.SearchContainer>

                    <Styled.Title variant="h6">
                        Narrative Codes
                    </Styled.Title>
                    <TKConsumer>
                        {(tk: TimeKeeperAssignment) =>
                            <div style={{ flex: '1' }}>
                                {tk.writable &&
                                    <Tooltip title="New Narrative">
                                        <Styled.New
                                            onClick={_ => {
                                                newNarrative();
                                            }}
                                        >
                                            <Add />
                                        </Styled.New>
                                    </Tooltip>
                                }
                            </div>}
                    </TKConsumer>
                </Styled.Header>

                <Styled.ContentContainer>
                    <Styled.ListContainer square={true}>
                        <AutoSizer>
                            {({ width, height }) => {
                                return (<List
                                    height={height}
                                    rowHeight={80}
                                    rowCount={filteredNarratives.length}
                                    width={width}
                                    rowRenderer={(props) => {
                                        return (
                                            <ListRow
                                                match={match}
                                                {...props}
                                            />
                                        );
                                    }}
                                />);
                            }}
                        </AutoSizer>
                    </Styled.ListContainer>
                    <Styled.FormContainer>
                        <Paper style={{ padding: '10px' }}>
                            <Route
                                path={`${match.url}/:id`}
                                component={NarrativeForm}
                            />
                        </Paper>
                    </Styled.FormContainer>
                </Styled.ContentContainer>
                {loading &&
                        <Loading>
                            <div style={{top: '-15%', position: 'relative'}}>
                                <CircularProgress size={100} />
                            </div>
                        </Loading>}
            </Styled.Container>
        );
    }
}
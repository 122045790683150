import React, { Component, RefObject } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { InputAdornment, Menu, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import * as Styled from 'components/TimeKeepersList/styled.desktop';
import AppStore from 'store/app.store';
import { inject } from 'mobx-react';
import { RootStore } from 'store/root.store';
import { Platform } from '../../util/Platform';
import { TimeKeeperAssignment } from '../../api/types/types';

interface Props {
    render: (tkMenuAnchor: RefObject<HTMLElement | HTMLButtonElement>, 
             openTkList: (
                 event: React.MouseEvent<HTMLButtonElement | HTMLLIElement | HTMLElement>
             ) => void) => JSX.Element,
    collaborate: boolean;
    menuWidth?: number;
    appStore?: AppStore;
    timeKeepers: TimeKeeperAssignment[];
    workDate: string;
}
interface State {
    tkMenuOpen: boolean;
    selectedTks: number[];
    searchText: string;
}
@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        appStore: rootStore.appStore
    };
})
class TimeKeepersList extends Component<Props, State> {
    timeKeeperMenuAnchor = React.createRef<HTMLElement | HTMLButtonElement>();
    state: State = {
        tkMenuOpen: false,
        selectedTks: [],
        searchText: ''
    };
    componentWillReceiveProps(nextProps: Readonly<Props>): void {
        this.setState({
            selectedTks: this.props.appStore!.rootStore.collaboratees
        })
    }
    componentWillMount(): void {
        this.setState({
            selectedTks: this.props.appStore!.rootStore.collaboratees
        })
    }
    componentWillUnmount(): void {
        this.setState({
            selectedTks: []
        });
    }
    fetchMoreTimeKeepers = (cHeight: number, scrollHeight: number, scrollTop: number, workDate: string) => {
        if (this.props.collaborate) {
            let bottom = scrollHeight - (scrollTop + cHeight);
            if (scrollTop === 0) {
                return
            }
            if (bottom <= 15 && bottom !== 0) {
                this.props.appStore!.fetchMoreTimeKeepers(workDate);
            }
        }
    }
    openTkList = (event: React.MouseEvent<HTMLButtonElement | HTMLLIElement | HTMLElement>) => {
        this.setState({ tkMenuOpen: true });
        this.props.appStore!.setTkSearchText('');
        this.setState({
            searchText: ''
        })
    }
    handleTkSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
        let search = evt.target.value || '';
        this.setState({
            searchText: search
        });
        this.props.appStore!.setTkSearchText(search);
        if (this.props.collaborate) {
            this.props.appStore!.debouncedGetTimekeepersList(this.props.workDate);
        }
    };
    handleTkSelection = (event: React.MouseEvent<HTMLElement>, val: number) => {
        let tkList = new Set<number>(this.state.selectedTks);
        
        if (tkList.has(val)) {
            tkList.delete(val);
        } else {
            tkList.add(val);
        }
        const finalList = Array.from(tkList)
        this.setState({
            selectedTks: finalList
        });
        this.props.appStore!.rootStore.setColloaboratees(finalList);
    }
    isTkSelected = (id: number) => {
        return this.state.selectedTks.includes(id);
    }
    setTimeKeeper = (tkId: number) => {
        if (Platform.isElectron() && this.props.appStore!.rootStore.api.TimeEntry.getServerEntries) {
            this.props.appStore!.rootStore.api.TimeEntry.getServerEntries!(tkId);
        }
        this.props.appStore!.setTimekeeper(tkId);
    }
    onMenuClose = () => {
        this.setState({ tkMenuOpen: false })
        setTimeout(() => {
                this.props.appStore!.currentEnd = 0;
                this.props.appStore!.allTimeKeepersList = []}
            , 500)
    }
    render() {
        const { tkMenuOpen, searchText } = this.state;
        const { collaborate, menuWidth, timeKeepers, workDate } = this.props;
        return (
            <>
                {this.props.render(this.timeKeeperMenuAnchor, this.openTkList)}
                <AutoSizer>
                    {({ width, height }) => {
                        return (
                            <Menu
                                anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
                                anchorEl={this.timeKeeperMenuAnchor.current}
                                open={tkMenuOpen}
                                onClose={() => this.onMenuClose()}
                                PaperProps={{
                                    style: {
                                        maxHeight: 48 * 8,
                                        minWidth: menuWidth,
                                        overflow: 'hidden',
                                    }
                                }}
                            >
                                <TextField
                                    id={'search-with-icon-textfield'}
                                    placeholder={'Search with Timekeeper/Office'}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position={'start'}>
                                                <Search />
                                            </InputAdornment>
                                        )
                                    }}
                                    value={searchText}
                                    fullWidth={true}
                                    onChange={this.handleTkSearch}
                                    style={{
                                        padding: 5,
                                        outline: 'none'
                                    }}
                                />
                                {timeKeepers.length >= 1 ?
                                    <List
                                        height={timeKeepers.length >= 7 ? 48 * 7 : 48 * timeKeepers.length}
                                        rowHeight={48}
                                        rowCount={timeKeepers.length}
                                        overscanRowCount={8}
                                        width={menuWidth!}
                                        onScroll={({ clientHeight, scrollHeight, scrollTop }:
                                            { clientHeight: number; scrollHeight: number; scrollTop: number }) =>
                                            this.fetchMoreTimeKeepers(clientHeight, scrollHeight, scrollTop, workDate)}
                                        rowRenderer={({ style, key, index }) => {
                                        // created a variable to avoid build errors
                                        let tkId = this.props.appStore!.currentTimekeeper.timeKeeperId
                                        let isSelected = timeKeepers[index] && timeKeepers[index].timeKeeperId === tkId;
                                        return (
                                            <div>
                                                {timeKeepers[index] &&
                                                    <Styled.TKMenuItem
                                                        selected={isSelected}
                                                        onClick={(isSelected || collaborate) ? undefined 
                                                            : () => this.setTimeKeeper(timeKeepers[index].timeKeeperId)}
                                                        key={key}
                                                        style={style}
                                                    >
                                                        <Styled.NameSpan>
                                                            {timeKeepers[index].name}
                                                            ({timeKeepers[index].office})
                                                                {timeKeepers[index].delegated && !collaborate ?
                                                                <Styled.DelegatedSpan>Delegated</Styled.DelegatedSpan> :
                                                                <></>
                                                            }
                                                        </Styled.NameSpan>
                                                        {(!isSelected && collaborate) &&
                                                            <div style={{margin: '10px'}}>
                                                                <Styled.CollaborateCheckbox
                                                                    checked={this.isTkSelected(
                                                                                timeKeepers[index].timeKeeperId
                                                                            )}
                                                                    onClick={evt => this.handleTkSelection(
                                                                                evt, timeKeepers[index].timeKeeperId
                                                                            )}
                                                                />
                                                            </div>
                                                        }
                                                    </Styled.TKMenuItem>
                                                }
                                            </div>

                                        )
                                        }}
                                    />
                                    : <div style={{ paddingLeft: 12 }}>No Timekeepers found</div>
                                }
                            </Menu>
                        )
                    }}
                </AutoSizer>
            </>
        );
    }
}

export default TimeKeepersList;
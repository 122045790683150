import BaseElectronImplementation from './Base.impl';
import SettingsAPI from '../../interfaces/SettingsAPI';
import { ApiResult } from '../../util';
import { Setting } from '../../types/types';
import { SettingI } from './Dexie';
import { IndexableType } from 'dexie';

export default class SettingsImpl extends BaseElectronImplementation implements SettingsAPI {
    handlers: (((settings: Setting[]) => void) | null)[] = [];

    registerReceiver = (handler: (settings: Setting[]) => void) => {
        this.handlers.push(handler);
        const theIndex = this.handlers.length - 1;
        return () => {
            this.handlers[theIndex] = null;
        };
    };

    receive = async (settings: Setting[]): Promise<void> => {
        const promises = settings.map((setting) => this.root.db.settings.get({id: setting.id!}));
        let localSettings = await Promise.all(promises);
        settings.forEach((setting, index) => {
            if (localSettings[index]) {
                // tslint:disable-next-line: no-any
                (setting as SettingI).localId = localSettings[index]!.localId;
            }
        });
        await this.root.db.settings.bulkPut(settings);
        let s = settings.map(setting => Object.assign({}, setting));
        this.handlers.filter(h => h !== null).forEach(h => h!(s));
    };

    async all() {
        const settings = (await this.root.db.settings.toArray());

        return settings
            .map((setting) => Object.assign({}, setting))
            .filter(s => {
                return !s.deleted;
            });
    }
    
    getByKey = async (key: string) => {
        const settings: Setting[] = (await this.root.db.settings.where('key').equals(key).toArray())
            .filter(s => !s.deleted);
        
        const globalSetting = settings.find(s => s.global && s.key === key);
        const userSetting = settings.find(s => !s.global && s.key === key);
        
        if (!globalSetting) {
            return userSetting
        }
        
        const setting: Setting = Object.assign(globalSetting, userSetting);
        
        if (setting.global || typeof setting.id !== 'number' || setting.id <= 0) {
            // no user setting set.
            delete setting.id;
            setting.global = false;
        }
        
        return setting;
    }

    async get(id: number) {
        const query: { [p: string]: IndexableType } = {};
        
        if (id < 0) {
            query.localId = id * -1;
        } else {
            query.id = id;
        }

        return (await this.root.db.settings.get(query))!;
    }

    getWriteableId = async (entry: Setting): Promise<number | undefined> => {
        if (!entry.id) {
            return undefined;
        }

        if (entry.id < 0) {
            return entry.id * -1;
        }

        return (await this.root.db.settings.get({id: entry.id}))!.localId;
    };

    trySaveOne = async (setting: Setting): Promise<ApiResult<Setting>> => {
        try {
            let insertKey = await this.getWriteableId(setting);

            let writeableEntry = JSON.parse(JSON.stringify(setting)) as SettingI;

            if (insertKey) {
                writeableEntry.localId = insertKey;
            }

            writeableEntry.serverDirty = true;

            let localId = await this.root.db.settings.put(
                writeableEntry
            );

            let safeEntry = (await this.root.db.settings.get(localId))!;

            return {
                status: {
                    failed: false,
                    message: 'Success'
                },
                object: Object.assign({}, safeEntry)
            };
        } catch (e) {
            return {
                status: {
                    failed: true,
                    message: 'Failed save'
                },
                object: setting
            };
        }
    };

    write = async () => {
        let dirtySettings = await this.root.db.settings.filter(setting => setting.serverDirty || false).toArray();
        if (dirtySettings.length === 0) {
            return;
        }
        const emitEntries: Setting[] = [];
        const toWrite = dirtySettings
            .map(s => {
                    let setting = Object.assign({}, s);
                    if (setting.id! < 0) {
                        setting.id = undefined;
                    }
                    return setting;
                }
            );

        const results = await this.root
            .webImpl
            .Settings
            .updateSettings(
                toWrite
            );

        // tslint:disable-next-line:no-any
        let proms: Promise<any>[] = [];
        for (let i = 0; i < results.length; i++) {
            let curRes = results[i];
            let localEntry = dirtySettings[i]!;
            if (curRes.status.failed) {
                // failed write, do something
            } else {
                (curRes.object as SettingI).localId = localEntry.localId;
                proms.push(this.root.db.settings.put(curRes.object));
                if (localEntry.id! < 0) {
                    localEntry.deleted = true;
                    // id less than 0, only local 
                    emitEntries.push(Object.assign({}, localEntry));
                }
                emitEntries.push(Object.assign({}, curRes.object));

            }
        }
        await Promise.all(proms);
        this.handlers.filter(h => h !== null).forEach(h => h!(emitEntries));
    };
    
    async save(n: Setting): Promise<ApiResult<Setting>> {
        let resp = await this.trySaveOne(n);
        this.root.Session.write();
        if (!resp.status.failed) {
            return {
                status: {
                    failed: false,
                    message: 'Success.'
                },
                object: resp.object
            };
        } else {
            throw resp.status.message;
        }
    }
}

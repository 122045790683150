import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, FormHelperText,
    Input, InputLabel,
    MenuItem, Select,
    TextField
} from '@material-ui/core';
import EditTimerTimeDialogStore from 'store/Timer/edit.timer.time.dialog.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import DateFnsUtils from '@date-io/date-fns';
import { InlineTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { FlexDiv } from 'common/flex';
import { EditTimeLabel } from '../../containers/Timers/styled.desktop';

interface Props {
    editTimerTimeDialogStore?: EditTimerTimeDialogStore
}
@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        editTimerTimeDialogStore: rootStore.editTimerChunkTimeDialogStore
    };
})
@observer
export default class EditTimerChunkTimeDialog extends React.Component<Props> {
    cancel = () => {
        this.props.editTimerTimeDialogStore!.cancel();
    };
    changeHours = (timeEvent: React.ChangeEvent<HTMLInputElement>) => {
        const hour = Number(timeEvent.target.value)
        this.props.editTimerTimeDialogStore!.setHours(hour);
    }
    changeMinutes = (timeEvent: React.ChangeEvent<HTMLInputElement>) => {
        const minute = Number(timeEvent.target.value)
        this.props.editTimerTimeDialogStore!.setMinutes(minute)
    }
    changeSeconds = (timeEvent: React.ChangeEvent<HTMLInputElement>) => {
        const sec = Number(timeEvent.target.value)
        this.props.editTimerTimeDialogStore!.setSeconds(sec);
    }
    changeEndTimeWithClock = (timeStr: string) => {
        this.props.editTimerTimeDialogStore!.setEndTime(timeStr);
    }
    save = async () => {
        await this.props.editTimerTimeDialogStore!.saveChunkTime();
    }
    changeMeridiem = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.editTimerTimeDialogStore!.setMeridiem(evt.target.value)
    }
    render() {
        const {
            isOpen,
            startTime,
            endTime,
            hours,
            minutes,
            seconds,
            meridiem,
            timeDuration,
            invalidEndTime,
            timerChunk,
            invalidHours,
            invalidMinutes,
            invalidSeconds
        } = this.props.editTimerTimeDialogStore!;
        const startDateTime = new Date(startTime);
        
        return(
            <Dialog
                open={isOpen}
                onClose={this.cancel}
                maxWidth="md"
            >
                <DialogTitle>
                    <div>
                        <label>Edit Timer Segment</label>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <EditTimeLabel style={{textAlign: 'center', fontSize: 18}}>{timeDuration}</EditTimeLabel>
                    <EditTimeLabel>
                        Start Time
                    </EditTimeLabel>
                    <FlexDiv style={{paddingBottom: 16}}>
                        <TextField
                            id="start-hour"
                            label="Hours"
                            value={startDateTime.getHours()}
                            type={'number'}
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 11
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={this.changeHours}
                            style={{
                                width: 40
                            }}
                            disabled={true}
                        />
                        <TextField
                            id="start-minute"
                            label="Minutes"
                            value={startDateTime.getMinutes()}
                            type={'number'}
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 59
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={this.changeMinutes}
                            style={{
                                width: 50
                            }}
                            disabled={true}
                        />
                        <TextField
                            id="start-second"
                            label="Seconds"
                            value={startDateTime.getSeconds()}
                            type={'number'}
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 59
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={this.changeSeconds}
                            style={{
                                width: 55
                            }}
                            disabled={true}
                        />
                        {/*<FormControl disabled={true}>*/}
                        {/*    <InputLabel shrink={true}>*/}
                        {/*        Meridiem*/}
                        {/*    </InputLabel>*/}
                        {/*    <Select*/}
                        {/*        input={<Input name={'Meridiem'} id={'timer-chunk-start-meridiem'}/>}*/}
                        {/*        displayEmpty={false}*/}
                        {/*        name={'select-meridiem'}*/}
                        {/*        value={startMeridiem}*/}
                        {/*        onChange={this.changeMeridiem}*/}
                        {/*    >*/}
                        {/*        <MenuItem value={'AM'}>AM</MenuItem>*/}
                        {/*        <MenuItem value={'PM'}>PM</MenuItem>*/}
                        {/*    </Select>*/}
                        {/*</FormControl>*/}
                    </FlexDiv>
                    <EditTimeLabel>
                        End Time
                    </EditTimeLabel>
                    <FlexDiv>
                        <TextField
                            id="hour"
                            label="Hours"
                            value={hours}
                            type={'number'}
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 23
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={this.changeHours}
                            style={{
                                width: 40
                            }}
                            disabled={timerChunk.timeEntryId ? true : false}
                        />
                        <TextField
                            id="minute"
                            label="Minutes"
                            value={minutes}
                            type={'number'}
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 59
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={this.changeMinutes}
                            style={{
                                width: 50
                            }}
                            disabled={timerChunk.timeEntryId ? true : false}
                        />
                        <TextField
                            id="second"
                            label="Seconds"
                            value={seconds}
                            type={'number'}
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 59
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={this.changeSeconds}
                            style={{
                                width: 55,
                                paddingRight: 10
                            }}
                            disabled={timerChunk.timeEntryId ? true : false}
                        />
                        {/*<FormControl style={{paddingRight: 10}}>*/}
                        {/*    <InputLabel shrink={true}>*/}
                        {/*        Meridiem*/}
                        {/*    </InputLabel>*/}
                        {/*    <Select*/}
                        {/*        input={<Input name={'Meridiem'} id={'timer-chunk-meridiem'}/>}*/}
                        {/*        displayEmpty={false}*/}
                        {/*        name={'select-meridiem'}*/}
                        {/*        value={meridiem}*/}
                        {/*        onChange={this.changeMeridiem}*/}
                        {/*    >*/}
                        {/*        <MenuItem value={'AM'}>AM</MenuItem>*/}
                        {/*        <MenuItem value={'PM'}>PM</MenuItem>*/}
                        {/*    </Select>*/}
                        {/*</FormControl>*/}
                        <div style={{width: 68}}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <InlineTimePicker
                                    value={new Date(endTime)}
                                    onChange={this.changeEndTimeWithClock}
                                    labelFunc={() => { return 'Clock'}}
                                    variant={'outlined'}
                                    seconds={true}
                                    ampm={false}
                                    disabled={timerChunk.timeEntryId ? true : false}
                                    mergePreviousDateOnChange={false}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </FlexDiv>
                    {invalidEndTime &&
                    <FormHelperText error={true}>
                        Invalid end time
                    </FormHelperText>
                    }
                    {invalidHours &&
                    <FormHelperText error={true}>
                        Invalid hours
                    </FormHelperText>
                    }
                    {invalidMinutes &&
                    <FormHelperText error={true}>
                        Invalid minutes
                    </FormHelperText>
                    }
                    {invalidSeconds &&
                    <FormHelperText error={true}>
                        Invalid seconds
                    </FormHelperText>
                    }
                </DialogContent>
                <DialogActions>
                    {!timerChunk.timeEntryId &&
                        <Button 
                            onClick={this.save}
                            disabled={invalidHours || invalidMinutes || invalidSeconds}
                        >
                            Save
                        </Button>
                    }
                    <Button onClick={this.cancel}>Cancel</Button>
                </DialogActions>
            </Dialog>
        )
    }
}
import { Features, TimerChunk } from '../../api/types/types';
import * as Styled from './styled.desktop';
import {
    FilledInput,
    FormControl,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    Tooltip,
    withStyles
} from '@material-ui/core';
import HomeStore, { SegmentViewMode } from 'store/home.store';
import { Close, Delete, Flip, ListAlt, Refresh, Timer, Visibility } from '@material-ui/icons';
import { CaptureSegmentsList } from 'components/CaptureSegmentsList/CaptureSegmentsList';
import { SegmentExpansion } from 'components/CaptureSegmentsList/SegmentExpansion';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import * as React from 'react';
import { LoadingDiv, ViewMode } from './Home.desktop';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';

const BootstrapInput = withStyles(theme => ({
    input: {
        backgroundColor: theme.palette.background.paper,
        color: '#000',
        paddingBottom: 3,
        fontSize: 12,
        '&:focus': {
            backgroundColor: '#fff',
            color: '#000'
        },
    },
}))(FilledInput);

interface Props {
    toggleTCView: boolean;
    homeStore?: HomeStore;
    // tslint:disable-next-line:no-any
    classes?: any;
}
interface State {
    
}
@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore
    return {
        homeStore: rootStore.homeStore,
        timerStore: rootStore.timerStore
    }
})
@observer
export default class SegmentsView extends React.Component<Props, State> {
    render() {
        const {
            toggleTCView,
            classes
        } = this.props;
        const {
            calendarMode,
            segmentViewMode,
            toggleSegmentGaps,
            showSegmentGaps,
            showUsedSegments,
            toggleShowProcessedSegments,
            showSegmentsAsLineItems,
            toggleSegmentListView,
            segmentsLoading,
            refreshSegments,
            showTimersPane,
            toggleTimers,
            hasSelectedAtLeastOneGroup,
            deleteSegments,
            selectedSegments,
            selectedTimerSegments,
            deselectAllSegments,
            toggleSegmentViewMode,
            filteredApplicationTypeSegments,
            filteredChronologicalSegments,
            filteredTimeTypeSegments,
            getProgram,
            toggleSegmentGroupOrGapSelection,
            isSelected,
            selectSameWindows,
            toggleApplicationExpansion,
            updateTimeEntryFromDragSource,
            toggleTimeGroupExpansion,
            getProgramColor,
            captureChunkObservables,
            filteredDayViewTimers,
            toggleTimerSelection,
            expandedTimers,
            toggleTimerExpansion,
            isSelectedTimerSegment,
            openTimeEntryFromTimerSegment
        } = this.props.homeStore!;
        
        return (
            <FeaturesConsumer>
                {(features: Features) => features.EpochConfigTimeCastEnabled &&
                    calendarMode !== ViewMode.WEEK && toggleTCView && (
                        <Styled.CapturedSegmentsContainer>
                            <Styled.CapturedSegmentsControls>
                                <Tooltip title="Show Gaps">
                                    <Styled.SegmentIconButton
                                        disabled={segmentViewMode !== SegmentViewMode.CHRONOLOGICAL}
                                        onClick={toggleSegmentGaps}
                                    >
                                        <Flip data-active={showSegmentGaps}/>
                                    </Styled.SegmentIconButton>
                                </Tooltip>
                                <Tooltip
                                    title={showUsedSegments
                                        ? 'Click to hide previously used segments'
                                        : 'Click to show previously used segments'}
                                >
                                    <Styled.SegmentIconButton onClick={toggleShowProcessedSegments}>
                                        <Visibility data-active={showUsedSegments}/>
                                    </Styled.SegmentIconButton>
                                </Tooltip>
                                <Tooltip
                                    title={showSegmentsAsLineItems
                                        ? 'Switch to cozy view'
                                        : 'Switch to line item view'}
                                >
                                    <Styled.SegmentIconButton onClick={toggleSegmentListView}>
                                        <ListAlt data-active={showSegmentsAsLineItems}/>
                                    </Styled.SegmentIconButton>
                                </Tooltip>
                                <Tooltip title="Refresh">
                                    <Styled.SegmentIconButton
                                        disabled={segmentsLoading}
                                        onClick={() => refreshSegments()}
                                    >
                                        <Refresh/>
                                    </Styled.SegmentIconButton>
                                </Tooltip>
                                <Tooltip title="Timers">
                                    <Styled.SegmentIconButton onClick={showTimersPane}>
                                        <Timer data-active={toggleTimers}/>
                                    </Styled.SegmentIconButton>
                                </Tooltip>
                                {
                                    hasSelectedAtLeastOneGroup &&
                                    <Tooltip title={'Delete'}>
                                        <Styled.SegmentIconButton onClick={deleteSegments}>
                                            <Delete/>
                                        </Styled.SegmentIconButton>
                                    </Tooltip>
                                }
                                {(selectedSegments.length > 0 || selectedTimerSegments.length > 0) &&
                                <Tooltip title="Deselect All">
                                    <Styled.SegmentIconButton onClick={deselectAllSegments}>
                                        <Close/>
                                    </Styled.SegmentIconButton>
                                </Tooltip>}
                                {!toggleTimers &&
                                <FormControl variant={'filled'} className={classes.groupByForm}>
                                    <InputLabel
                                        shrink={true}
                                        FormLabelClasses={{
                                            root: classes.groupByLabel
                                        }}
                                    >
                                        Group by
                                    </InputLabel>
                                    <Select
                                        input={
                                            <BootstrapInput
                                                name={'groupBy'}
                                                id={'group-label-placeholder'}
                                            />}
                                        displayEmpty={true}
                                        name={'select-status'}
                                        value={SegmentViewMode[segmentViewMode]}
                                        onChange={toggleSegmentViewMode}
                                    >
                                        <MenuItem value={'CHRONOLOGICAL'}>None</MenuItem>
                                        <MenuItem value={'APPLICATION_TYPE'}>Application</MenuItem>
                                        <MenuItem value={'TIME_TYPE'}>Time</MenuItem>
                                    </Select>
                                </FormControl>}
                            </Styled.CapturedSegmentsControls>
                            {/*{segmentsLoading*/}
                            {/*    ? <LinearProgressBar color={'primary'} progressBar={50}/>*/}
                            {/*    : <div style={{backgroundColor: '#444'}}/>}*/}
                            <Styled.CapturedSegmentsListContainer>
                                <Styled.SegmentsPane>
                                    {(filteredChronologicalSegments.length > 0 ||
                                        Object.keys(filteredApplicationTypeSegments).length > 0 ||
                                        Object.keys(filteredTimeTypeSegments).length > 0
                                    ) && 
                                    <CaptureSegmentsList
                                        segmentViewMode={segmentViewMode}
                                        filteredApplicationTypeSegments={filteredApplicationTypeSegments}
                                        filteredChronologicalSegments={filteredChronologicalSegments}
                                        filteredTimeTypeSegments={filteredTimeTypeSegments}
                                        getProgram={getProgram}
                                        toggleSegmentGroupOrGapSelection={toggleSegmentGroupOrGapSelection}
                                        isSelected={isSelected}
                                        selectSameWindows={selectSameWindows}
                                        toggleApplicationExpansion={toggleApplicationExpansion}
                                        updateTimeEntryFromDragSource={updateTimeEntryFromDragSource}
                                        toggleTimeGroupExpansion={toggleTimeGroupExpansion}
                                        getProgramColor={getProgramColor}
                                        LoadingDiv={LoadingDiv}
                                        observables={captureChunkObservables}
                                    />}
                                </Styled.SegmentsPane>
                                {toggleTimers &&
                                <Styled.SegmentsPane>
                                    {filteredDayViewTimers.length > 0 ?
                                        filteredDayViewTimers.slice().sort().map((timer, index) =>
                                            <SegmentExpansion
                                                key={index}
                                                toggleSelection={toggleTimerSelection}
                                                isSelected={(group: TimerChunk) => isSelectedTimerSegment(group)}
                                                expanded={expandedTimers.includes(timer.id!)}
                                                toggleExpansion={() => toggleTimerExpansion(timer.id!)}
                                                selectedSegmentsToDrag={
                                                    selectedTimerSegments.length + selectedSegments.length
                                                }
                                                onDrop={updateTimeEntryFromDragSource}
                                                timer={timer}
                                                timeEntryOpen={openTimeEntryFromTimerSegment}
                                            />) :
                                        <div>No captured data.</div>
                                    }
                                </Styled.SegmentsPane>
                                }
                            </Styled.CapturedSegmentsListContainer>

                        </Styled.CapturedSegmentsContainer>
                    )}
            </FeaturesConsumer>
        )
    }
}
import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import UnsavedStore from 'store/unsaved.store';

interface Props {
    unsavedStore?: UnsavedStore;
}
@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        unsavedStore: rootStore.unsavedStore
    };
})
@observer
export default class UnSavedDialog extends React.Component<Props> {
    render() {
        let {
            isOpen,
            cancel,
            save
        } = this.props.unsavedStore!;
        return (
            <Dialog
                open={isOpen}
                onClose={cancel}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle id="unsaved-dialog-title">Unsaved Changes</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You have unsaved changes. Do you want to continue without saving?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={save} color="primary">
                        Yes
                    </Button>
                    <Button onClick={cancel} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
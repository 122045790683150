import ClientAPI from 'api/interfaces/ClientAPI';
import BaseWebImplementation from './Base.impl';

export default class ClientImpl extends BaseWebImplementation implements ClientAPI {
    searchClients = async (searchText: string) => {
        let resp = await this.http.get(`/clients?offset=0&limit=50&search=${searchText}`,
            {
                headers: {
                    timeKeeper: this.root.Session.currentTimeKeeper
                }
            }
            );
        return resp.data;
    }
    
    getClients = async () => {
        let { data } = await this.http.get(`/clients?offset=0&limit=50`);
        return data;
    }

    get = async (id: number) => {
        return null;
    }
}
import * as React from 'react';
import * as Styled from './styled.desktop';
import {
    DateRange,
    Home,
    FileCopy,
    KeyboardArrowLeft,
    KeyboardArrowRight, Note, Settings, Cloud, Timer, List, Search
} from '@material-ui/icons';
import { FlexDiv } from 'common/flex';
import { NavBarStyles } from './styled.desktop'
import { Badge, InputAdornment, Menu, MenuItem, TextField, Tooltip, withStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import AppStore from 'store/app.store';
import { withRouter } from 'react-router';
import NavigationStore from 'store/navigation.store';
import { Platform } from '../../util/Platform';
import TimeKeepersList from 'components/TimeKeepersList/TimeKeepersList';
import { DateTime } from 'luxon';

interface State {
    expanded: boolean;
    userMenuOpen: boolean;
}

// tslint:disable:no-any
interface Props {
    appStore?: AppStore;
    routerStore?: NavigationStore;
    match: {
        path: string;
        params: string;
        isExact: boolean;
        url: string;
    } | any;
    location: {
        pathname: string
    } | any;
    history: any;
    classes?: any;
}
// tslint:enable:no-any

function getInitials(name: string): string {
    const firstAndLastName = (name || '').split(/\s/);
    const firstName = firstAndLastName.shift() || '';
    const lastName = firstAndLastName.pop() || '';
    const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;
    
    return initials.toUpperCase();
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        appStore: rootStore.appStore,
        routerStore: rootStore.routerStore
    };
})
@observer
export class NavBarDesktop extends React.Component<Props, State> {
    userMenuAnchor = React.createRef<HTMLLIElement>();
    preventClick: boolean = false;
    // tslint:disable-next-line:no-any
    timer: any;

    state = {
        expanded: false,
        userMenuOpen: false
    };

    handleExpand = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    synchronize = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        clearTimeout(this.timer);
        this.preventClick = true;
        if (e.shiftKey) {
            if (Platform.isElectron()) {
                this.props.appStore!.reSynchronize();
            } else {
                this.props.appStore!.getFeaturesApi();
            }
        } else {
            this.props.appStore!.synchronize();
        }
    }

    handleTkSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.props.appStore!.setTkSearchText(evt.target.value);
    };
    
    // openTkList = () => {
    //     this.setState({ tkMenuOpen: true });
    //     this.props.appStore!.setTkSearchText('');
    //    
    // }

    takeMeToOfflineView = () => {
        if (this.props.appStore!.offlineEntryCount === 0) {
            return;
        }
        if (Platform.isElectron()) {
            this.props.routerStore!.attemptPush(`/settings/offlineEntries`)
        }
    }

    goToOfflineView = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        this.timer = setTimeout(() => {
            if (!this.preventClick) {
                this.takeMeToOfflineView();
            }
            this.preventClick = false;
        }, 500);
    }
    
    goToView = (path: string) => {
        if (this.props.location.pathname.startsWith('/settings') && path.startsWith('/settings')) { return; }
        this.props.routerStore!.attemptPush(path);
    }
    logout = () => {
        this.setState({ userMenuOpen: false })
        if (Platform.isElectron()) {
            this.props.appStore!.attemptHardLogout();
        } else {
            this.props.appStore!.logOut();
        }
    }

    render() {
        const { expanded } = this.state;
        const { classes } = this.props;
        const {
            user,
            currentTimekeeper,
            uniqueTimeKeepers,
            setTimekeeper,
            online,
            synchronize,
            offlineEntryCount,
            tkSearchText,
            filteredTimeKeepers,
            disableAnimations
        } = this.props.appStore!;
        return (
            <Styled.NavBarContainer>
                {this.props.location.pathname.startsWith(`/timersPopOut`) ? <></> :
                <Styled.IconList expanded={expanded} animationsEnabled={!disableAnimations}>
                    <Styled.Logo onClick={this.handleExpand}>
                        <Styled.EPImg src="static/epoch-logo-text-left.png"/>
                        <Styled.OImg src="static/epoch-logo-small.png"/>
                        <Styled.CHImg src="static/epoch-logo-text-right.png"/>
                    </Styled.Logo>
                    <Tooltip title="Home" placement="right" disableHoverListener={expanded}>
                        <Styled.IconItem 
                            onClick={() => this.goToView('/home')}  
                            selected={this.props.location.pathname.startsWith('/home')}
                        >
                            <Home fontSize="inherit"/>
                            <Styled.IconText>Home</Styled.IconText>
                        </Styled.IconItem>
                    </Tooltip>
                        
                    <Tooltip title="Time Entries" placement="right" disableHoverListener={expanded}>
                        <Styled.IconItem 
                            selected={this.props.location.pathname.startsWith('/timeentries')}
                            onClick={() => this.goToView('/timeentries')}
                        >
                            <DateRange fontSize="inherit"/>
                            <Styled.IconText>Time Entries</Styled.IconText>
                        </Styled.IconItem>
                    </Tooltip>
                    
                    <Tooltip title="Matters" placement="right" disableHoverListener={expanded}>
                        <Styled.IconItem 
                            selected={this.props.location.pathname.startsWith('/matters')}
                            onClick={() => this.goToView('/matters')}
                        >
                            <List fontSize="inherit"/>
                            <Styled.IconText>Matters</Styled.IconText>
                        </Styled.IconItem>
                    </Tooltip>
                    
                    <Tooltip title="Templates" placement="right" disableHoverListener={expanded}>
                        <Styled.IconItem 
                            selected={this.props.location.pathname.startsWith('/templates')}
                            onClick={() => this.goToView('/templates')}
                        >
                            <FileCopy fontSize="inherit"/>
                            <Styled.IconText>Templates</Styled.IconText>
                        </Styled.IconItem>
                    </Tooltip>
                    
                    <Tooltip title="Timers" placement="right" disableHoverListener={expanded}>
                        <Styled.IconItem 
                            selected={this.props.location.pathname.startsWith('/timers')}
                            onClick={() => this.goToView('/timers')}
                        >
                            <Timer fontSize="inherit"/>
                            <Styled.IconText>Timers</Styled.IconText>
                        </Styled.IconItem>
                    </Tooltip>
                    
                    <Tooltip title="Narrative Codes" placement="right" disableHoverListener={expanded}>
                    <Styled.IconItem 
                        selected={this.props.location.pathname.startsWith('/narratives')}
                        onClick={() => this.goToView('/narratives')}
                    >
                        <Note fontSize="inherit" />
                        <Styled.IconText>Narrative Codes</Styled.IconText>
                    </Styled.IconItem>
                    </Tooltip>
                    
                    <Tooltip title="Settings" placement="right" disableHoverListener={expanded}>
                    <Styled.IconItem 
                        selected={this.props.location.pathname.startsWith('/settings')}
                        onClick={() => this.goToView('/settings/general')}
                    >
                        <Settings fontSize="inherit" />
                        <Styled.IconText>Settings</Styled.IconText>
                    </Styled.IconItem>
                    </Tooltip>
                    
                    <FlexDiv flex={1} />
                    <Tooltip title={online ? 'Connected' : 'Offline'} placement="right" disableHoverListener={expanded}>
                        <Styled.IconItem>
                            <Badge badgeContent={offlineEntryCount} color="error">
                                <Cloud 
                                    onClick={this.goToOfflineView}
                                    onDoubleClick={this.synchronize}
                                    style={{marginLeft: '3px', color: online ? '#fff' : 'rgb(230, 107, 107)'}}
                                />
                            </Badge>
                            <Styled.IconText style={{marginLeft: 24}}>
                                {online ? 'Connected' : 'Offline'}
                            </Styled.IconText>
                        </Styled.IconItem>
                    </Tooltip>
                    <Tooltip title="Timekeepers" placement="right" disableHoverListener={expanded}>
                        <TimeKeepersList 
                            render={(tkMenuAnchor, openTkList) => (
                                <Styled.IconItem 
                                    onClick={openTkList} 
                                    innerRef={tkMenuAnchor} 
                                    style={{ overflow: 'visible' }}
                                >
                                    {(uniqueTimeKeepers.length > 1) ? (
                                        <Badge
                                            badgeContent={uniqueTimeKeepers.length}
                                            classes={{ badge: classes.customBadge }}
                                            color="primary"
                                        >
                                            <Styled.SmallAvatar primary={true}>
                                                {getInitials(currentTimekeeper.name)}
                                            </Styled.SmallAvatar>
                                        </Badge>
                                    ) : (
                                        <Styled.SmallAvatar primary={true}>
                                            {getInitials(currentTimekeeper.name)}
                                        </Styled.SmallAvatar>
                                    )}
                                    {this.state.expanded && <Styled.IconText>{currentTimekeeper.name}</Styled.IconText>}
                                </Styled.IconItem>
                                )} 
                            collaborate={false}
                            timeKeepers={filteredTimeKeepers}
                            menuWidth={300}
                            workDate={DateTime.local().startOf('day').toISO()}
                        />
                    </Tooltip>
                    <Tooltip
                        title={this.props.appStore!.user.displayName}
                        placement="right"
                        disableHoverListener={expanded}
                    >
                        <Styled.IconItem
                            onClick={() => this.setState({ userMenuOpen: true })}
                            innerRef={this.userMenuAnchor}
                        >
                            <Styled.SmallAvatar>{getInitials(user.displayName)}</Styled.SmallAvatar>
                            <Styled.IconText>{user.displayName}</Styled.IconText>
                        </Styled.IconItem>
                    </Tooltip>
                    
                    <Menu
                        anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
                        anchorEl={this.userMenuAnchor.current}
                        open={this.state.userMenuOpen}
                        onClose={() => this.setState({ userMenuOpen: false })}
                    >
                        <MenuItem 
                            disabled={!online} 
                            onClick={this.logout}
                        >
                            Logout
                        </MenuItem>
                    </Menu>
                    <Styled.IconItem onClick={this.handleExpand}>
                        {expanded ? (
                            <KeyboardArrowLeft fontSize="inherit"/>
                        ) : (
                            <KeyboardArrowRight fontSize="inherit"/>
                        )}
                        <Styled.IconText>Collapse</Styled.IconText>
                    </Styled.IconItem>
                </Styled.IconList>}
            </Styled.NavBarContainer>
        );
    }
}

// tslint:disable-next-line:no-any
export default  withRouter(withStyles(NavBarStyles as any)(NavBarDesktop));
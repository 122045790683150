import { Platform } from '../util/Platform';
const constants = require('constants.json');

const winston = require('winston');

// tslint:disable-next-line:no-any
const formatLine = winston.format.printf((info: any) => {
    return `${info.level}\t[${info.timestamp}] ${info.message}`;
});

const logger = winston.createLogger({
    level: 'verbose',
    silent: true,
    exitOnError: false,
    label: 'epoch',
    format: winston.format.combine(
        // winston.format.colorize({
        //     all: true
        // }),
        winston.format.timestamp({
            format: 'YYYY-MM-DD HH:mm:ss,SSSZZ'
        }),
        // winston.format.errors({
        //     stack: true  
        // }),
        formatLine
    )
});

if (Platform.isElectron()) {
    
    const { ipcRenderer } = require('electron');
    // tslint:disable-next-line:no-any
    ipcRenderer.on(constants.ipcEvents.SET_APPDATA_PATH, (evt: any, path: string) => {
        logger.add(new winston.transports.File({
            filename: `${path}/logs/epoch.log`
        }));
    });
    
}

// If we're in web, then log to the `console` with the format:
if (Platform.isWeb()) {
    logger.add(new winston.transports.Console({
        format: winston.format.simple(),
    }));
}

export default logger;

import { Button } from '@material-ui/core';
import { CallMerge, Check, Delete, Save, Send, SwapHoriz, Undo } from '@material-ui/icons';
import * as Styled from '../../containers/Home/styled.desktop';
import * as React from 'react';
import TimeKeepersList from 'components/TimeKeepersList/TimeKeepersList';
import { TimeKeeperAssignment } from '../../api/types/types';
import { DateTime } from 'luxon';

interface Props {
    dirty: boolean;
    saveDirtyEntries: () => void;
    clearSelectedEntries: () => void;
    postSelectedEntries: () => void;
    moveSelectedEntries: () => void;
    mergeEntries: () => void;
    transferEntries: () => void;
    deleteSelectedEntries: () => void;
    selectedEntryIds: number[];
    selectedIdsForCollab: number[];
    online: boolean;
    getAllTimeKeepers: (workDate: string) => Promise<void>;
    filteredAllTimekeepersList: Array<TimeKeeperAssignment>;
    workDate: string;
    setTkSearchText:  (value: string) => void;
}

export const FabContainerView = (props: Props) => {
    const {
        dirty,
        saveDirtyEntries,
        clearSelectedEntries,
        postSelectedEntries,
        moveSelectedEntries,
        mergeEntries,
        transferEntries,
        deleteSelectedEntries,
        selectedEntryIds,
        selectedIdsForCollab,
        online,
        getAllTimeKeepers,
        setTkSearchText,
        filteredAllTimekeepersList,
        workDate
    } = props;

    return (
        <Styled.FabContainer onScreen={selectedEntryIds.length > 0 || dirty}>
            {dirty &&
            <Button
                onClick={saveDirtyEntries}
                variant="extendedFab"
                aria-label="Save"
            >
                <Save/>
                Save Changes
            </Button>}
            {(selectedEntryIds.length > 0) && <>
                {online && (selectedIdsForCollab.length > 0) && <TimeKeepersList
                    render={(tkMenuAnchor, openTkList) =>
                        <Styled.CollaborateBtn innerRef={tkMenuAnchor}>
                            <Button
                                onClick={evt => {
                                    setTkSearchText('')
                                    getAllTimeKeepers(workDate).then(() => openTkList(evt))
                                }
                                }
                                variant="extendedFab"
                                aria-label="Collaborate"
                            >
                                Collaborate ({selectedIdsForCollab.length})
                            </Button>
                        </Styled.CollaborateBtn>}
                    collaborate={true}
                    menuWidth={300}
                    timeKeepers={filteredAllTimekeepersList}
                    workDate={workDate}
                />}
                <Button
                    onClick={clearSelectedEntries}
                    variant="extendedFab"
                    color="secondary"
                    aria-label="Clear"
                >
                    <Undo/>
                    Clear ({selectedEntryIds.length})
                </Button>
                <Button
                    onClick={postSelectedEntries}
                    variant="extendedFab"
                    aria-label="Post"
                >
                    <Check/>
                    Post ({selectedEntryIds.length})
                </Button>
                <Button
                    onClick={moveSelectedEntries}
                    variant="extendedFab"
                    aria-label="move"
                >
                    <Send/>
                    Move ({selectedEntryIds.length})
                </Button>
                {selectedEntryIds.length > 1 &&
                <Button
                    onClick={mergeEntries}
                    variant="extendedFab"
                    aria-label="merge"
                >
                    <CallMerge/>
                    Merge ({selectedEntryIds.length})
                </Button>
                }
                {selectedEntryIds.length > 1 &&
                <Button
                    onClick={transferEntries}
                    variant="extendedFab"
                    aria-label="transfer"
                >
                    <SwapHoriz/>
                    Transfer ({selectedEntryIds.length})
                </Button>
                }
                <Button
                    onClick={deleteSelectedEntries}
                    variant="extendedFab"
                    aria-label="Delete"
                >
                    <Delete/>
                    Delete ({selectedEntryIds.length})
                </Button>
            </>}
        </Styled.FabContainer>
    )
}
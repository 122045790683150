import { DialogRootStore } from 'store/dialog.root.store';
import { onOpen } from '@fulcrumgt/mobx-store-utils';
import { observable } from 'mobx';
import { PopoutErrorMessage } from 'components/TimerPopOut/TimerPopoutErrorDialog';

export default class TimerPopoutErrorMessageDialogStore extends DialogRootStore {
    @observable errorMessages: PopoutErrorMessage[] = [];
    
    @onOpen()
    dialogOpen(messages: PopoutErrorMessage[]) {
        this.errorMessages = messages;
    }
}
import React, { Component } from 'react';
import { Tabs, Tab, AppBar, Typography, List, Paper, Badge } from '@material-ui/core';
import PendingItemStore from 'store/pendingItem.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import * as Styled from 'components/OfflineEntries/styled';
import TimeEntryPanel from 'components/TimeEntryPanel';
import ImmutableTimeEntry from '../../api/immutables/ImmutableTimeEntry';
import ImmutableTemplate from '../../api/immutables/ImmutableTemplate';
import TimeEntryStore from 'store/timeentry.store';

import TemplateStore from 'store/template.store';
import TemplatePanelItem from 'components/OfflineEntries/TemplatePanelItem';
import TimerPanelItem from 'components/OfflineEntries/TimerPanelItem';

enum OFFLINE_VIEW_TABS {
    TIMEENTRIES,
    TEMPLATES,
    TIMERS
}
interface OfflineProps {
    pendingItemsStore?: PendingItemStore;
    timeEntryStore: TimeEntryStore;
    templateStore: TemplateStore;
}

interface OfflineTabState {
    currentTab: number
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        pendingItemsStore: rootStore.pendingItemsStore,
        timeEntryStore: rootStore.timeEntryStore,
        templateStore: rootStore.templateStore
    }
})

@observer
export default class OfflineEntries extends Component<OfflineProps, OfflineTabState> {
    constructor(props: OfflineProps) {
        super(props);
        this.state = {
            currentTab: OFFLINE_VIEW_TABS.TIMEENTRIES
        }
    }
    handleTabChange = (event: React.ChangeEvent, newValue: number) => {
        this.setState( {
            currentTab: newValue
        })
    }
    expandTimeEntry = (id: number) => () => {
        let expanded = this.props.timeEntryStore!.expandedEntryIds.slice();
        if (expanded.includes(id)) {
            expanded = expanded.filter((s) => s !== id)
        } else {
            expanded.push(id);
        }
        this.props.timeEntryStore!.setExpandedTimeEntries(expanded);
    }
    expandTemplate = (id: number) => () => {
        let expanded = this.props.templateStore!.expandedTemplateIds.slice();
        if (expanded.includes(id)) {
            expanded = expanded.filter((s) => s !== id)
        } else {
            expanded.push(id);
        }
        this.props.templateStore!.setExpandedTemplates(expanded);
    }
    
    render() {
        const value = this.state.currentTab;
        const { 
            pendingTimeEntries, 
            pendingTemplates, 
            pendingTimers, 
            getTimerChunks } = this.props.pendingItemsStore!;
        const { expandedEntryIds } = this.props.timeEntryStore!;
        const { onTemplateChange, expandedTemplateIds } = this.props.templateStore!;
        
        return (
            <Styled.OfflineContainer>
                <AppBar position="static" color="default">
                    <Tabs 
                        value={value}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        variant="fullWidth"
                        centered={true}
                    >
                        <Tab 
                            value={OFFLINE_VIEW_TABS.TIMEENTRIES} 
                            label={
                                <Badge badgeContent={pendingTimeEntries.length} color={'error'}>
                                    {'Time Entries '}
                                </Badge>} 
                        />
                        <Tab 
                            value={OFFLINE_VIEW_TABS.TEMPLATES}
                            label={
                                <Badge badgeContent={pendingTemplates.length} color={'error'}>
                                    {'Templates '}
                                </Badge>}
                        />
                        <Tab 
                            value={OFFLINE_VIEW_TABS.TIMERS}
                            label={
                                <Badge badgeContent={pendingTimers.length} color={'error'}>
                                    {'Timers '}
                                </Badge>}
                        />
                    </Tabs>
                    
                </AppBar>
                <Styled.OfflineList>
                {value === OFFLINE_VIEW_TABS.TIMEENTRIES && 
                    <Styled.TabContainer>
                        {pendingTimeEntries.map((entry: ImmutableTimeEntry) => 
                            <TimeEntryPanel 
                                timeEntry={entry} 
                                onChange={this.props.timeEntryStore!.changeEntry}
                                key={entry.id as number}
                                expanded={expandedEntryIds.includes(entry.id as number)}
                                onExpand={this.expandTimeEntry(entry.id as number)}
                                disabled={true}
                                noCheckBox={true}
                            />
                        )}
                    </Styled.TabContainer>
                }
                {value === OFFLINE_VIEW_TABS.TEMPLATES &&
                    <Styled.TabContainer>
                        {pendingTemplates.map((template: ImmutableTemplate) => 
                            <TemplatePanelItem 
                                template={template} 
                                onExpand={this.expandTemplate(template.id as number)} 
                                onChange={onTemplateChange}
                                expanded={expandedTemplateIds.includes(template.id as number)}
                                key={template.id as number}
                            />
                        )}
                    </Styled.TabContainer>
                }
                {value === OFFLINE_VIEW_TABS.TIMERS &&
                    <Styled.TabContainer>
                        {pendingTimers.map((timer) =>
                                <TimerPanelItem 
                                    timer={timer}
                                    key={timer.id as number}
                                    timerChunks={getTimerChunks(timer.id)}
                                />
                            )}
                    </Styled.TabContainer>
                }
                </Styled.OfflineList>
            </Styled.OfflineContainer>
        );
    }
}
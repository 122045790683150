import { action, computed, observable } from 'mobx';
import { onOpen, onSave } from '@fulcrumgt/mobx-store-utils';
import TimeEntry from 'api/immutables/ImmutableTimeEntry';
import ImmutableTimeEntry, { SapStatus } from 'api/immutables/ImmutableTimeEntry';
import { DateTime } from 'luxon';
import Template from 'api/immutables/ImmutableTemplate';
import ImmutableTemplate from 'api/immutables/ImmutableTemplate';
import {
    ValidatePost,
    ValidateSave,
    ValidateTemplate,
    ValidationState,
    ValidationTemplateState
} from 'api/immutables/validators';
import { DialogRootStore } from 'store/dialog.root.store';
import { CodeSetFlags, TimeEntryType } from 'api/types/types'
import { RootStore } from './root.store';
import { debounce } from 'typescript-debounce-decorator';
import { ApiResult } from '../api/util';
import { Platform } from '../util/Platform';
import logger from '../logging/logging';

export default class TimeEntryDialogStore extends DialogRootStore {
    @observable entry: TimeEntry;
    @observable templateName: string = '';
    @observable selectedTemplate?: Template | null;
    @observable createAnotherFlag: boolean = false;
    @observable validationState?: ValidationState;
    @observable durationValidationState?: boolean;
    @observable templateValidationState?: ValidationTemplateState;
    @observable saving: boolean = false;
    @observable disableCreateAnother: boolean | undefined = false;
    @observable narrativeText: string | null = '';

    @computed get minNarrativeLength() {
        return this.rootStore!.appStore!.features!.EpochConfigNarrativesMinimumChars;
    }
    @computed get maxNarrativeLength() {
        return this.rootStore!.appStore!.features!.EpochConfigNarrativesMaximumChars;
    }
    
    createHandler?: (timeEntry: TimeEntry) => void;
    saveHandler?: (results: ApiResult<ImmutableTimeEntry>[]) => void
    
    constructor(rs: RootStore) {
        super(rs);
        this.wrappedSave = this.wrappedSave.bind(this);
        this.wrappedPost = this.wrappedPost.bind(this);
    }
    
    open = (input?: TimeEntry, createHandler?: (timeEntry: TimeEntry) => void, disableFeature?: boolean,
            saveHandler?: (results: ApiResult<ImmutableTimeEntry>[]) => void): Promise<{}> => {
        this.createHandler = createHandler;
        this.disableCreateAnother = disableFeature;
        this.saveHandler = saveHandler;
        return super.open(input);
    }

    @onOpen()
    @action setEntry(entry: TimeEntry) {
        // entry.timeEntryType = TimeEntryType.NORMAL;
        entry.timeEntryType = (entry.timeEntryType === TimeEntryType.COLLABORATE) ?
            TimeEntryType.COLLABORATE : TimeEntryType.NORMAL;
        this.entry = entry;
        this.narrativeText = entry.narrative;
        if (this.entry.id) {
            this.createAnotherFlag = false;
        }
        this.validationState = undefined;
        this.rootStore.setColloaboratees([]);
    }
    
    @action changeEntry = (entry: TimeEntry, newVState?: ValidationState, durVstate?: boolean) => {
        this.entry = entry;
        this.narrativeText = entry.narrative;
        this.validationState = newVState;
        if (durVstate !== undefined) {
            this.durationValidationState = durVstate;
        }
        this.templateName = (entry.matterId === null) ? '' : this.templateName;
        this.templateValidationState = undefined;
    }
    
    @action toggleCreateAnotherFlag = () => {
        this.createAnotherFlag = !this.createAnotherFlag;
    }
    @debounce(500, {leading: false})
    @action 
    async wrappedPost () {
        if (this.saving) {
            return;
        }
        this.saving = true;
        try {
            await this.postEntry();
        } finally {
        this.saving = false;
        }
    }
    @debounce(500, {leading: false})
    @action 
    async wrappedSave() {
        if (this.saving) {
            return;
        }
        this.saving = true;
        try {
            await this.saveEntry();
        } finally {
        this.saving = false;
        }
    }
    @action postEntry = async () => {
        if (this.entry.matterId) {
            try {
                await this.getInvalidWords(this.entry.matterId);
                const narrLengthObj = await this.rootStore.api.TimeEntry.getNarrativeLength(this.entry.matterId!);
                let newEntry = this.entry
                    .setMinNarrativeLength(narrLengthObj.minLength)
                    .setMaxNarrativeLength(narrLengthObj.maxLength);
                this.entry = newEntry.clone();
            } catch (e) {
                logger.info('Failed fetching narrative lengths or restricted words', e);
            }
        }
        const activeTimeKeeper = this.rootStore.appStore.getActiveTimeKeeperForDate(DateTime.fromISO(this.entry.workDateTime));
        this.entry = this.entry.setOffice(activeTimeKeeper ? activeTimeKeeper.office : undefined)
            .setOfficeName(activeTimeKeeper ? activeTimeKeeper.officeName : undefined)
        
        // todo 24 hour validation 
        let vstate = ValidatePost(
            this.entry, 
            await this.getTotalDurationExclusive(this.entry.workDateTime, this.entry.id!), 
            ['STOP', 'HOLD', 'DCLN', 'HCLS', 'SCLS', 'BUDG'], 
            ['02'],
            this.rootStore.appStore.features,
            activeTimeKeeper
        );
        let template: ImmutableTemplate | undefined;
        if (this.templateName.trim().length > 0) {
            // TODO validate template
            template = this.entry.createTemplate();
            template.name = this.templateName;
            let templateValidationState = ValidateTemplate(
                template,
                 await this.rootStore.api.Template.getAllTemplates(), this.maxNarrativeLength);
            if (!templateValidationState.valid) {
                this.templateValidationState = templateValidationState;
                return;
            }
        }
        // if (Platform.isElectron()) {
        //     vstate = await this.validateCodeSets(this.entry, vstate);
        // }
        if  (vstate.valid && !this.durationValidationState) {
            this.entry = this.entry.setPosted();
            await this.saveEntry(); 
            this.rootStore.homeStore.setTimersForDay();
            return;
        }
        if (!vstate.valid) {
            this.validationState = vstate;
        }
    }
    @action validateCodeSets = async(entry: TimeEntry, vstate: ValidationState) => {
        if (entry.phaseId) {
            let phaseCode = await this.rootStore.api.Code.get(entry.phaseId);
            if (phaseCode.deleted) {
                vstate.missing.phase = true;
            }
        }
        if (entry.taskCodeId) {
            let taskCode = await this.rootStore.api.Code.get(entry.taskCodeId);
            if (taskCode.deleted) {
                vstate.missing.task = true;
            }
        }
        if (entry.actCodeId) {
            let actCode = await this.rootStore.api.Code.get(entry.actCodeId);
            if (actCode.deleted) {
                vstate.missing.activity = true;
            }
        }
        if (this.rootStore.appStore.features.EpochConfigFlatFeeCodesEnabled) {
            if (entry.ffTaskCodeId) {
                let ffTaskCode = await this.rootStore.api.Code.get(entry.ffTaskCodeId);
                if (ffTaskCode.deleted) {
                    vstate.missing.ffTask = true;
                }
            }
            if (entry.ffActCodeId) {
                let ffActCode = await this.rootStore.api.Code.get(entry.ffActCodeId);
                if (ffActCode.deleted) {
                    vstate.missing.ffAct = true;
                }
            }
        }
        return vstate;
    }
    @action setWorkDate = (date: DateTime) => {
        let actTk = this.rootStore.appStore.getActiveTimeKeeperForDate(date);
        this.entry = this.entry
        .setWorkDate(date)
        .setOffice(actTk ? actTk.office : undefined);
        if (this.validationState) {
            this.validationState.invalidWorkDate = false;
        }
    }
    
    @action setTemplateName = (name: string) => {
        this.templateValidationState = undefined;
        this.templateName = name;
    }
    
    @action setTemplate = async (t?: Template) => {
        this.selectedTemplate = t;
        if (t) {
            if (t.matter) {
                const codeSetFlags: CodeSetFlags =
                    await this.rootStore.api.Code.determineCodeSetFields(t.matter.id, this.entry.workDateTime);
                t.isPhaseCode = codeSetFlags.isPhaseCode;
                t.isFfTaskCode = codeSetFlags.isFfTaskCode;
                t.isActCode = codeSetFlags.isActCode;
            }
            this.entry = await this.setTemplateProps(t);
            this.validationState = undefined;
            this.templateValidationState = undefined;
            this.templateName = ''
        } else {
            if (this.entry.narrative !== this.narrativeText) {
                this.entry.narrative = this.narrativeText!.replace(this.entry.narrative!, '').trim();
            }
        }
    }
    setTemplateProps = async (t: Template) => {
        let te: ImmutableTimeEntry = this.entry.clone();
        te = this.entry.loadFromTemplate(t);
        te.narrative  = [te.narrative, this.narrativeText ].join(' ').trim();
        te.selectedCodeSetTemplate = null;
        return te;
    }
    
    async getTotalDurationExclusive (workDate: string, id: number) {
        return await this.rootStore.api.TimeEntry.getTotalForDateExclusive(workDate, [id])
    }

    @action
    getInvalidWords = async (matterId: number) => {
        let invalidWords = await Promise.all([
            this.rootStore.api.Matter.getBannedWords(matterId),
            this.rootStore.api.Matter.getBlockBillingWords(matterId)
        ]);
        this.entry.bannedWords = invalidWords[0];
        this.entry.blockBillingWords = invalidWords[1];
    }
    
    @action
    saveEntry = async () => {
        if (this.entry.matterId) {
            await this.getInvalidWords(this.entry.matterId);
        };
        
        let vstate = ValidateSave(
            this.entry,
            await this.getTotalDurationExclusive(this.entry.workDateTime, this.entry.id!),
        
            this.rootStore.appStore.features,
            this.rootStore.appStore.getActiveTimeKeeperForDate(
                DateTime.fromISO(this.entry.workDateTime)));
        if (Platform.isElectron()) {
            vstate = await this.validateCodeSets(this.entry, vstate);
        }
        if (!vstate.valid) {
            this.validationState = vstate;
            return;
        }
        if (this.durationValidationState) {
            return;
        }
        let template: ImmutableTemplate | undefined;
        if (this.templateName.trim().length > 0) {
            // TODO validate template
            template = this.entry.createTemplate();
            template.name = this.templateName;
            let templateValidationState = ValidateTemplate(
                template,
                 await this.rootStore.api.Template.getAllTemplates(), this.maxNarrativeLength);
            if (!templateValidationState.valid) {
                this.templateValidationState = templateValidationState;
                return;
            }
        }
        if (this.rootStore.collaboratees.length > 0) {
            // this.entry.timeEntryType = TimeEntryType.COLLABORATE
            this.entry.collaborateTks = this.rootStore.collaboratees.join();
        }
        let results = await this.rootStore.api.TimeEntry.updateEntries([this.entry]);
        let result = results[0];
        
        if (result.status.failed) {
            this.rootStore.snackbarStore.triggerSnackbar(result.status.message);
            this.entry.sapStatus = SapStatus.UNSUBMITTED;
            this.entry = this.entry.clone();
            return;
        } 

        if (!result.status.failed) {
            this.rootStore.snackbarStore.triggerSnackbar('Saved Successfully');
        } 

        if (template) {
            let templateResult = await this.rootStore.api.Template.saveTemplate(template);
        }
        let entry = Object.assign(new TimeEntry(), JSON.parse(JSON.stringify(results[0].object)));
        entry.isActCode = this.entry.isActCode;
        entry.isPhaseCode = this.entry.isPhaseCode;
        entry.isFfTaskCode = this.entry.isFfTaskCode;
        
        if (this.saveHandler) {
            this.saveHandler(results)
        }
        
        if (this.createAnotherFlag) {
            // this.rootStore.homeStore.changeEntry(entry);
            if (this.createHandler) {
                this.createHandler(entry);
            }

            this.createAnotherEntry(entry);
        } else {
            this.rootStore.setColloaboratees([]);
            this.resolveAndClose(entry);
        }
        
        // @onSave is not getting called on clicking on Save. had to explicitly call clear function.
        this.clear();
        
        return;
    }

    @action
    createAnotherEntry = (oldEntry: TimeEntry) => {
        let actTk = this.rootStore.appStore.getActiveTimeKeeperForDate(
            DateTime.fromISO(oldEntry.workDateTime));
        let entry = new TimeEntry()
            .setWorkDate(DateTime.fromISO(oldEntry.workDateTime))
            .setOffice(actTk ? actTk.office : undefined)
            .setOfficeName(actTk ? actTk.officeName : undefined)
            .setClient(oldEntry.client)
            .setMatter(oldEntry.matter)
            .setDuration(0)
            .setActionCode(oldEntry.actionCodeObj)
            .setStatus(SapStatus.UNSUBMITTED)
            .setNarrative('');
        
        entry.timeKeeperId = oldEntry.timeKeeperId;
        this.selectedTemplate = undefined;
        this.templateName = '';
        this.changeEntry(entry);
    }
    
    @onSave()
    @action
    clear() {
        this.templateName = '';
        this.selectedTemplate = undefined;
        this.durationValidationState = undefined;
        this.templateValidationState = undefined;
        this.rootStore.homeStore.selectedSegments = [];
        this.rootStore.homeStore.selectedTimerSegments = [];
        this.rootStore.homeStore.validationState.clear();
        this.rootStore.timeEntryStore.validationState.clear();
        this.rootStore.collaboratees = [];
    }
}
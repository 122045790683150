import BaseWebImplementation from './Base.impl';
import TimeCastAPI from '../../interfaces/TimeCastAPI';
import { TimeCastProgram, TimeCastSegment, TimeCastSegmentType, SegmentOptions } from '../../types/types';
import { ApiResult } from 'api/util';
import { TimeCast } from '../../../util/TimeCast';
import { DateTime } from 'luxon';

export default class TimeCastImpl extends BaseWebImplementation implements TimeCastAPI {
    segmentHandlers: (((entries: TimeCastSegment[]) => void) | null )[] = [];
    programHandlers: (((entries: TimeCastProgram[]) => void) | null )[] = [];

    allPrograms = async (): Promise<TimeCastProgram[]> => {
        const { data } = await this.http.get(`segments/programs`);

        return data || [];
    };

    saveProgram = async (program: TimeCastProgram): Promise<TimeCastProgram> => {
        try {
            const clone = { ...program };
            const { data } = await this.http.post(`segments/programs`, clone);

            return { ...data };
        } catch (e) {
            throw e;
        }
    };

    getSegmentsBetween = async (startDateTime: string, endDateTime: string, options: SegmentOptions)
        : Promise<TimeCastSegment[]> => {
        let segments = await this.getSegmentTypesBetween(startDateTime, endDateTime, []);
        
        if (options.extractFromLocalOutlook) {
            const d = new Date(startDateTime);
            const year = d.getFullYear();
            const month = d.getMonth() + 1;
            const day = d.getDate();
            const outlookSegments = (await TimeCast.getLocalOutlookSegments(year, month, day)).filter((seg) => {
                const existing = segments.find(s =>
                    s.type.toUpperCase() === 'CONVERSATION_HISTORY' &&
                    DateTime.fromISO(s.startTime).equals(DateTime.fromISO(seg.startTime)) &&
                    DateTime.fromISO(s.endTime).equals(DateTime.fromISO(seg.endTime)) &&
                    s.data === seg.data
                )
                // Remove if the segments are already being saved in backend or local DB.
                if (existing) {
                    return false;
                } else {
                    return true;
                }
            });
            for (let i = 0; i < outlookSegments.length; i++) {
                const segment = outlookSegments[i]; 
                if (typeof segment.data === 'string') {
                    segment.data = JSON.parse(segment.data);
                }
            }
            segments = segments.concat(outlookSegments);
        }
        
        return segments;
    }

    /**
     * @param types
     *        empty array = get all types
     */
    getSegmentTypesBetween = async (
        startDateTime: string,
        endDateTime: string,
        types?: TimeCastSegmentType[]
    ): Promise<TimeCastSegment[]> => {
        try {
            let typesQuery = '';
            if (types && types.length > 0) {
                for (const type of types) {
                    typesQuery = `${typesQuery}&types=${type}`
                }
            }
            let { data } = await this.http.get<TimeCastSegment[]>(
                `segments?start=${startDateTime}&end=${endDateTime}${typesQuery}`
            );
            
            data = data.filter((d) => !d.deleted);

            // deserialize the 'data' string
            return data.map(segment => ({
                ...segment,
                data: (typeof segment.data as unknown === 'string')
                    ? JSON.parse(segment.data as unknown as string)
                    : segment.data
            }))
        } catch (e) {
            return [];
        }
    };
    
    saveSegments = async (segments: TimeCastSegment[]): Promise<ApiResult<TimeCastSegment>[]> => {
        try {
            segments = await segments.map(segment => {
                const clone = {...segment};
                if (typeof clone.data !== 'string') {
                    clone.data = JSON.stringify(clone.data);
                }
                return clone;
            })
            
            const { data } = await this.http.post(`segments/bulk`, segments);
            return data;
        } catch (e) {
            throw e;
        }
    };

    deleteSegments = async (segments: TimeCastSegment[]): Promise<ApiResult<TimeCastSegment>[]> => {
        const cloned = segments.map((segment) => {
            const clone = { ...segment };
            if (typeof clone.data !== 'string') {
                clone.data = JSON.stringify(clone.data);
            }
            return clone;
        }).map((seg) => {
            return {
                associatedTimeEntry: seg.associatedTimeEntry,
                createdBy: seg.createdBy,
                createdOn: seg.createdOn,
                data: seg.data,
                deleted: seg.deleted,
                endTime: seg.endTime,
                foreignIdentifier: seg.foreignIdentifier,
                id: seg.id,
                lastModified: seg.lastModified,
                startTime: seg.startTime,
                type: seg.type
            }
        })
        try {
            // tslint:disable-next-line:no-any
            const { data } = await this.http.delete(`/segments/bulkdelete`, {
                headers: {
                    timeKeeper: this.root.Session.currentTimeKeeper
                },
                data: cloned
            });
            return data;
        } catch (e) {
            throw e;
        }
    }
    
    registerReceiverForSegments = (handler: (s: TimeCastSegment[]) => void): () => void => {
        this.segmentHandlers.push(handler);
        const theIndex = this.segmentHandlers.length - 1;
        return  () => {
            this.segmentHandlers[theIndex] = null;
        }
    };

    registerReceiverForPrograms = (handler: (s: TimeCastProgram[]) => void): () => void => {
        this.programHandlers.push(handler);
        const theIndex = this.programHandlers.length - 1;
        return  () => {
            this.programHandlers[theIndex] = null;
        }
    };
    recieveSegments = (segments: TimeCastSegment[]) => {
        this.segmentHandlers.filter(h => h !== null).forEach(h => h!(segments))
    }

    getTimeCastSegmentsByTimeEntryIds = async (ids: number[]): Promise<TimeCastSegment[]> => {
        let csValues = ids.join(',');
        const { data } = await this.http.get(`/timeEntries/segments?ids=${csValues}`)
        return data;
    }

    updateTimeCastSegments = async (segmentIds: number[], timeEntryId: number):
     Promise<ApiResult<TimeCastSegment>[]> => {
        let segIds = segmentIds.join(',')
        try {
            const { data } = await this.http.post(`/segments/associate?timeEntryId=${timeEntryId}&ids=${segIds}`);
            return { ...data }
        } catch (e) {
            throw e;
        }
    }
}
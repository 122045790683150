import * as React from 'react';
import * as Styled from 'containers/Login/styled';
import { FlexDiv } from 'common/flex';
import { CircularProgress, LinearProgress, Typography } from '@material-ui/core';
import { Progress } from '../../api/types/types';

const svgLogo = require('images/logodark.svg');

interface Props {
    progress?: Progress
}

export default class Spinner extends React.Component<Props> {
    render() {
        const {
            progress
        } = this.props
        
        return (
            <Styled.LoginContainer>
                <FlexDiv flex={1}/>
                <Styled.LoginBox elevation={10} square={true}>
                    <Styled.LogoContainer>
                        <img src={svgLogo} height="80px"/>
                    </Styled.LogoContainer>
                    <div style={{textAlign: 'center'}}>
                        <CircularProgress size={100}/>
                        {progress && (
                            <Styled.LoginProgressDiv>
                                <Typography>
                                    {progress.message}
                                </Typography>
                                {/*<LinearProgress style={{marginTop: '10px'}} value={progress.percent}/>*/}
                            </Styled.LoginProgressDiv>
                        )}
                    </div>
                </Styled.LoginBox>
                <div style={{height: '100px'}}/>
                <FlexDiv flex={1}/>
            </Styled.LoginContainer>
        );
    }
}
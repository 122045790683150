import BaseWebImplementation from './Base.impl';
import CodeTemplateAPI from '../../interfaces/CodeTemplateAPI';
import { CodeSetTemplate } from '../../types/types';

export default class CodeTemplatesImpl extends BaseWebImplementation implements CodeTemplateAPI {
    getCodeSets = async (matterId?: number, search?: string, offset: number = 0, count: number = 50, ) => {
        const { data } = await this.http.get(
            `/templates/codeSet?count=${count}&offset=${offset}` +
            `${search ? `&search=${search}` : ``}` +
            `${matterId ? `&matter=${matterId}` : ``}`);
        return data;
    }
}
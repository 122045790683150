import { action, computed, observable } from 'mobx';
import { Narrative } from 'api/types/types';
import BaseStore from 'store/base.store';
import { removeListItem, setListItem } from 'util/array';
import { RootStore } from './root.store';
import { sortByAlphaNumeric } from '../util/utils';

export default class NarrativeStore extends BaseStore {
    @observable narratives: Narrative[] = [];
    @observable selectedNarrative: Narrative;
    @observable searchText: string = '';
    @observable dirty: boolean = false;
    @observable invalid: Record<string, string | boolean>;
    originalNarrative: Narrative;
    errGlobalDupTxt: string = '';
    @observable loading: boolean = false;

    handlerDestructor: () => void;
    constructor(rootStore: RootStore) {
        super(rootStore);
        this.initializeHandler();
    }
    initializeHandler = () => {
        this.handlerDestructor = this.rootStore.api.Narrative.registerReciever(this.recieveNarratives)
    }

    @computed get maxNarrativeLength() {
        return this.rootStore!.appStore!.features!.EpochConfigNarrativesMaximumChars;
    }

    @action recieveNarratives = (narratives: Narrative[]) => {
        narratives.forEach(n => {
            if (n.deleted) {
                this.narratives = removeListItem(this.narratives, n.id!);
                this.resetInvalid();
                if (this.selectedNarrative && this.selectedNarrative.id === n.id) {
                    this.selectedNarrative = new Narrative();
                    this.originalNarrative = Object.assign(new Narrative(), this.selectedNarrative);
                    this.dirty = false;
                    this.rootStore.routerStore.push(`/narratives/new`);
                }
                return;
            }
            let newLocal = this.narratives.slice();
            setListItem(newLocal, n);
            this.narratives = newLocal;
        })
    }

    @action loadNarratives = async () => {
        this.loading = true;
        this.narratives = await this.rootStore.api.Narrative.getAllNarratives();
        this.loading = false;
    };

    @action loadNarrative = async (id: number) => {
        this.selectedNarrative = await this.rootStore.api.Narrative.getNarrative(id);
        this.originalNarrative = Object.assign(new Narrative(), this.selectedNarrative);
    };

    @action changeSelectedNarrative = async (id: number) => {
        if (!this.selectedNarrative || id !== this.selectedNarrative.id) {
            const allowed = await this.rootStore.routerStore.attemptPush(`/narratives/${id}`);
        
            const narrative = this.narratives.find(n => n.id === id);
            if (narrative && allowed) {
                this.resetInvalid();
                this.selectedNarrative = narrative.clone();
                this.originalNarrative = narrative.clone();
            }
        }
    };

    @action newNarrative = async () => {
        const notDirty = await this.rootStore.routerStore.attemptPush(`/narratives/new`);
        if (notDirty) {
            this.resetInvalid();
            this.selectedNarrative = new Narrative();
            this.originalNarrative = Object.assign(new Narrative(), this.selectedNarrative);
        }
        this.dirty = false;
    };

    @action onChange = (n: Narrative) => {
        this.selectedNarrative = Object.assign(new Narrative(), n);
        this.dirty = true;
    };

    @action deleteNarrative = async (id: number) => {
        const idx = this.narratives.findIndex(t => t.id === id);
        if (idx > -1) {
            const allowedToNavigate = await this.rootStore.routerStore.attemptPush(`/narratives/new`);
            
            if (allowedToNavigate) {
                const confirmDelete = await this.rootStore.deleteConfirmationDialog.open();
                if (!confirmDelete) { return };

                this.narratives[idx].deleted = true;
                await this.rootStore.api.Narrative.saveNarrative(this.narratives[idx]);
                this.narratives.splice(idx, 1);
                this.newNarrative();
            }
        }
        
        this.rootStore.snackbarStore.triggerSnackbar('Deleted Successfully');
    };
    
    @action addNarrative = async (n: Narrative, isRedirect: boolean) => {
        this.dirty = false;

        let resp: Narrative;

        try {
            resp = await this.rootStore.api.Narrative.saveNarrative(n);
        } catch (e) {
            return;
        }

        const newNarrative = resp;

        this.narratives.unshift(newNarrative.clone());
        if (isRedirect) {
            this.selectedNarrative = newNarrative.clone();
            this.rootStore.routerStore.push(`/narratives/${newNarrative.id}`);
        }

        this.selectedNarrative = new Narrative()
        this.originalNarrative = new Narrative()
        
        this.rootStore.snackbarStore.triggerSnackbar('Saved Successfully');
    }

    @action saveNarrative = async (n: Narrative) => {
        const idx = this.narratives.findIndex(nar => nar.id === n.id);
        let resp: Narrative;
        n.replacement = n.replacement.trim();
        this.loading = true;
        try {
            resp = await this.rootStore.api.Narrative.saveNarrative(n);
        } catch (e) {
            return;
        }
        const newNarrative = resp;
        if (idx > -1) {
            this.narratives.splice(idx, 1);
            this.narratives.unshift(newNarrative.clone());
        } else {
            this.narratives.unshift(newNarrative.clone());
            this.selectedNarrative = newNarrative.clone();
            this.rootStore.routerStore.push(`/narratives/${newNarrative.id}`);
        }
        this.selectedNarrative = new Narrative()
        this.originalNarrative = newNarrative.clone();
        this.dirty = false;
        this.loading = false;
        this.rootStore.routerStore.push(`/narratives/new`);
        this.rootStore.snackbarStore.triggerSnackbar('Saved Successfully');
    };

    @action restoreNarrative = () => {
        this.selectedNarrative = Object.assign(new Narrative(), this.originalNarrative);
        this.resetInvalid();
        this.dirty = false;
    };

    @action onSearchChange = (input: string) => {
        this.searchText = input;
    };

    @action isKeyDuplicate = (key: string): boolean => {
        this.errGlobalDupTxt = '';
        let obj = this.narratives.find(n =>
            (key.toLowerCase() !== this.originalNarrative.key.toLowerCase() && 
                n.key.toLowerCase() === key.toLowerCase()));
        let globalAndUserCodes = this.narratives
            .filter(g => key.toLowerCase() !== this.originalNarrative.key.toLowerCase() 
                &&
                g.key.toLowerCase() === key.toLowerCase()
            );
        if (globalAndUserCodes.length > 0) {
            const allGlobals: boolean = globalAndUserCodes.every((gl) => gl.global);
            if (allGlobals) {
                const globalIdx = globalAndUserCodes.find((gn) => gn.global);
                if (globalIdx) {
                    this.errGlobalDupTxt = '\\' + globalIdx.key + ' will override existing global narrative';
                }
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    @action isKeyGlobalDuplicate = (key: string) => {
        this.invalid.key = this.isKeyDuplicate(key) ? false : this.errGlobalDupTxt;
    }

    @computed get filteredNarratives(): Narrative[] {
        const filtered = this.narratives.filter((n) => n.key.toLowerCase().includes(this.searchText.toLowerCase()) ||
            n.replacement.toLowerCase().includes(this.searchText.toLowerCase()));
        
        // Local Narratives must be always on top
        const localNarratives = (filtered.filter(n => !n.global))
            .sort((alpha, beta) => sortByAlphaNumeric(alpha.key, beta.key));
        const globalNarratives = (filtered.filter(n => n.global))
            .sort((alpha, beta) => sortByAlphaNumeric(alpha.key, beta.key));

        return [...localNarratives, ...globalNarratives];

    };

    @action resetInvalid() {
        this.invalid = { 'key': false, 'replacement': false };
    }

    @action validateNarrative(n: Narrative) {
        this.invalid.replacement = n.replacement.trim() === '' ? 'Required field' : 
                                   n.replacement.trim().length > this.maxNarrativeLength ? 
                                   `Narrative cannot exceed ${this.maxNarrativeLength} characters` : false;
        this.invalid.key = 
            n.key === '' ? 'Required field' :
                n.key.length < 2 ? 'Narrative Code cannot be less than 2 characters' :
            (n.key.indexOf(' ') > -1 ? 'No spaces allowed' :
            (this.isKeyDuplicate(n.key) ? 'Duplicate code' : false))
        return !this.invalid.replacement && !this.invalid.key;
    }
};
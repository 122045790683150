import * as React from 'react';
import { Checkbox, Collapse, IconButton, Tooltip, TextField } from '@material-ui/core';
import { Cancel, Check, CheckCircle, Delete, KeyboardArrowDown, KeyboardArrowUp, Save, GroupWorkOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import TimeEntryForm from 'components/TimeEntryForm/TimeEntryForm';
import TimeEntry from 'api/immutables/ImmutableTimeEntry';
import * as Styled from './styled';
import TEContextMenu from 'components/TEContextMenu/TEContextMenu';
import { MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import { ValidationState } from 'api/immutables/validators';
import * as ReactDOM from 'react-dom';
import scrollIntoView from 'scroll-into-view-if-needed';
import { Features, TimeEntryType, TimeKeeperAssignment } from '../../api/types/types';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { FlexDiv } from 'common/flex';
import { TKConsumer } from 'common/TKProvider';

interface TimeEntryPanelProps {
    timeEntry: TimeEntry;
    validationState?: ValidationState;
    durValidationState?: boolean;
    onChange: (t: TimeEntry, vstate?: ValidationState, durVstate?: boolean) => void;
    onEdit?: (t: TimeEntry) => void;
    onSave?: (t: TimeEntry) => void;
    onPost?: (t: TimeEntry) => void;
    onCancel?: (t: TimeEntry) => void;
    onDelete?: (t: TimeEntry) => void;
    onCopy?: (t: TimeEntry) => void;
    onSplit?: (t: TimeEntry) => void;
    onMerge?: (t: TimeEntry) => void;
    isMergeable?: boolean;
    onTransfer?: (t: TimeEntry) => void;
    isTransferable?: boolean;
    selected?: boolean;
    onSelect?: (selected: boolean) => void;
    expanded?: boolean;
    onExpand?: (expanded: boolean) => void;
    posted?: boolean; /** if true, time entry is posted and cannot be edited */
    error?: boolean; /** any error messages */
    disabled?: boolean;
    noCheckBox?: boolean;
    buildCodeSets?: (id: number) => void;
    timeCastEnabledInDayView?: boolean;
    // roles: Role[];
}

const styles = {
    tooltip : {
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical' as 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '400px',
        maxHeight: '46px'
    }
}

export default class TimeEntryPanel extends React.Component<
    TimeEntryPanelProps
> {
    constructor(props: TimeEntryPanelProps) {
        super(props);
    }
    
    expand = async () => {
        if (!this.props.expanded) {
            const entry = this.props.timeEntry;
            if (this.props.buildCodeSets) {
                await this.props.buildCodeSets(entry.id!);
            }
            setTimeout(() => {
                let elem: HTMLElement = ReactDOM.findDOMNode(this) as HTMLElement;
                scrollIntoView(elem, { behavior: 'smooth', scrollMode: 'if-needed' })
            }, 0);
        }
        this.props.onExpand!(!this.props.expanded);
    }
    
    select = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        this.props.onSelect!(checked);
    }

    changeReference = (event: React.ChangeEvent<HTMLInputElement>) => {
        let entry = this.props.timeEntry.setReference(event.target.value);
        this.props.onChange(entry);
    }

    render() {
        let {
            timeEntry,
            selected,
            onSelect,
            expanded,
            onExpand,
            onChange,
            onPost,
            onEdit,
            onCopy,
            onDelete,
            onSave,
            onCancel,
            onSplit,
            onMerge,
            isMergeable,
            onTransfer,
            isTransferable,
            validationState,
            disabled,
            noCheckBox,
            posted,
            error,
            timeCastEnabledInDayView
        } = this.props;
        let providerData = {
            entry: timeEntry,
            selected: selected,
        };
        const { dirty } = timeEntry;
        const clientText = timeEntry.clientId ? `${timeEntry.clientNumber} - ${timeEntry.clientName}` : '';
        const matterText = timeEntry.matterId ? `${timeEntry.matterNumber} - ${timeEntry.matterName}` : '';
        const CustomTooltip = withStyles(styles)(Tooltip);
        
        return (
            <FeaturesConsumer>
                { (features: Features) =>
            <TKConsumer>
                { (tk: TimeKeeperAssignment) =>
            <>
            <Styled.Container 
                elevation={1}
                square={true}
                expanded={expanded || false}
                posted={timeEntry.isPosted()}
            >
                <MenuProvider id={`menu_${timeEntry.id}`} data={providerData} >
                <Styled.Header 
                    onClick={onExpand && this.expand}
                >
                    {timeEntry.isPosted() ? 
                        <Styled.CheckIcon /> :
                        <Checkbox
                            checked={selected}
                            onClick={(e) => e.stopPropagation()}
                            onChange={this.select}
                            disableRipple={true}
                            style={{padding: 0}}
                            disabled={noCheckBox || !tk.writable}
                        />
                    }
                    <Styled.TEContent gridAutoFlow="row">
                        <Styled.TEDetails
                            gridAutoFlow="column"
                            gridTemplateColoums={timeCastEnabledInDayView ? '1.5fr 1.5fr .5fr .4fr auto' : '1fr 1fr 1.5fr .27fr .18fr auto auto'}
                        >
                            <Tooltip title={clientText}>
                                    <Styled.HeaderItem>{clientText}</Styled.HeaderItem>
                            </Tooltip>
                            <Tooltip title={matterText}>
                                <Styled.HeaderItem>{matterText}</Styled.HeaderItem>
                            </Tooltip>
                            { !timeCastEnabledInDayView &&
                                <CustomTooltip title={timeEntry.narrative} >
                                    <Styled.HeaderItem>{timeEntry.narrative}</Styled.HeaderItem>
                                </CustomTooltip>
                            }
                            <Styled.DurationHeader
                                billable={timeEntry.isBillable()}
                                matter={timeEntry.matterId}
                            >
                                {(timeEntry.duration / 3600).toFixed(2)}
                            </Styled.DurationHeader>
                            { (timeEntry.timeEntryType === TimeEntryType.COLLABORATE && timeEntry.collaborateTks! ) ?
                                <CustomTooltip title={timeEntry.collaborateTks} >
                                    <GroupWorkOutlined/>
                                </CustomTooltip> : <div/>
                            }
                            {expanded ? <div title="Collapse"><KeyboardArrowUp/></div>
                                : <div title="Expand"><KeyboardArrowDown/></div>}
                            <Styled.DirtyIndicator>{dirty ? '*' : ''}</Styled.DirtyIndicator>
                        </Styled.TEDetails>
                        {
                            timeCastEnabledInDayView &&
                            <Styled.StyledNarrative>
                            <CustomTooltip title={timeEntry.narrative} >
                                <Styled.HeaderItem>{timeEntry.narrative}</Styled.HeaderItem>
                            </CustomTooltip>
                            </Styled.StyledNarrative>
                        }
                    </Styled.TEContent>
                </Styled.Header>
                </MenuProvider>
    
                   { expanded && <Styled.Form>
                        <TimeEntryForm
                            timeEntry={timeEntry}
                            validationState={validationState}
                            durValidationState={this.props.durValidationState}
                            onChange={onChange}
                            actionCodesRequired={features.EpochConfigActionCodesRequired}
                            minNarrativeLength={features.EpochConfigNarrativesMinimumChars}
                            maxNarrativeLength={features.EpochConfigNarrativesMaximumChars}
                            disabled={disabled}
                        />
                        <div style={{ display: 'flex'}}>
                            {features.EpochConfigReferenceRequired && timeEntry && !timeEntry.matterId &&
                                        <TextField
                                            label="Reference"
                                            error={validationState && validationState.isReferenceEmpty}
                                            helperText={validationState && validationState.isReferenceEmpty ? 'Invalid Reference' : ''}
                                            value={timeEntry && timeEntry.reference ? timeEntry.reference : ''}
                                            onChange={this.changeReference}
                                        />
                                        }
                            <FlexDiv flex={1} />
                            {(timeEntry.sapStatus === 'UNSUBMITTED') && <Styled.Actions>
                                {onSave &&
                                <Tooltip title="Save">
                                    <IconButton
                                        disabled={!dirty || !tk.writable}
                                        onClick={() => onSave!(timeEntry)}
                                    >
                                            <Save />
                                    </IconButton>
                                </Tooltip>}
                                {onPost &&
                                    <Tooltip title="Post">
                                        <IconButton disabled={!tk.writable} onClick={() => onPost!(timeEntry)}>
                                            <Check />
                                        </IconButton>
                                    </Tooltip>}
                                {onCancel &&
                                <Tooltip title="Cancel">
                                <IconButton
                                    disabled={!dirty || !tk.writable}
                                    onClick={() => onCancel!(timeEntry)}
                                >
                                        <Cancel />
                                </IconButton>
                                </Tooltip>}
                                {onDelete &&
                                <Tooltip title="Delete">
                                <IconButton disabled={!tk.writable} onClick={() => onDelete!(timeEntry)}>
                                    <Delete />
                                </IconButton>
                                </Tooltip>}
                            </Styled.Actions>}
                       </div>
                    </Styled.Form>}
                <TEContextMenu
                    id={`menu_${timeEntry.id}`}
                    editHandler={onEdit && !timeEntry.isPosted() && tk.writable ? () => onEdit!(timeEntry) : undefined}
                    postHandler={onPost && !timeEntry.isPosted() && tk.writable ? () => onPost!(timeEntry) : undefined}
                    deleteHandler={onDelete && !timeEntry.isPosted() && tk.writable ? () => onDelete!(timeEntry) : undefined}
                    copyHandler={onCopy && tk.writable ? () => onCopy!(timeEntry) : undefined}
                    splitHandler={onSplit && timeEntry.isSplitable() && tk.writable ? () => onSplit!(timeEntry) : undefined}
                    viewHandler={onEdit && timeEntry.isPosted() || !tk.writable ? () => onEdit!(timeEntry) : undefined}
                    mergeHandler={onMerge && isMergeable && !timeEntry.isPosted() && tk.writable ? () => 
                        onMerge!(timeEntry) : undefined}
                    transferHandler={onTransfer && isTransferable && !timeEntry.isPosted() && tk.writable ? () =>
                            onTransfer!(timeEntry) : undefined}
                />
            </Styled.Container>
            </>
            }
            </TKConsumer>
            }
        </FeaturesConsumer>
        );
    }
}
import { observable, action } from 'mobx';
import { DialogStore, onOpen, onSave } from '@fulcrumgt/mobx-store-utils';
import { TimeKeeperAssignment } from 'api/types/types';

export default class TimekeeperDialogStore extends DialogStore {
    @observable selectedTimekeeperId: number;
    @observable entries: TimeKeeperAssignment[] = [];
    @observable selectedEntry: number;
    
    @action setTimeKeeper(id: number) {
        this.selectedTimekeeperId = id;
    }
    
    @onOpen()
    @action setEntries(entries: TimeKeeperAssignment[]) {
        this.entries = entries;
    }
    
    selectEntry(entry: number) {
        this.selectedEntry = entry;
    }
    
    @onSave() 
    saveEntry(tkId: number) {
        return this.selectedEntry;
    }
}
import { action, computed, observable } from 'mobx';
import { onOpen } from '@fulcrumgt/mobx-store-utils';
import { Client, Matter, Code, ActionCode, TimeEntryType } from '../api/types/types';
import TimeEntry, { SapStatus } from '../api/immutables/ImmutableTimeEntry';
import { DateTime } from 'luxon';
import TimeEntryDialogStore from 'store/timeentry.dialog.store';
import { ValidateSave, ValidatePost } from 'api/immutables/validators';
import ImmutableTimeEntry from 'api/immutables/ImmutableTimeEntry';
import { debounce } from 'typescript-debounce-decorator';
import { RootStore } from 'store/root.store';

export default class TransferEntryDialogStore extends TimeEntryDialogStore {
    @observable entry: TimeEntry;
    @observable transferNarrativeFlag: boolean = false;
    @observable transferdNarrative: string;
    @observable entries: TimeEntry[] = [];
    @observable saving: boolean = false;
    @observable rootStore: RootStore;

    constructor(root: RootStore) {
        super(root);
        this.wrappedPostTransferredEntries = this.wrappedPostTransferredEntries.bind(this);
        this.wrappedSaveTransferredEntries = this.wrappedSaveTransferredEntries.bind(this);
    }

    @computed get matters(): Matter[] {
        let matterList: Matter[] = [];
        this.entries.forEach((te) => {
            if (te.matter) {
                matterList.push(te.matter);
            }
        });
        return matterList;
    }

    getTotalDurationExclusive = async (workDate: string, id: number) => {
        let totalDuration = await this.rootStore.api.TimeEntry.getTotalForDateExclusive(workDate,
            this.entries.map(e => e.id!)
        );
        return totalDuration;
    }

    openTransfer = async (entries: TimeEntry[]) => {
        this.rootStore.setColloaboratees([]);
        this.entries = entries;
        this.entry = new TimeEntry();
        this.transferNarrativeFlag = false;
        this.selectedTemplate = undefined;
        this.validationState = undefined;
        this.durationValidationState = false;

        // Check if same client
        let isSameClient: boolean = entries.every((c: TimeEntry, ind, cArray) =>
            c.clientId === cArray[0].clientId),
            mClient: Client | null = isSameClient ? entries[0].client : null;

        // Check if all selected entries has same matter, then set matter obj with that matter.
        let isSameMatter: boolean = entries.every((mat: TimeEntry, index, array) =>
            mat.matterId === array[0].matterId),
            mMatter: Matter | null = isSameMatter ? entries[0].matter : null;

        // getting banned words on opening the dialog when matter ids are same, coz bannned words array will be empty.
        if (mMatter) {
            mMatter = await this.rootStore.api.Matter.get(mMatter.id);
        }

        let isSamePhase: boolean = entries.every((phs: TimeEntry, index, array) =>
            phs.phaseId === array[0].phaseId),
            mPhase: Code | null = isSamePhase ? entries[0].phase : null;

        let isSameTask: boolean = entries.every((tsk: TimeEntry, index, array) =>
            tsk.taskCodeId === array[0].taskCodeId),
            mTask: Code | null = isSameTask ? entries[0].task : null;

        let isSameActivity: boolean = entries.every((act: TimeEntry, index, array) =>
            act.actCodeId === array[0].actCodeId),
            mActivity: Code | null = isSameActivity ? entries[0].activity : null;

        let isSameActionCode: boolean = entries.every((accd: TimeEntry, index, array) =>
            accd.actionCodeId === array[0].actionCodeId),
            mActionCode: ActionCode | null = isSameActionCode ? entries[0].actionCodeObj : null;

        let isSameFFTask: boolean = entries.every((accd: TimeEntry, index, array) =>
            accd.ffTaskCodeId === array[0].ffTaskCodeId),
            mFFTask: Code | null = isSameFFTask ? entries[0].ffTask : null;

        let isSameFFActivity: boolean = entries.every((accd: TimeEntry, index, array) =>
            accd.ffActCodeId === array[0].ffActCodeId),
            mFFActivity: Code | null = isSameFFActivity ? entries[0].ffActivity : null;

        let isReference: boolean = entries.every((ref: TimeEntry, index, array) =>
            ref.reference === array[0].reference),
            mReference: string | null = isReference ? entries[0].reference : null;
        // If entries are from same work date, else today's date.
        let isSameWorkDate: boolean = entries.every((e: TimeEntry, i, arr) => {
            return e.workDateTime === arr[0].workDateTime
        }),
            workDate: string = isSameWorkDate ? entries[0].workDateTime : DateTime.local().startOf('day').toISO();
        this.setWorkDate(DateTime.fromISO(workDate));

        // Set office of the TimeKeeper
        let actTk = this.rootStore.appStore.getActiveTimeKeeperForDate(DateTime.fromISO(workDate))

        this.entry = this.entry
            .setClient(mClient)
            .setMatter(mMatter)
            .setNarrative('')
            .setOffice(actTk ? actTk.office : undefined)
            .setOfficeName(actTk ? actTk.officeName : undefined)
            .setPhase(mPhase)
            .setTask(mTask)
            .setAct(mActivity)
            .setActionCode(mActionCode)
            .setFFTask(mFFTask)
            .setFFAct(mFFActivity)
            .setReference(mReference)
        if (mMatter) {
            const codeSetFlags = await this.rootStore.api.Code.determineCodeSetFields(
                mMatter.id, workDate
            );
            this.entry.isActCode = codeSetFlags.isActCode;
            this.entry.isPhaseCode = codeSetFlags.isPhaseCode;
            this.entry.isFfTaskCode = codeSetFlags.isFfTaskCode;
        }
        this.entry.sapStatus = SapStatus.UNSUBMITTED;
        this.entry.timeKeeperId = this.rootStore.api.Session.currentTimeKeeper!;
        this.entry.timeEntryType = TimeEntryType.TRANSFER;
        this.entry.collaborateTks = '';
        return await this.open();
    }

    @computed get changedEntries() {
        return this.entries.map((entry) => {
            return entry.setClient(this.entry.client)
                .setMatter(this.entry.matter)
                .setPhase(this.entry.phase)
                .setTask(this.entry.task)
                .setAct(this.entry.activity)
                .setActionCode(this.entry.actionCodeObj)
                .setFFTask(this.entry.ffTask)
                .setFFAct(this.entry.ffActivity)
        })
    }

    @debounce(500, {leading: false})
    @action
    async wrappedPostTransferredEntries () {
        if (this.saving) {
            return;
        }
        this.saving = true;
        try {
            await this.postTransferredEntries();
        } finally {
        this.saving = false;
        }
    }
    @debounce(500, {leading: false})
    @action
    async wrappedSaveTransferredEntries() {
        if (this.saving) {
            return;
        }
        this.saving = true;
        try {
            await this.saveTransferredEntries();
        } finally {
        this.saving = false;
        }
    }

    @action saveTransferredEntries = async () => {
        let vstate = ValidateSave(
            this.entry,
            await this.getTotalDurationExclusive(this.entry.workDateTime, this.entry.id!),
            this.rootStore.appStore.features,
            this.rootStore.appStore.getActiveTimeKeeperForDate(DateTime.fromISO(this.entry.workDateTime)));
        vstate.missing.matter = !this.entry.matter
        if (!vstate.valid) {
            this.validationState = vstate;
            return;
        }
        let excludeIds: number[] = this.changedEntries ? this.changedEntries.map(entry => entry.id!) : [];
        let totalForThatDay = await this.rootStore.api.TimeEntry.getTotalForDateExclusive(
            DateTime.fromISO(this.entry.workDateTime).startOf('day').toISO(),
            excludeIds
        );
        let movableTotal = 0;
        this.changedEntries.forEach( entry => movableTotal = movableTotal + entry.duration);
        this.rootStore.timeEntryStore.saveEntries(this.changedEntries);
        this.resolveAndClose(this.changedEntries);
    }

    @action postTransferredEntries = async () => {
        let postedEntries: ImmutableTimeEntry[] = [];
        let notPosted: number = 0;
        await Promise.all(this.changedEntries.map(async (entry) => {
            const activeTimeKeeper = this.rootStore.appStore.getActiveTimeKeeperForDate(DateTime.fromISO(entry.workDateTime));
            entry = entry.setOffice(activeTimeKeeper ? activeTimeKeeper.office : undefined)
                .setOfficeName(activeTimeKeeper ? activeTimeKeeper.officeName : undefined)
            if (entry.matterId) {
                let invalidWords = await Promise.all([
                    this.rootStore.api.Matter.getBannedWords(entry.matterId),
                    this.rootStore.api.Matter.getBlockBillingWords(entry.matterId)
                ]);
                entry.bannedWords = invalidWords[0];
                entry.blockBillingWords = invalidWords[1];
            }
            let vstate = ValidatePost(
                entry,
                0,
                ['STOP', 'HOLD', 'DCLN', 'HCLS', 'SCLS', 'BUDG'],
                ['02'],
                this.rootStore.appStore.features,
                activeTimeKeeper
            );
            if (vstate.valid) {
                entry = entry.setPosted();
                postedEntries.push(entry);
            } else {
                this.validationState = vstate;
                notPosted = notPosted + 1;
            }
        }));
        if (postedEntries.length > 0) {
            this.entries = postedEntries;
            await this.saveTransferredEntries();
        }
        if (notPosted > 0) {
            let type = notPosted === 1 ? 'entry' : 'entries'
            this.rootStore.snackbarStore.triggerSnackbar(notPosted + ' time ' + type + ' failed to post');
        }
    }
}
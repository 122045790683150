import BaseStore from 'store/base.store';
import { action, computed, observable } from 'mobx';
import ImmutableTimeEntry from '../api/immutables/ImmutableTimeEntry';
import ImmutableTimer from '../api/immutables/ImmutableTimer';
import ImmutableTemplate from '../api/immutables/ImmutableTemplate';
import { RootStore } from 'store/root.store';
import { Matter, TimerChunk } from '../api/types/types';

export default class PendingItemStore extends BaseStore {
    @observable pendingTimeEntries: ImmutableTimeEntry[] = [];
    @observable pendingTimers: ImmutableTimer[] = [];
    @observable pendingTemplates: ImmutableTemplate[] = [];
    @observable pendingTimerChunks: Map<number, TimerChunk[]> = new Map<number, TimerChunk[]>();
    
    constructor(root: RootStore) {
        super(root);
    }
    
    @action.bound setPendingTimeEntries(entries: ImmutableTimeEntry[]) {
        this.pendingTimeEntries = entries;
    }
    
    @action.bound setPendingTimers(timers: ImmutableTimer[]) {
        this.pendingTimers = timers;
    }

    @action.bound setPendingTemplates(templates: ImmutableTemplate[]) {
        this.pendingTemplates = templates;
    }
    
    @action.bound setPendingTimerChunks(timerChunks: Map<number, TimerChunk[]>) {
        this.pendingTimerChunks = timerChunks;
    }
    
    @action.bound getTimerChunks(id: number | undefined): TimerChunk[] | undefined {
        if (id) {
            return this.pendingTimerChunks.get(id);
        } else {
            return undefined;
        }
    }
}
import CustomDictionaryAPI from 'api/interfaces/CustomDictionaryAPI';
import BaseWebImplementation from './Base.impl';
import { CustomDictionary } from '../../types/types';

export default class CustomDictionaryImpl extends BaseWebImplementation implements CustomDictionaryAPI {

    getAllUserDictionaries = async () => {
        let { data } = await this.http.get(`userDictionary`);
        return data.map((d: object) => Object.assign(new CustomDictionary(), d));
    }
    
    async getUserDictionary (id: number) {
        let { data } = await this.http.get(`userDictionary/${id}`);
        return Object.assign(new CustomDictionary(), data);
    }

    async saveUserDictionary (dict: CustomDictionary[]) {
        let entries = dict.map((t) => {
            return { 
                id: t.id, 
                userId: t.userId,
                dictionary: t.dictionary,
                deleted: t.deleted
            }
        });
        const { data } = await this.http.put(`userDictionary`, entries);
        return data;
    }
}
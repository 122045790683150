import * as React from 'react';
import {
    Dialog,
    DialogContent,
    TextField,
    DialogTitle,
    Button,
    DialogActions,
    FormControlLabel, Checkbox, FormHelperText, Typography
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import TimeEntryDialogStore from 'store/timeentry.dialog.store';
import TimeEntryForm from 'components/TimeEntryForm/TimeEntryForm';
import { FlexDiv } from 'common/flex';
import { InlineDatePicker } from 'material-ui-pickers';
import { KeyboardArrowLeft, KeyboardArrowRight, GroupWorkOutlined } from '@material-ui/icons';
import AutoCompleteField from 'components/AutoCompleteField/AutoCompleteField';
import { ApiConsumer } from 'common/ApiProvider';
import RootAPI from '../../api/interfaces/RootAPI';
import Template from 'api/immutables/ImmutableTemplate';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { Features, TimeEntryType, TimeKeeperAssignment } from '../../api/types/types';

import { DateTime } from 'luxon';
import { isoDate, getDateFormat } from '../../util/date';
import * as Styled from 'components/NavBar/styled.desktop';
import TimeKeepersList from 'components/TimeKeepersList/TimeKeepersList';
import { LinearProgressBar } from 'components/LoadingSpinner/LinearProgressBar';
import { TKConsumer } from 'common/TKProvider';

interface Props {
    dialogStore?: TimeEntryDialogStore;
}
interface State {
    setFieldLoader: boolean;
}
@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        dialogStore: rootStore.timeEntryDialogStore,
    };
})
@observer
export default class TimeEntryDialog extends React.Component<Props, State> {
    state = {
        setFieldLoader: false
    }
    changeReference = (event: React.ChangeEvent<HTMLInputElement>) => {
        let entry = this.props.dialogStore!.entry.setReference(event.target.value);
        this.props.dialogStore!.changeEntry(entry);
    }
    
    setTemplateName = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.dialogStore!.setTemplateName(event.target.value);
    }
    
    cancel = () => {
        this.props.dialogStore!.clear();
        this.props.dialogStore!.cancel();
        this.props.dialogStore!.createAnotherFlag = false
    }
    setWorkDate = (date: Date) => {
        const workDate: DateTime = DateTime.local(
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate(),
            0,
            0,
            0
        );
        this.props.dialogStore!.setWorkDate(workDate)
    }
    setTemplate = async (template: Template | undefined) => {
        this.setState({
            setFieldLoader: true
        });
        await this.props.dialogStore!.setTemplate(template);
        this.setState({
            setFieldLoader: false
        })
    }
    render() {
        const { 
            isOpen, 
            entry, 
            templateName,
            createAnotherFlag,
            selectedTemplate,
            changeEntry, 
            saving,
            wrappedPost,
            wrappedSave,
            durationValidationState,
            toggleCreateAnotherFlag,
            validationState,
            templateValidationState,
            disableCreateAnother
        } = this.props.dialogStore!;
        
        let workDateErrText: string = '';
        if (validationState && validationState.invalidWorkDate) {
            workDateErrText = 'Invalid Work Date'
        }
        const fetchTemplates = this.props.dialogStore!.rootStore.templateStore.fetchTemplatesInAutoComplate;
        const collaboratees = this.props.dialogStore!.rootStore.collaboratees.length;
        const { getAllTimeKeepers, filteredAllTimekeepersList, online,
            setTkSearchText } = this.props.dialogStore!.rootStore.appStore;
        const { setFieldLoader } = this.state
        return (
            <FeaturesConsumer>
                {(features: Features) =>
            <TKConsumer>
                { (tk: TimeKeeperAssignment) =>
                    <>
                        <Dialog
                            PaperProps={{ style: { overflow: 'visible', maxWidth: '1024px' } }}
                            disableBackdropClick={true}
                            disableEscapeKeyDown={true}
                            open={isOpen}
                            onClose={this.cancel}
                            fullWidth={true}
                            maxWidth={false}
                            disableRestoreFocus={true}
                        >
                            <DialogTitle
                                id="timeentry-dialog-title"
                                style={{ paddingBottom: 0 }}
                            >
                                Time Entry {(entry && entry.timeEntryType === TimeEntryType.COLLABORATE &&
                                    entry.collaborateTks!) &&
                                    <>
                                        <span
                                            style={{ float: 'right', fontSize: '15px' }}
                                        >
                                            {entry.collaborateTks}
                                        </span>
                                        <span style={{ float: 'right' }}><GroupWorkOutlined /></span>
                                    </>
                                    }
                            </DialogTitle>
                            <DialogContent style={{ overflow: 'visible', paddingBottom: 0 }}>
                                <FlexDiv direction="row" style={{ alignItems: 'flex-end' }}>
                                    <InlineDatePicker
                                        format={entry ? DateTime.fromISO(entry.workDateTime).toFormat(getDateFormat())
                                            : 'MM/dd/yyyy'}
                                        onlyCalendar={true}
                                        value={entry ? isoDate(DateTime.fromISO(entry.workDateTime)) : ''
                                        }
                                        disabled={entry && entry.isPosted() || !tk.writable}
                                        onChange={this.setWorkDate}
                                        leftArrowIcon={<KeyboardArrowLeft/>}
                                        rightArrowIcon={<KeyboardArrowRight/>}
                                    />
                                    <FlexDiv flex={1}/>
                                    {entry && !entry.isPosted() && <ApiConsumer>
                                        {(api: RootAPI) =>
                                            <div>
                                                <AutoCompleteField
                                                    label="Template"
                                                    fetch={fetchTemplates}
                                                    currentItem={selectedTemplate ? selectedTemplate : null}
                                                    clearable={true}
                                                    disabled={false || !tk.writable}
                                                    onClear={() => this.setTemplate(undefined)}
                                                    getItemText={(m: Template) => `${m.name}`}
                                                    onSelect={this.setTemplate}
                                                    width={200}
                                                />
                                                {setFieldLoader && 
                                                <LinearProgressBar color={'primary'} progressBar={50}/>
                                                }
                                            </div>}

                                    </ApiConsumer>}
                                </FlexDiv>
                                {workDateErrText &&
                                <FormHelperText error={true}>
                                    {workDateErrText}
                                </FormHelperText>
                                }
                                <TimeEntryForm
                                    validationState={validationState}
                                    durValidationState={durationValidationState}
                                    minHeight={144}
                                    timeEntry={entry}
                                    onChange={changeEntry}
                                    actionCodesRequired={features.EpochConfigActionCodesRequired}
                                    minNarrativeLength={features.EpochConfigNarrativesMinimumChars}
                                    maxNarrativeLength={features.EpochConfigNarrativesMaximumChars}
                                />
                            </DialogContent>
                            <DialogActions>
                                {entry && !entry.isPosted() && <div style={{ paddingLeft: 12 }}>
                                    { entry && !entry.matterId && <TextField
                                        label="Reference"
                                        error={validationState && validationState.isReferenceEmpty}
                                        helperText={validationState && validationState.isReferenceEmpty ? 'Invalid Reference' : ''}
                                        value={entry && entry.reference ? entry.reference : ''}
                                        onChange={this.changeReference}
                                        disabled={!tk.writable}
                                    />}
                                    {entry && entry.matterId && <TextField
                                        label="Save As Template"
                                        inputProps={{ maxlength: 100 }}
                                        value={templateName}
                                        error={templateValidationState && !templateValidationState.valid}
                                        helperText={
                                            (templateValidationState && !templateValidationState.valid) 
                                                ? 'Duplicate Template' : ''}
                                        onChange={this.setTemplateName}
                                        disabled={!tk.writable}
                                    />}
                                </div>}

                                <FlexDiv flex={1}/>
                                {entry && !entry.isPosted() && <>
                                    {!entry.id &&
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={createAnotherFlag}
                                                onChange={toggleCreateAnotherFlag}
                                                disabled={disableCreateAnother || !tk.writable}
                                            />
                                        }
                                        label="Create Another"
                                    />
                                    }
                                    {online &&
                                        (entry && entry.timeEntryType !== TimeEntryType.COLLABORATE &&
                                            !entry.collaborateTks && tk.writable) &&
                                    <TimeKeepersList
                                        render={(tkMenuAnchor, openTkList) =>
                                            <Styled.CollaborateBtn innerRef={tkMenuAnchor}>
                                                <Button
                                                    onClick={(evt) => {
                                                        setTkSearchText('')
                                                        getAllTimeKeepers(entry.workDateTime)
                                                            .then(() => openTkList(evt))
                                                    }
                                                    }
                                                    aria-label="Collaborate"
                                                >
                                                    Collaborate ({collaboratees})
                                                </Button>
                                            </Styled.CollaborateBtn>}
                                        collaborate={true}
                                        menuWidth={300}
                                        timeKeepers={filteredAllTimekeepersList}
                                        workDate={entry.workDateTime}
                                    />}
                                    <Button disabled={saving || !tk.writable} onClick={wrappedSave}>Save</Button>
                                    <Button disabled={saving || !tk.writable} onClick={wrappedPost}>
                                        Post
                                    </Button>
                                </>}
                                <Button onClick={this.cancel}>Cancel</Button>
                            </DialogActions>

                        </Dialog>
                    </>
                }
            </TKConsumer>}
            </FeaturesConsumer>
        );
    }
}
import { observable, action } from 'mobx';
import { DialogStore, onOpen, onSave } from '@fulcrumgt/mobx-store-utils';
import { DialogRootStore } from 'store/dialog.root.store';
import { RootStore } from 'store/root.store';

export default class EULADialogStore extends DialogRootStore {
    @observable eulaAcceptance: boolean;
    @observable eulaText: string = '';

    constructor(rs: RootStore) {
        super(rs);
    }
    open = (): Promise<{}> => {
        return super.open();
    }
    @onOpen()
    @action async getEula() {
        this.eulaText = await this.rootStore.api.Session.getEulaText();
    }
    @action setEulaAcceptance = (eulaAcceptance: boolean) => {
        this.eulaAcceptance = eulaAcceptance;
    }

    @onSave()
    saveEulaAcceptance(eulaAcceptance: boolean) {
        return this.eulaAcceptance;
    }
}
import { DialogStore } from '@fulcrumgt/mobx-store-utils';
import { observable } from 'mobx';
import { RootStore } from 'store/root.store';

export class DialogRootStore extends DialogStore {
    @observable rootStore: RootStore

    constructor(rootStore: RootStore) {
        super();
        this.rootStore = rootStore
    }
}
import BaseElectronImplementation from './Base.impl';
import SessionAPI from 'api/interfaces/SessionAPI';
import {
    User,
    OfflineItems,
    TimerChunk,
    TimeKeeperAssignment,
    Features,
    LoginMode,
    TkGoals,
    Matter
} from 'api/types/types';
import { AxiosInstance } from 'axios';
import { SyncResponse } from './SyncResponse';
import WebSessionImpl from '../web/Session.impl';
import ElectronRootImpl from './Root.impl';
import ImmutableTimeEntry from 'api/immutables/ImmutableTimeEntry';
import ImmutableTemplate from 'api/immutables/ImmutableTemplate';
import Mutex from 'api/util';
import { DateTime } from 'luxon';
import ImmutableTimer from '../../immutables/ImmutableTimer';
import { MatterWordsMapping } from './Dexie';
import { FileSaver } from '../../../util/ExportToExcel';
let IDBExportImport = require('indexeddb-export-import');
let JSZip = require('jszip');
let zip = new JSZip();
import XLSX from 'xlsx';
import logger from '../../../logging/logging';

export default class SessionImpl extends BaseElectronImplementation implements SessionAPI {
    writeMutex = new Mutex();
    onlineHandler?: (online: boolean) => void;
    pendingItemHandlers: (((entries: OfflineItems | undefined) => void) | null )[] = [];
    updatedTKHandlers: (((timeKeepers: TimeKeeperAssignment[]) => void) | null )[] = [];

    constructor(root: ElectronRootImpl) {
        super(root);
        this.webSession.onlineStatusChange = this.statusChange;
        this.webSession.syncHandler = this.onSync;
        const { ipcRenderer } = require('electron');
        ipcRenderer.on('sync-on-reopen', this.sync);
    }

    syncStatusProgressListener: (message: string, progress?: number) => void = () => {
        /* this function gets set by `setProgressListener` */
    }

    setProgressListener = (listener: (message: string, progress?: number) => void) => {
        this.syncStatusProgressListener = listener
        this.webSession.syncStatusProgressListener = listener
    }

    setOnlineStatusChangeHandler = (handler: (online: boolean) => void ) => {
        this.onlineHandler = handler;
    }
    onSync = (sync: SyncResponse) => {
        this.saveSyncResponse(sync);
        // todo
    }
    get currentTimeKeeper(): number | undefined {
        return this.webSession.currentTimeKeeper;
    }
    get axios(): AxiosInstance {
        return this.root.webImpl.Session.axios;
    }
    get webSession(): WebSessionImpl {
        return this.root.webImpl.Session;
    }

    setServer = async (url: string, wsUri: string) => {
        await this.root.webImpl.Session.setServer(url, wsUri);
    }

    silentSSOLogin = async (features: Features) => {
        // if SSO fall back feature is enabled, then login directly using kerberos
        if (features) {
            if (features.EpochConfigLoginMode === LoginMode.EAGER_SSO_FALLBACK) {
                try {
                    if (features.EpochConfigKerberosEnabled) {
                        await this.webSession.kerberosLogin();
                        this.setRetentionDates();
                        await this.getAll();
                    } else {
                        await this.ssoEntry();
                    }
                } catch (e) {
                    logger.error(e);
                    throw e;
                }
            }
        }
    }
    setRetentionDates() {
        const features = JSON.parse(localStorage.getItem('features') || '')
        if (features) {
            const { EpochConfigTimeCastSegmentsRetentionDays, EpochConfigTimeCastSegmentsFetchDays } = features
            const segmentsOnGetAll = DateTime
                .local()
                .minus({days: EpochConfigTimeCastSegmentsFetchDays})
                .startOf('day')
                .toISO();
            const retainUpto = DateTime
                .local()
                .minus({days: EpochConfigTimeCastSegmentsRetentionDays})
                .startOf('day')
                .toISO();
            localStorage.setItem('tcSegmentsFrom', segmentsOnGetAll);
            localStorage.setItem('tcSegmentsRetentionDays', retainUpto);
        }
        const from = DateTime.local().minus({ months: 2 }).startOf('month');
        localStorage.setItem('retentionFromDate', JSON.stringify(from));
    }
    get serverSet() {
        if (localStorage.getItem('serverUrl')) {
            return true;
        }
        return false;
    }
    // after this runs the api should be ready to handle calls.
    logIn = async (user: string, password: string) => {
        try {
            await this.root.webImpl.Session.logIn(user, password);
            this.setRetentionDates();
            await this.getAll();
            this.webSession.loginInProgress = false;
        } catch (e) {
            logger.info('Login failed: ', e)
            throw e;
        }
    }
    syncLastPushNotification = () => {
        this.webSession.syncLastPushNotification();
    }
    ssoLogin = async (token: string) => {
        try {
            await this.root.webImpl.Session.ssoLogin(token);
            this.setRetentionDates();
            await this.getAll()
        } catch (e) {
            logger.error('ssoLogin error: ', e)
            throw e;
        }
    }
    logOut = async () => {
        try {
            await this.webSession.logOut();
            localStorage.removeItem('retentionFromDate');
            localStorage.removeItem('tcDatesBfrRetentionDateArray')
            await this.root.deleteDatabase();
        } catch (e) {
            logger.info('Logout error info: ', e);
        } finally {
            return;
        }
    }
    initialize = async (): Promise<boolean | 'needs-soft-login'> => {
        this.syncStatusProgressListener('Checking tokens...')
        const token = localStorage.getItem('token');
        const refToken = localStorage.getItem('refreshToken');
        const user = localStorage.getItem('user');

        if (!token || !refToken) {
            if (!user) {
                this.syncStatusProgressListener('Clearing session...');
                try {
                    this.root.db.destroy();  
                } catch (e) {
                    logger.warn(e);
                }
                return false
            } else {
                this.syncStatusProgressListener('Session has expired.', 100)
                return 'needs-soft-login'
            }
        }
        let timeKeeperId = localStorage.getItem('timeKeeperId') || undefined;
        if (timeKeeperId) {
            this.syncStatusProgressListener('Setting TimeKeeper...')
            this.setTimeKeeper(Number(timeKeeperId));
        }
        let us = await this.check();
        this.syncStatusProgressListener('Checking authentication status...')
        this.webSession.authenticated = true;
        try {
            await this.write()
            // await this.sync()
        } catch (e) {
            logger.error('Error in write', e)
        } finally {
            // this.webSession.tryOpenWebsocket();
            return us;
        }
    } // if this returns true the api will be ready to recieve else, show login
    check = async () => {
        let token = localStorage.getItem('token');
        if (!this.webSession.online && token) {
            this.webSession.buildAxios();
            return true;
        }
        return this.webSession.check();
    }; // does a liveness check on the session
    getTimekeeperAssignments = async () => {
        return this.root.db.timeKeeperAssignments.toArray();
    };
    setTimeKeeper = async (timeKeeperId: number) => {
        this.setRetentionDates();
        return await this.webSession.setTimeKeeper(timeKeeperId);
        // todo
    }
    me = async () => {
        let localStorageVariable = localStorage;
        return {
            displayName: localStorageVariable.displayName,
            name: localStorageVariable.userName,
            id: localStorageVariable.userId,
            valid: localStorageVariable.valid
        } as User;
    };
    setReinitializeHandler = async (handler: (attemptSoftLogin?: boolean) => void) => {
        this.webSession.setReinitializeHandler(handler);
        // todo
    };
    statusChange = async (online: boolean) => {
        if (this.onlineHandler) {
            this.onlineHandler(online)
        }
        if (online) {
            this.write();
            this.root.webImpl.Session.periodicSync();
        }

    }
    saveSyncResponse = async (resp: SyncResponse, shouldCallTracked?: boolean) => {
        if (resp.lastSync) {
            localStorage.setItem('lastSync', resp.lastSync);
        }
        let invalidBannedWords: MatterWordsMapping[] = [];
        let invalidBlockBillingWords: MatterWordsMapping[] = [];
        let tkGoals: TkGoals[] = [];

        if (resp.matters) {
            this.syncStatusProgressListener('Loading BannedWords...', 5)
            invalidBannedWords = resp.matters.map((m) => {
                return {
                    matterId: m.id,
                    words: m.bannedWords
                }
            })
            this.syncStatusProgressListener('Loading InvalidBlockBillingWords...', 10)
            invalidBlockBillingWords = resp.matters.map((m) => {
                return {
                    matterId: m.id,
                    words: m.blockBillingWords
                }
            })
        }
        if (resp.tkGoals) {
            tkGoals = resp.tkGoals
        }
        try {
            await Promise.all([
                this.root.db.clients.bulkPut(resp.clients || [])
                    .then(() => {
                        this.syncStatusProgressListener('Loading Clients...')
                    }).catch(e => logger.info(e)),
                this.root.db.matters.bulkPut(resp.matters || [])
                    .then(() => {
                        this.syncStatusProgressListener('Loading Matters...')
                    }).catch(e => logger.info(e)),
                this.root.db.codeSetMappings.bulkPut(resp.codeSetMappings || [])
                    .then(() => {
                        this.syncStatusProgressListener('Loading CodeSetMappings...')
                    }).catch(e => logger.info(e)),
                this.root.db.timeKeeperAssignments.bulkPut(resp.timeKeepers || [])
                    .then(() => {
                        this.syncStatusProgressListener('Loading TimeKeeperAssignments...')
                    }).catch(e => logger.info(e)),
                this.root.db.codes.bulkPut(resp.codes || [])
                    .then(() => {
                        this.syncStatusProgressListener('Loading Codes...')
                    }).catch(e => logger.info(e)),
                this.root.db.matterTkMappings.bulkPut(resp.matterTkMappings || [])
                    .then(() => {
                        this.syncStatusProgressListener('Loading MatterTkMappings...')
                    }).catch(e => logger.info(e)),
                this.root.db.matterOfficeMappings.bulkPut(resp.matterOfficeMappings || [])
                    .then(() => {
                        this.syncStatusProgressListener('Loading MatterOfficeMappings...')
                    }).catch(e => logger.info(e)),
                this.root.db.tkHours.bulkPut(resp.hours || [])
                    .then(() => {
                        this.syncStatusProgressListener('Loading TkHours...')
                    }).catch(e => logger.info(e)),
                this.root.db.actionCodeMappings.bulkPut(resp.actionCodeMapping || [])
                    .then(() => {
                        this.syncStatusProgressListener('Loading ActionCodeMappings...')
                    }).catch(e => logger.info(e)),
                this.root.db.actionCodes.bulkPut(resp.actionCode || [])
                    .then(() => {
                        this.syncStatusProgressListener('Loading ActionCodes...')
                    }).catch(e => logger.info(e)),
                this.root.db.bannedWordsMapping.bulkPut(invalidBannedWords)
                    .then(() => {
                        this.syncStatusProgressListener('Loading BannedWordsMappings...')
                    }).catch(e => logger.info(e)),
                this.root.db.blockBillingWordsMapping.bulkPut(invalidBlockBillingWords)
                    .then(() => {
                        this.syncStatusProgressListener('Loading BlockBillingWordsMappings...')
                    }).catch(e => logger.info(e)),
                this.root.db.tkGoals.bulkPut(tkGoals)
                    .then(() => {
                        this.syncStatusProgressListener('Loading TimeKeeper goals...')
                    }).catch(e => logger.info(e)),
            ]);

        } catch (e) {
            logger.error('Error resolving bulkPut in Dexie', e)
        }
        
        if (resp.templates && resp.templates.length > 0) {
            await this.root.Template.recieve((resp.templates as unknown as ImmutableTemplate[]) || [])
                .then(() => {
                    this.syncStatusProgressListener('Receiving Templates...')
                }).catch((e) => {
                    logger.info('Error receiving Templates', e);
                });
        }
        if (resp.glossaries && resp.glossaries.length > 0) {
            await this.root.Narrative.recieve(resp.glossaries || [])
                .then(() => {
                    this.syncStatusProgressListener('Receiving Narratives...')
                }).catch((e) => {
                    logger.info('Error receiving Narratives', e);
                });
        }
        if (resp.timeEntries && resp.timeEntries.length > 0) {
            await this.root.TimeEntry.recieve(resp.timeEntries || [])
                .then(() => {
                    this.syncStatusProgressListener('Receiving TimeEntries...')
                }).catch((e) => {
                    logger.info('Error receiving TimeEntries', e);
                });
        }
        if (resp.userDictionaries && resp.userDictionaries.length > 0) {
            await this.root.CustomDictionary.recieve(resp.userDictionaries || [])
                .then(() => {
                    this.syncStatusProgressListener('Receiving CustomDictionaries...')
                }).catch((e) => {
                    logger.info('Error receiving CustomDictionaries', e);
                });
        }
        if (resp.timers && resp.timers.length > 0) {
            await this.root.Timer.recieve(resp.timers || [])
                .then(() => {
                    this.syncStatusProgressListener('Receiving Timers...')
                }).catch((e) => {
                    logger.info('Error receiving Timers', e);
                });
        }
        if (resp.matters || resp.matterTkMappings) {
            if (resp.matters.length > 0 || resp.matterTkMappings.length > 0) {
                this.syncStatusProgressListener('Receiving Matters...')
                if (shouldCallTracked) {
                    await this.root.Matter.recieve(resp.matters);
                }
            }
        }
        if (resp.timeCastSegments && resp.timeCastSegments.length > 0) {
            await this.root.TimeCast.receiveSegments(resp.timeCastSegments || [])
                .then(() => {
                    this.syncStatusProgressListener('Receiving TimeCastSegments')
                });
        }
        if (resp.timeCastPrograms && resp.timeCastPrograms.length > 0) {
            await this.root.TimeCast.receivePrograms(resp.timeCastPrograms || [])
                .then(() => {
                    this.syncStatusProgressListener('Receiving TimeCastPrograms')
                });
        }
        if (resp.settings && resp.settings.length > 0) {
            await this.root.Settings.receive(resp.settings || [])
                .then(() => {
                    this.syncStatusProgressListener('Receiving Settings')
                });
        }
        await this.updatedTKHandlers.filter(h => h !== null)
            .forEach(async h => h!(await this.getTimekeeperAssignments()))

        if (resp.timers || resp.timeEntries) {
            await this.pendingItemHandlers.filter(h => h !== null)
                .forEach(async h => h!(await this.getAllOfflineEntries()))
        }
        
        this.syncStatusProgressListener('Done.', 100)
    }
    getAll = async () => {
        let from = DateTime.local().minus({ months: 2 }).startOf('month');
        let to = DateTime.local().endOf('month');

        this.syncStatusProgressListener('Fetching data...')
        try {
            let {data} = await this.axios.get(`/getAll?fromDate=${from.toISO()}&toDate=${to.toISO()}`)
            return await this.saveSyncResponse(data, false);
        } catch (e) {
            logger.info(e);
            return;
        }
        
    }
    sync = async () => {
        try {
            this.syncStatusProgressListener('Fetching data...');
            if (this.axios) {
                let {data} = await this.axios.get(`/sync`)
                await this.saveSyncResponse(data, true);
            }
        } catch (e) {
            logger.warn('sync error', e);
        }
        
    }

    write = async () => {
        return this.writeMutex.execute(async () => {
            await Promise.all([
                this.root.TimeEntry.write()
                    .then(() => this.syncStatusProgressListener('Writing TimeEntries'))
                    .catch((e) => {
                        logger.info('Error writing TimeEntries', e);
                    }),
                this.root.Template.write()
                    .then(() => this.syncStatusProgressListener('Writing Templates'))
                    .catch((e) => {
                        logger.info('Error writing Templates', e);
                    }),
                this.root.Narrative.write()
                    .then(() => this.syncStatusProgressListener('Writing Narratives'))
                    .catch((e) => {
                        logger.info('Error writing Narratives', e);
                    }),
                this.root.CustomDictionary.write()
                    .then(() => this.syncStatusProgressListener('Writing CustomDictionaries'))
                    .catch((e) => {
                        logger.info('Error writing CustomDictionaries', e);
                    }),
            ]);
            await this.root.Timer.write()
                .then(() => this.syncStatusProgressListener('Writing Timers'))
                .catch((e) => {
                    logger.info('Error writing Timers', e);
                });
            await this.root.Timer.writeChunks()
                .then(() => this.syncStatusProgressListener('Writing TimerChunks'))
                .catch((e) => {
                    logger.info('Error writing TimerChunks', e);
                });
            await this.root.Settings.write()
                .then(() => this.syncStatusProgressListener('Writing Settings'))
                .catch((e) => {
                    logger.info('Error writing Settings', e);
                });
            await this.root.TimeCast.write()
                .then(() => this.syncStatusProgressListener('Writing TimeCast Data'))
                .catch((e) => {
                    logger.info('Error writing TimeCast Data', e);
                });

            this.pendingItemHandlers.filter(h => h !== null)
                .forEach(async h => h!(await this.getAllOfflineEntries()))
        });
    }
    getAllOfflineEntries = async (): Promise<OfflineItems | undefined> => {
        if (this.currentTimeKeeper) {
            const offlineTimeEntries = await this.root.db.timeEntries
                .where({timeKeeperId: this.currentTimeKeeper!})
                .filter((t) => t.serverDirty!)
                .toArray();
            const offlineTimers = (await this.root.db.timers
                    .where({timeKeeperId: this.currentTimeKeeper!})
                    .toArray())
                    .filter(a => a.serverDirty)
                    
            const offlineTemplates = await this.root.db.templates
                .where({timeKeeperId: this.currentTimeKeeper!})
                .filter((t) => t.serverDirty!)
                .toArray();

            const offlineTimerChunks: Map<number, TimerChunk[]> = new Map<number, TimerChunk[]>();
            for (let i = 0; i < offlineTimers.length; i++) {
                let timer = offlineTimers[i];
                if (timer.id) {
                    let timerId = timer.id;
                    let chunks = (await this.root.db.timerChunks
                        .where({timerId})
                        .toArray())
                        .filter((chk) => chk.serverDirty)
                    offlineTimerChunks.set(timer.id, chunks);
                }
            }

            let offlineEntries: OfflineItems = {
                TimeEntries: offlineTimeEntries.map((entry) => Object.assign(new ImmutableTimeEntry(), entry)),
                Timers: offlineTimers.map((timer) => Object.assign(new ImmutableTimer(), timer)),
                Templates: offlineTemplates.map((temp) => Object.assign(new ImmutableTemplate(), temp)),
                TimerChunks: offlineTimerChunks
            }

            return offlineEntries;
        } else {
            return;
        }
    }
    getFeatures = async () => {
        return JSON.parse(localStorage.getItem('features')!)
    }

    getEulaText = async (): Promise<string> => {
        return await this.root.webImpl.Session.getEulaText();
    }

    ssoEntry = async () => {
        const urlOrigin: string = this.webSession.rootURI.replace('api', '');
        window.location.href =
            `${this.webSession.rootURI}/oidc/idp/entry?referer=${urlOrigin}/features`
    }

    pendingItemsReciever = (handler: (entries: OfflineItems | undefined) => void ) => {
        this.pendingItemHandlers.push(handler);
        const theIndex = this.pendingItemHandlers.length - 1;
        return  () => {
            this.pendingItemHandlers[theIndex] = null;
        }
    }
    updatedTKsReciever = (handler: (tks: TimeKeeperAssignment[]) => void ) => {
        this.updatedTKHandlers.push(handler);
        const theIndex = this.updatedTKHandlers.length - 1;
        return  () => {
            this.updatedTKHandlers[theIndex] = null;
        }
    }

    exportLocalDBToJSON = async () => {
        let idb = this.root.db;
        if (idb) {
            let backendDB = idb.backendDB();
            await IDBExportImport.exportToJsonString(
                backendDB,
                (error: string, jsonString: string) => {
                    if (error) {
                        throw error;
                    } else {
                        let zipFolder = zip.folder('Epoch')

                        let epochDB = JSON.parse(jsonString);
                        for (let i in epochDB) {
                            if (epochDB[i].length > 0) {
                                zipFolder.file('epochDB.' + i + '.csv',
                                    this.JSONToCSVConvertor(JSON.stringify(epochDB[i])));
                            }
                        }
                        zipFolder.file('EpochLocalStorage.json', JSON.stringify(localStorage));
                        zip.generateAsync( { type: 'blob'}).then((blob: Blob) =>
                            FileSaver.saveAs(blob, 'epoch.zip')
                        )
                    }
                }
            )
        }
    }
    JSONToCSVConvertor = async (jsonString: string) => {
        let data = JSON.parse(jsonString);
        /* Create Worksheet */
        let ws = XLSX.utils.json_to_sheet(data);
        /* Add to WorkBook */
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws);
        /* write */
        let wBout = XLSX.write(wb, {
            bookType: 'csv',
            type: 'binary'
        });
        return wBout;
    }
    getTkGoals = async (year: number): Promise<TkGoals | undefined> => {
        let tkId = this.currentTimeKeeper;
        if (tkId) {
            const tkGoals: Promise<TkGoals | undefined> = this.root.db.tkGoals
                .where({timekeeperId: tkId, goalYear: year})
                .first();
            return tkGoals;
        } else {
            return undefined;
        }
    };
    getAllTimeKeepersList = async (search: string, offset: number, limit: number, workDate: string) => {
        return this.webSession.getAllTimeKeepersList(search, offset, limit, workDate);
    }
    get online(): boolean {
        return this.webSession.online
    }
}

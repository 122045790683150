import * as React from 'react'
import * as Styled from './styled'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import LuxonUtils from '@date-io/luxon';
import { Redirect, Route, Router } from 'react-router-dom'
import { observer, Provider } from 'mobx-react'
import DevTools from 'mobx-react-devtools'
import { MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { muitheme, theme } from 'common/theme'
import { ThemeProvider } from 'common/styled-components'
// https://material.io/color/#!/?view.left=1&view.right=0&primary.color=FF9800&secondary.color=424242
import rootStore from 'store/root.store'
import NavBar from 'components/NavBar'
import Home from 'containers/Home'
import Templates from 'containers/Templates'
import Matters from 'containers/Matters'
import Narratives from 'containers/Narratives'
import Settings from 'containers/Settings'
import TimeEntries from 'containers/TimeEntries'
import Timers from 'containers/Timers'
import Login from 'containers/Login/Login'
import { ApiProvider } from 'common/ApiProvider'
import Spinner from 'components/LoadingSpinner/Spinner'
import TimeKeeperDialog from 'components/TimeKeeperDialog'
import TimeEntryDialog from 'containers/TimeEntryDialog'
import MultipleTimeEntriesDialog from 'containers/MultipleTimeEntriesDialog'
import EULADialog from 'components/EULADialog/EULADialog'
import UnSavedDialog from './containers/UnSavedDialog/UnSavedDialog'
import MoveDateDialog from './containers/MoveDateTimeEntryDialog'
import MergeEntryDialog from './containers/MergeEntryDialog'
import TransferEntryDialog from './containers/TransferEntryDialog'
import CreateNarrativeCodeDialog from 'components/CreateNarrativeCodeDialog'
import {
    Button,
    CircularProgress,
    IconButton,
    Snackbar,
    TextField,
    SnackbarContent,
    Typography, LinearProgress
} from '@material-ui/core';
import { Close } from '@material-ui/icons'
import { Settings as LuxonSettings } from 'luxon'
import DeleteConfirmationDialog from 'containers/DeleteConfirmationDialog'
import MultipleTEConfirmationDialog from 'components/MultipleTEConfirmationDialog'
import SplitEntryDialog from './containers/SplitEntryDialog/SplitEntryDialog'
import { FlexDiv } from 'common/flex'
import NewTimerDialog from './containers/NewTimerDialog/NewTimerDialog'
import NavTimerNarrativeDialog from './containers/NavTimerNarrativeDialog'
import { FeaturesProvider } from 'common/FeaturesProvider'
import TimerPopOut from 'components/TimerPopOut/TimerPopOut'
import { Loading } from './containers/TimeEntries/styled'
import OfflineEntries from 'components/OfflineEntries/OfflineEntries'
import DateFnsUtils from '@date-io/date-fns';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import GeneralSettings from './containers/GeneralSettings/GeneralSettings';
import EditTimerChunkTimeDialog from 'components/TimerChunkItem/EditTimerChunkTimeDialog';
import TimerPopoutErrorDialog from 'components/TimerPopOut/TimerPopoutErrorDialog';
import HardLogoutDialog from 'components/NavBar/HardLogoutDialog';
import { Platform } from './util/Platform';
import ConfirmCollaborateDialog from 'components/TimeKeepersList/ConfirmCollaborateDialog';
export const countryTimeZone = require('countries-and-timezones');
import { TKProvider } from 'common/TKProvider';
import logger from './logging/logging';

const svgLogo = require('images/logodark.svg')

const appStore = rootStore.appStore

// load in dictionaries!
require('./store/dictionaries')
LuxonSettings.defaultZoneName = 'utc'
export const CountryLanguage = require('country-language');

// let locales = CountryLanguage.getLocales();
// const countrycode = countryTimeZone.getTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
//
// locales.map((locale: string) => {
//     if (locale.toUpperCase().search(countrycode.country.toUpperCase()) > 0) {
//         if (locale.includes('en')) { // Set locale by country, electron defaults it to US.
//             LuxonSettings.defaultLocale = locale;
//         }
//     }
// });

interface State {
    serverUrl: string
}

// tslint:disable-next-line:no-any
(window as any).store = rootStore

@observer
class App extends React.Component<object, State> {
    state: State = {
        serverUrl: process && process.env && process.env.NODE_ENV === 'development' 
            ? 'https://dev.epoch.fulcrumgt.com'
            : ''
    }

    componentDidMount() {
        // tslint:disable-next-line:no-any
        rootStore.history.block((location: any) => {
            rootStore.setLocation(location)
            return 'BLOCKED'
        });
        const enableLogging = localStorage.getItem('enableLogging');
        if (enableLogging) {
            if (JSON.parse(enableLogging)) {
                logger.silent = false;
            } else {
                logger.silent = true;
            }
        }
        logger.info('App has finished loading...');
    }
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        logger.info('Finally, I can see the reason for white screen. See below')
        logger.error(error, errorInfo)
    }
    
    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        appStore.errorText = '';
        this.setState({serverUrl: event.target.value});
    }

    render() {
        const {
            initializing,
            isFetchingRegistryKeys,
            authenticated,
            isServerSet,
            connectToServerUrl,
            syncing,
            disableAnimations,
            errorText,
            needsSoftLogin,
            pickersUtilLocale,
            chunkSaveLoader
        } = appStore
        
        muitheme.props = {
            MuiButtonBase: {
                disableRipple: disableAnimations
            }
        }
        
        if (initializing) {
            return (
                <MuiThemeProvider theme={muitheme}>
                    {Platform.isElectron() ?
                    <Styled.AppContainer>
                        {isServerSet ? (
                            <Spinner progress={appStore.initProgress}/>
                        ) : (
                            <FlexDiv fillContainer={true} direction="column">
                                <FlexDiv flex={1}/>
                                <div style={{textAlign: 'center', width: 300, margin: '0 auto'}}>
                                    <img src={svgLogo} height="80px"/>

                                    <br/>
                                    <br/>

                                    <TextField
                                        value={this.state.serverUrl}
                                        label="Epoch Server URL"
                                        fullWidth={true}
                                        onChange={this.onChange}
                                        helperText={errorText}
                                        error={errorText !== '' ? true : false}
                                    />
                                    <br/>
                                    <br/>
                                    <Button
                                        color="primary"
                                        variant="raised"
                                        onClick={() => connectToServerUrl(this.state.serverUrl)}
                                    >
                                        Set Server
                                    </Button>

                                    <br/>
                                    <br/>

                                    {isFetchingRegistryKeys && <span>
                                        <CircularProgress style={{width: '16px', height: '16px'}}/>
                                        &nbsp;&nbsp;
                                        Loading registry settings...
                                    </span>}
                                </div>
                                <FlexDiv flex={1}/>
                            </FlexDiv>
                        )}
                    </Styled.AppContainer> :
                    <Styled.AppContainer>
                        <Spinner progress={appStore.initProgress}/>
                    </Styled.AppContainer>
                    }

                    <Provider rootStore={rootStore}>
                        <>
                            <EULADialog/>
                            <TimeKeeperDialog/>

                            {needsSoftLogin && <Styled.Fullscreen><Login /></Styled.Fullscreen>}
                        </>
                    </Provider>
                </MuiThemeProvider>
            )
        }
        
        return (
            <MuiThemeProvider theme={muitheme}>
                <ThemeProvider theme={theme}>
                    <Provider rootStore={rootStore}>
                        <ApiProvider value={rootStore.api}>
                            <FeaturesProvider value={rootStore.appStore!.features}>
                                <DndProvider backend={HTML5Backend}> {/* Provider for Drag and Drop */}
                                <TKProvider value={rootStore.appStore!.currentTimekeeper}>
                                <Router history={rootStore.history}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pickersUtilLocale}>
                                        <Styled.AppContainer disableAnimations={rootStore.appStore.disableAnimations}>
                                            {authenticated ? <>
                                                    <NavBar/>
                                                    <Styled.AppBody>
                                                        <Route
                                                            exact={true}
                                                            path="/"
                                                            render={() => (
                                                                <Redirect to="/home"/>
                                                            )}
                                                        />
                                                        <Route path="/home" component={Home}/>
                                                        <Route path="/templates" component={Templates}/>
                                                        <Route path="/matters" component={Matters}/>
                                                        <Route path="/narratives" component={Narratives}/>
                                                        <Route path="/settings" component={Settings}/>
                                                        <Route path="/timeentries" component={TimeEntries}/>
                                                        <Route path="/timers" component={Timers}/>
                                                        <Route path="/timersPopOut" component={TimerPopOut}/>
                                                        <Route path="/offlineEntries" component={OfflineEntries}/>
                                                        <Route path="/general" component={GeneralSettings}/>
                                                    </Styled.AppBody>
                                                    <UnSavedDialog/>
                                                    <NavTimerNarrativeDialog/>
                                                    <TimeEntryDialog/>
                                                    <MultipleTimeEntriesDialog allowSaveAsTemplate={false}/>
                                                    <SplitEntryDialog/>
                                                    <MoveDateDialog/>
                                                    <MergeEntryDialog/>
                                                    <TransferEntryDialog/>
                                                    <NewTimerDialog/>
                                                    <DeleteConfirmationDialog/>
                                                    <MultipleTEConfirmationDialog/>
                                                    <CreateNarrativeCodeDialog/>
                                                    <EditTimerChunkTimeDialog/>
                                                    <TimerPopoutErrorDialog/>
                                                    <HardLogoutDialog/>
                                                    <ConfirmCollaborateDialog/>
                                                </> :
                                                <Route path="*" component={Login}/>
                                            }
                                            {
                                                !process.env.NODE_ENV ||
                                                process.env.NODE_ENV === 'development' &&
                                                <DevTools
                                                    position={{bottom: 0}}
                                                />
                                            }

                                            <CssBaseline/>

                                        </Styled.AppContainer>
                                    </MuiPickersUtilsProvider>
                                </Router>
                                <Snackbar
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                    open={rootStore.snackbarStore.snackbarOpen}
                                    autoHideDuration={3200}
                                    onClose={rootStore.snackbarStore.closeSnackbar}
                                    action={[
                                        <IconButton
                                            key="close"
                                            aria-label="Close"
                                            color="inherit"
                                            onClick={rootStore.snackbarStore.closeSnackbar}
                                        >
                                            <Close/>
                                        </IconButton>
                                    ]}
                                    TransitionProps={{
                                        appear: !disableAnimations
                                    }}
                                >
                                    <SnackbarContent
                                        style={{
                                            backgroundColor: rootStore.snackbarStore.isError ? '#ef4e4e' : '',
                                            color: rootStore.snackbarStore.isError ? 'white' : '',
                                        }}
                                        message={
                                            <span id="message-id">
                                                    {rootStore.snackbarStore.snackbarMessage}
                                            </span>
                                        }
                                    />
   
                                </Snackbar>
                                {syncing && <Loading>
                                    <div style={{top: '-15%', position: 'relative'}}>
                                        <CircularProgress size={100}/>
                                    </div>
                                </Loading>}
                                {chunkSaveLoader && <Loading>
                                    <div style={{top: '-15%', position: 'relative'}}>
                                        <CircularProgress size={100}/>
                                    </div>
                                </Loading>}
                                    {needsSoftLogin && <Styled.Fullscreen>
                                        <Login />
                                    </Styled.Fullscreen>}
                                    </TKProvider>
                                </DndProvider>
                            </FeaturesProvider>
                        </ApiProvider>
                    </Provider>
                </ThemeProvider>
            </MuiThemeProvider>
        )
    }
}

export default App ;
import React, { Component } from 'react';
import { MenuProvider } from 'react-contexify';
import * as Styled from 'components/OfflineEntries/styled';
import { Tooltip } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import TemplateForm, { TemplateFormValidation } from 'components/TemplateForm';
import ImmutableTemplate from '../../api/immutables/ImmutableTemplate';
import { HeaderItem, Form } from 'components/TimeEntryPanel/styled';
import { parseCode } from '../../util/utils';
import * as ReactDOM from 'react-dom';
import scrollIntoView from 'scroll-into-view-if-needed';

interface PanelProps {
    template: ImmutableTemplate;
    onExpand: (expanded: boolean) => void;
    expanded?: boolean;
    onChange: (t: ImmutableTemplate, n?: TemplateFormValidation) => void;
}
class TemplatePanelItem extends Component<PanelProps> {
    constructor(props: PanelProps) {
        super(props);
    }

    expand = () => {
        if (!this.props.expanded) {
            setTimeout(() => {
                let elem: HTMLElement = ReactDOM.findDOMNode(this) as HTMLElement;
                scrollIntoView(elem, { behavior: 'smooth', scrollMode: 'if-needed' })
            }, 0);
        }
        this.props.onExpand!(!this.props.expanded);
    }

    render() {
        const { template, expanded, onExpand, onChange } = this.props;
        const clientText = parseCode(template.clientName, template.clientNumber);
        const matterText = parseCode(template.matterName, template.matterNumber);
        const validation: TemplateFormValidation = { name: false, matter: false, narrative: false};
        
        return (
            <Styled.Container
                elevation={1}
                square={true}
                expanded={expanded || false}
                type={'template'}
            >
                <MenuProvider id={`menu_${template.id}`} >
                    <Styled.OfflineHeader
                        type={`template`}
                        onClick={onExpand && this.expand}
                    >
                        <Tooltip title={template.name}>
                            <HeaderItem>{template.name}</HeaderItem>
                        </Tooltip>
                        <Tooltip title={clientText}>
                            <HeaderItem>{clientText}</HeaderItem>
                        </Tooltip>
                        <Tooltip title={matterText}>
                            <HeaderItem>{matterText}</HeaderItem>
                        </Tooltip>
                        <Tooltip title={template.narrative}>
                            <HeaderItem>{template.narrative}</HeaderItem>
                        </Tooltip>
                        {expanded ? <div title="Collapse"><KeyboardArrowUp/></div>
                            : <div title="Expand"><KeyboardArrowDown/></div>}

                    </Styled.OfflineHeader>
                </MenuProvider>

                { expanded && 
                <Form>
                    <TemplateForm
                        template={template}
                        disabled={true}
                        onChange={onChange}
                        validation={validation}
                    />
                </Form>
                }
            </Styled.Container>
        );
    }
}

export default TemplatePanelItem;
import { observable, action, computed } from 'mobx';
import Template from 'api/immutables/ImmutableTemplate';
import BaseStore from 'store/base.store';
import { TemplateFormValidation } from 'components/TemplateForm';
import { removeListItem, setListItem } from 'util/array';
import { RootStore } from './root.store';
import { debounce } from 'typescript-debounce-decorator';
import { ValidateTemplate } from 'api/immutables/validators';
import { sortByAlphaNumeric } from '../util/utils';

export default class TemplateStore extends BaseStore {
    @observable templates: Template[] = [];
    @observable selectedTemplate: Template;
    @observable saving: boolean = false;
    @observable searchText: string = '';
    @observable validation: TemplateFormValidation = { name: false, matter: false, narrative: false};
    @observable expandedTemplateIds: number[] = [];
    
    @computed get minNarrativeLength() {
        return this.rootStore!.appStore!.features!.EpochConfigNarrativesMinimumChars;
    }
    @computed get maxNarrativeLength() {
        return this.rootStore!.appStore!.features!.EpochConfigNarrativesMaximumChars;
    }
    
    originalTemplate: Template;
    handlerDestructor: () => void;
    constructor(rootStore: RootStore) {
        super(rootStore);
        this.wrappedSave = this.wrappedSave.bind(this);
        this.initializeHandler();
    }
    initializeHandler = () => {
        this.handlerDestructor = this.rootStore.api.Template.registerReciever(this.recieveTemplates)
    }

    @action recieveTemplates = (templates: Template[]) => {
        templates.forEach(t => {
            if ( t.timeKeeperId !== this.rootStore.api.Session.currentTimeKeeper) {
                return;
            }
            if (t.deleted) {
                this.templates = removeListItem(this.templates, t.id!);
                if (this.selectedTemplate && this.selectedTemplate.id === t.id!) {
                    this.saving = false;
                    this.selectedTemplate = new Template();
                    this.originalTemplate = this.selectedTemplate.clone();
                    if (this.rootStore.routerStore.location.pathname === `/templates/${t.id}`) {
                        this.rootStore.routerStore.push(`/templates/new`);
                    }
                }
                return;
            }
            let newLocal = this.templates.slice();
            setListItem(newLocal, t);
            this.templates = newLocal;

        })
    }
    @action determineCodeSetsInTemplate = async (e: Template) => {
        if (e) {
            if (e.matterId) { // if Matter exists, then hydrate entry with code set flags
                const codeSetFlags = await this.rootStore.api.Code.determineCodeSetFields(
                    e.matterId, ''
                );
                e.isActCode = codeSetFlags.isActCode;
                e.isPhaseCode = codeSetFlags.isPhaseCode;
                e.isFfTaskCode = codeSetFlags.isFfTaskCode;

                let newLocal2 = this.templates.slice();
                
                setListItem(newLocal2, e);
                this.templates = newLocal2;
            }
        }
    }
    @computed get dirty(): boolean {
        return !!(this.selectedTemplate && this.selectedTemplate.dirty)
    }
    
    @action loadTemplates = async () => {
        this.templates = await this.rootStore.api.Template.getAllTemplates();
    }
    
    @action loadTemplate = async (id: number) => {
        this.selectedTemplate = await this.rootStore.api.Template.getTemplate(id);
        this.originalTemplate = this.selectedTemplate.clone();
    }
    
    @action changeSelectedTemplate = async (id: number | null | undefined) => {
        if (!this.selectedTemplate || id !== this.selectedTemplate.id) {
            const notDirty = await this.rootStore.routerStore.attemptPush(`/templates/${id}`);

            const template = this.templates.find(t => t.id === id);
            if (template && notDirty) {
                await this.determineCodeSetsInTemplate(template);
                this.saving = false;
                this.resetValidation();
                this.selectedTemplate = template.clone();
                this.originalTemplate = template.clone();
            }
        }
    }

    @action newTemplate = async () => {
        const notDirty = await this.rootStore.routerStore.attemptPush(`/templates/new`);
        if (notDirty) {
            this.resetValidation();
            this.saving = false;
            this.selectedTemplate = new Template();
            this.originalTemplate = this.selectedTemplate.clone();
        }
    }

    @action onTemplateChange = (newTemplate: Template, newValidation?: TemplateFormValidation) => {
        this.saving = true;
        newTemplate.dirty = true;
        this.selectedTemplate = newTemplate;
        this.validation = newValidation ? newValidation : this.validation;
    }

    @action validate = (): boolean => {
        if (!this.selectedTemplate.dirty) {
            this.validation = {}
        } else {
            // this.selectedTemplate.dirty = false;
            // name validation
            let v = ValidateTemplate(this.selectedTemplate, this.templates, this.maxNarrativeLength);

            if (v.duplicateName) {
                this.validation.name = 'Name already exists'
            } else if (v.emptyName) {
                this.validation.name = 'Template name cannot be empty'
            }
            // narrative validation
            if (v.narrativeMaxLength) {
                this.validation.narrative = `Narrative cannot exceed ${this.maxNarrativeLength} characters`
            }
            // matter validation
            if (v.noMatter) {
                this.validation.matter = 'Matter must be selected'
            }
        }
        this.validation = Object.assign({}, this.validation);
        return !this.validation.name && !this.validation.matter && !this.validation.narrative;
    }

    @action resetValidation = () => {
        this.validation = { name: false, matter: false, narrative: false };
    }

    @debounce(500, {leading: false})
    @action 
    async wrappedSave(t: Template) {
        this.saving = false;
        try {
            await this.saveTemplate(t);
        } finally {
            // do nothing
        }
    }

    @action saveTemplate = async (t: Template) => {
        if (!this.validate()) {
            return;
        }
        const templatesLength = this.templates.length;
        let idx = this.templates.findIndex(tmp => tmp.id === t.id)

        if (t.narrative) {
           t = t.setNarrative(t.narrative.trim());
        };

        let resp: Template;
        try {
            resp = await this.rootStore.api.Template.saveTemplate(t);
        } catch (e) {
            // handle error...
            return;
        }
        const newTemplate = resp;
        newTemplate.isActCode = t.isActCode;
        newTemplate.isFfTaskCode = t.isFfTaskCode;
        newTemplate.isPhaseCode = t.isPhaseCode;
        
        if (idx > -1) {
            this.templates[idx] = newTemplate.clone();
            this.templates.splice(1, 0);
            this.selectedTemplate.dirty = false;
            this.rootStore.snackbarStore.triggerSnackbar('Saved Successfully');
        } else {
            this.templates.unshift(newTemplate);
            this.selectedTemplate = newTemplate.clone();
            this.rootStore.routerStore.push(`/templates/${newTemplate.id}`);
            this.rootStore.snackbarStore.triggerSnackbar('Template Created');
        }
        this.selectedTemplate = new Template()
        this.originalTemplate = new Template();
        this.rootStore.routerStore.push(`/templates/new`);
    }

    @action deleteTemplate = async (id: number | null | undefined) => {
        if (await this.rootStore.canIChangeScope()) {
            const confirmDelete = await this.rootStore.deleteConfirmationDialog.open();
            if (!confirmDelete) { return };

            const idx = this.templates.findIndex(t => t.id === id);
            if (idx > -1) {
                this.templates[idx].deleted = true;
                await this.rootStore.api.Template.saveTemplate(this.templates[idx]);
                this.templates.splice(idx, 1);
            }

            if (this.selectedTemplate && this.selectedTemplate.id === id) {
                this.newTemplate();
            }

            this.rootStore.snackbarStore.triggerSnackbar('Deleted Successfully');
        }
    }
    
    @action restoreTemplate = () => {
        this.selectedTemplate = this.originalTemplate.clone();
        this.saving = false;
        this.resetValidation();
    }
    
    @action onSearchChange = (input: string) => {
        this.searchText = input;
    }
    
    @computed get names(): Template[] {
        return this.templates.map((t) => t);
    }
    searchWithinObject(template: Template, search: string): boolean {
        let nar = template.narrative ? template.narrative.toLowerCase() : '',
            mname = template.matterName ? template.matterName.toLowerCase() : '',
            mnumber = template.matterNumber ? template.matterNumber.toLowerCase() : '',
            cname = template.clientName ? template.clientName.toLowerCase() : '',
            cnumber = template.clientNumber ? template.clientNumber.toLowerCase() : '',
            actCodeTxt = template.actCode ? template.actCode.toLowerCase() : '',
            ffTaskCodeTxt = template.ffTaskCode ? template.ffTaskCode.toLowerCase() : '',
            ffActCodeTxt = template.ffActCode ? template.ffActCode.toLowerCase() : '',
            taskCode = template.taskCode ? template.taskCode.toLowerCase() : '',
            phaseName = template.phaseName ? template.phaseName.toLowerCase() : '',
            actionCodeText = template.actionCode ? template.actionCode.toLowerCase() : '',
            tmpName = template.name ? template.name.toLowerCase() : '', 
            
            searchText = search.toLowerCase();

        return nar.includes(searchText) ||
            mname.includes(searchText) ||
            mnumber.includes(searchText) ||
            cname.includes(searchText) ||
            cnumber.includes(searchText) ||
            actCodeTxt.includes(searchText) ||
            ffTaskCodeTxt.includes(searchText) ||
            ffActCodeTxt.includes(searchText) ||
            taskCode.includes(searchText) ||
            phaseName.includes(searchText) ||
            actionCodeText.includes(searchText) ||
            tmpName.includes(searchText);
    }

    @computed get filteredNames(): Template[] {
        const filteredTemplates = this.names.filter((te: Template) => {
            return this.searchWithinObject(te, this.searchText);
        });
        const filtered = filteredTemplates.sort((a, b) => sortByAlphaNumeric(a.name, b.name));
        
        return filtered;  
    }

    @action setExpandedTemplates = (ids: number[]) => {
        this.expandedTemplateIds = ids;
    };
    @action
    fetchTemplatesInAutoComplate = async (search: string) => {
        const templates = await this.rootStore.api.Template.getAllTemplates();

        let filteredTemplates = templates.filter((te: Template) => {
            return this.searchWithinObject(te, search);
        });
        filteredTemplates = filteredTemplates.sort((alpha, beta) =>
            sortByAlphaNumeric(alpha.name, beta.name)
        )

        return filteredTemplates;
    }
};
import ImmutableTimeEntry from 'api/immutables/ImmutableTimeEntry';
import { TimeKeeperAssignment } from '../api/types/types';
import { Settings as LuxonSettings, DateTime } from 'luxon'

export function getStringMatches(searchString: string, targetStrings: string[]): string[] {
    let lcase = searchString.toLowerCase();
    return targetStrings.reduce((prev, cur) => {
        let matchStr = '\\b(' + cur + '\\b)';
        let re = new RegExp(matchStr, 'gi');
        let matchFound = lcase.match(re);
        if (matchFound) {
            return prev.concat(matchFound[0]);
        }

        return prev;
    }, [] as string[]);
}
export function getStringMatchesForBBWords(searchString: string, targetStrings: string[]): string[] {
    let lcase = searchString.toLowerCase();
    return targetStrings.reduce((prev, cur) => {
        let matchStr = '(\\' + cur + ')';
        let re = new RegExp(matchStr, 'gi');
        let matchFound = lcase.match(re);
        if (matchFound) {
            return prev.concat(matchFound[0]);
        }
        return prev;
    }, [] as string[]);
}
export function timeEntryCanSave(timeEntry: ImmutableTimeEntry): boolean {
    let narErrs = 
    getStringMatches(timeEntry.narrative || '', timeEntry.bannedWords || []).length
    + getStringMatches(timeEntry.narrative || '', timeEntry.blockBillingWords || []).length;
    return narErrs === 0;
}
export const parseCode = (name: string | null | undefined, desc: string | null | undefined) => 
    `${(name ? name : '') + (desc ? ' - ' + desc : '')}`;

const systemTimeZone = LuxonSettings.defaultZoneName;

export const fileNameForExport = (tk: TimeKeeperAssignment | undefined, appendStr: string) => {
    const today = DateTime.local().setZone(systemTimeZone);
    // Not able to save file with LocaleString format in Windows
    // tslint:disable-next-line:max-line-length
    const dayTime = `${today.toFormat('DD')}-${today.toFormat('hh')} ${today.toFormat('mm')} ${today.toFormat('ss')} ${today.toFormat('a')}`
    return `${appendStr}-${tk ? tk.name : ''}-${dayTime}`;
}

export const sortByAlphaNumeric = (string1: string, string2: string) => {
    // Constructor for collators, which are objects that enable language-sensitive string comparison.
    // By passing the numeric: true option, it will smartly recognize numbers. 
    // It compares alphanumeric, numeric, special characters and alphabets
    // No localization as of now, so undefined
    const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base'
    });
    return collator.compare(string1.toLowerCase(), string2.toLowerCase())
}

export const dateISOFormat = (date: string): string => { return (DateTime.fromISO(date)).toFormat('yyyy-MM-dd'); }

import * as React from 'react';
import { inject, observer } from 'mobx-react';
import TransferEntryDialogStore from 'store/transferEntry.dialog.store';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
}
    from '@material-ui/core';
import { RootStore } from 'store/root.store';
import TimeEntryForm from 'components/TimeEntryForm/TimeEntryForm';
import { Features } from '../../api/types/types';
import { FlexDiv } from 'common/flex';
import { ApiConsumer } from 'common/ApiProvider';
import RootAPI from '../../api/interfaces/RootAPI';
import AutoCompleteField from 'components/AutoCompleteField/AutoCompleteField';
import Template from '../../api/immutables/ImmutableTemplate';
import { FeaturesConsumer } from 'common/FeaturesProvider';

interface Props {
    store?: TransferEntryDialogStore;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        store: rootStore.transferEntryDialogStore,
    };
})
@observer
export default class TransferEntryDialog extends React.Component<Props> {

    render() {
        const {
            isOpen,
            cancel,
            entry,
            selectedTemplate,
            setTemplate,
            changeEntry,
            saving,
            wrappedSaveTransferredEntries,
            wrappedPostTransferredEntries,
            durationValidationState,
            validationState } = this.props.store!;
        
        const fetchTemplates = this.props.store!.rootStore.templateStore.fetchTemplatesInAutoComplate;
        return (
            <FeaturesConsumer>
                {(features: Features) =>
                    <>
                        <Dialog
                            PaperProps={{ style: { overflow: 'visible', maxWidth: '1024px' } }}
                            disableBackdropClick={true}
                            disableEscapeKeyDown={true}
                            open={isOpen}
                            onClose={cancel}
                            scroll="paper"
                            maxWidth="md"
                            aria-labelledby="scroll-dialog-title"
                            fullWidth={true}
                        >
                            <DialogTitle
                                id="dialog-transfer-title"
                                style={{ paddingBottom: 0 }}
                            >
                                Transfer
                            </DialogTitle>
                            <DialogContent>
                                <FlexDiv direction="row" style={{ alignItems: 'flex-end' }}>
                                    <FlexDiv flex={1} />
                                    {entry && !entry.isPosted() && <ApiConsumer>
                                        {(api: RootAPI) =>
                                            <div>
                                                <AutoCompleteField
                                                    label="Template"
                                                    fetch={fetchTemplates}
                                                    currentItem={selectedTemplate}
                                                    clearable={true}
                                                    disabled={false}
                                                    onClear={() => setTemplate(undefined)}
                                                    getItemText={(m: Template) => `${m.name}`}
                                                    onSelect={setTemplate}
                                                    width={200}
                                                />
                                            </div>}

                                    </ApiConsumer>}
                                </FlexDiv>
                                <TimeEntryForm
                                    timeEntry={entry}
                                    durValidationState={durationValidationState}
                                    onChange={changeEntry}
                                    minHeight={80}
                                    validationState={validationState}
                                    actionCodesRequired={
                                        features.EpochConfigActionCodesRequired
                                    }
                                    minNarrativeLength={
                                        features.EpochConfigNarrativesMinimumChars
                                    }
                                    maxNarrativeLength={
                                        features.EpochConfigNarrativesMaximumChars
                                    }
                                    includeDuration={false}
                                    includeNarrative={false}
                                />
                            </DialogContent>
                            <DialogActions>
                            {entry && !entry.matterId && entry.reference && <div style={{ paddingLeft: 12 }}>
                                    <span>Reference: </span>
                                    <span>{entry.reference}</span>
                                </div>}

                                <FlexDiv flex={1}/>
                                {entry && !entry.isPosted() &&
                                    <>
                                        <Button disabled={saving} onClick={wrappedSaveTransferredEntries}>Save</Button>
                                        <Button disabled={saving} onClick={wrappedPostTransferredEntries}>Post</Button>
                                    </>
                                }
                                <Button onClick={cancel}>Cancel</Button>
                            </DialogActions>
                        </Dialog>
                    </>
                }
            </FeaturesConsumer>
        )
    }
}
import { DialogRootStore } from 'store/dialog.root.store';
import { onOpen } from '@fulcrumgt/mobx-store-utils';
import { action, observable } from 'mobx';
import ImmutableTemplate from '../../api/immutables/ImmutableTemplate';
import { Matter } from '../../api/types/types';
import ImmutableTimer from '../../api/immutables/ImmutableTimer';
import { debounce } from 'typescript-debounce-decorator';
import { Timer } from '../../api/implementations/electron/Dexie';

export interface TimerFormValidation {
    name?: string | boolean
}

export default class TimerNewDialogStore extends DialogRootStore {
    @observable name: string = '';
    @observable template?: ImmutableTemplate | null;
    @observable matter?: Matter | null;
    @observable start: boolean = false;
    @observable validation: TimerFormValidation = { name: false};
    @observable saving: boolean = false;

    @action.bound toggleStart() {
        this.start = !this.start;
    }
    @action.bound setName(name: string) {
        this.name = name;
        this.validation.name = false;
    }
    
    @action.bound setTemplate(template?: ImmutableTemplate) {
        this.template = template;
        if ( !this.name && template ) {
            this.name = template.name
            this.validation.name = false;
        }
        this.matter = null;
    }
    @action.bound async setMatter(matter?: Matter) {
        if (matter) {
            if (!matter.tracked) {
                let csIds: number[] = [];
                csIds.push(matter.id);
                await this.rootStore.api.Matter.track(csIds);
            }
            if (!this.name && matter) {
                this.setName(matter.name)
                this.validation.name = false;
            }
            this.matter = (await this.rootStore.api.Matter.get(matter.id)) || undefined;
        } else {
            this.matter = matter;
        }
        this.template = null;
    }
    @onOpen() @action.bound showDialog() {
        this.name = '';
        this.template = undefined;
        this.matter = undefined;
        this.start = false;
        this.saving = false;
        this.validation = Object.assign({ name: false });
    }
    validateTimerName = (timer: ImmutableTimer) => {
        let duplicates = this.rootStore.timerStore.timers.filter((t) => {
            return (t.name || '').trim().toUpperCase() === (timer.name || '').trim().toUpperCase()
        })
        if (duplicates.length > 0) {
            this.validation.name = 'Name already exists';
        }
        if (timer.name.length > 100) {
            this.validation.name = 'Name cannot exceed 100 characters';
        }
        if (timer.name.trim() === '') {
            this.validation = Object.assign({ name: 'Required Field'});
        }
        return !this.validation.name;
    }
    saveNew = async () => {
        let timer = Object.assign(new ImmutableTimer(), {
            name: this.name.trim().replace(/\s\s+/g, ' '),
            matterId: this.matter ? this.matter.id : undefined,
            templateId: this.template ? this.template.id : undefined,
            timeKeeperId: this.rootStore.appStore.currentTimekeeper.timeKeeperId!,
            deleted: false,
            favorite: false
        });
        if (!this.validateTimerName(timer)) {
            return [];
        }
        let createdTimers: ImmutableTimer[] = [];
        if (this.start) {
            let stoppedTimer = await this.rootStore.api.Timer.stop();
            if (stoppedTimer) {
                createdTimers.push(stoppedTimer)
            }
            let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            timer.active = true;
            timer.startedOn = (new Date()).toISOString();
            timer.startedTimezone = timeZone;
            timer.notes = '';
        } else {
            timer.active = false;
        }
        let resp = await this.rootStore.api.Timer.updateTimers([timer]);
        const respTimer = resp[0].object as unknown as Timer;
        if (!respTimer.id && respTimer.localId) {
            respTimer.id = respTimer.localId * -1;
        }
        createdTimers.push(await this.rootStore.api.Timer.get(respTimer.id!));
        this.rootStore.snackbarStore.triggerSnackbar('Saved Successfully');
        return createdTimers;
    }
    @action.bound @debounce(500, {leading: false}) async doSave() {
        if (this.saving) {
            return;
        }
        this.saving = true;
        let createdTimers = await this.saveNew();
        if (createdTimers.length) {
            this.resolveAndClose(createdTimers);
        } else {
            this.saving = false;
            return;
        }
        this.saving = false;
    }
}
import * as React from 'react';
import * as Styled from './styled.desktop';
import { Fab, IconButton, List, Popover, PropTypes, Tooltip } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import TimerStore from 'store/Timer/timer.store';
import ImmutableTimer from '../../api/immutables/ImmutableTimer';
import { InsertDriveFile, PlayArrow, Stop } from '@material-ui/icons';
import NarrativeDialogStore from 'store/narrative.dialog.store';
import TimerLink from './TimerLink';
import { TimeKeeperAssignment } from '../../api/types/types';
import { TKConsumer } from 'common/TKProvider';
interface Props {
    // homeStore?: HomeStore;
    timerStore?: TimerStore;
};

interface State {
    MenuOpen: boolean;
};

interface NavHeaderTimerProps {
    timer: ImmutableTimer;
    onClick?: () => void;
    onStart: () => void;
    onStop: () => void;
    onAddNote?: () => void;
    includeSubmitted: boolean;
    loadingId?: number,
    actionColor?: PropTypes.Color;
    narrativeDialogStore?: NarrativeDialogStore;
    
    timerStore?: TimerStore;
}

interface NavHeaderTimerState {
    // tslint:disable-next-line:no-any
    interval?: any,
    durTxt: string;
}

export class NavHeaderTimer extends React.Component<NavHeaderTimerProps, NavHeaderTimerState> {
    constructor(props: NavHeaderTimerProps) {
        super(props);
        this.state = {
            durTxt: this.getTimerDurationTxt(),
            interval: props.timer.active ? setInterval(
                this.timerTick,
                250) : undefined
        }
    }
    getSnapshotBeforeUpdate(prevProps: NavHeaderTimerProps) {
        if (prevProps.timer.active === this.props.timer.active) {
            return null;
        }
        if (prevProps.timer.active) {
            clearInterval(this.state.interval);
            this.setState({
                interval: undefined
            })
            // prev active, now inactive
        } else {
            this.setState({
                interval: setInterval(
                    this.timerTick,
                    250)
            })
        }
        return null;
    };
    timerTick = () => {
        this.setState({durTxt: this.getTimerDurationTxt()})
    };
    getTimerDurationTxt = () => {
        const timer = this.props.timer;
        let secs = timer.totalDuration;
        if (!this.props.includeSubmitted) {
            secs = secs - timer.convertedDuration;
        }
        if (timer.active) {
            let da = Math.floor((new Date().getTime() - new Date(timer.startedOn!).getTime()) / 1000);
            secs += da;
        }
        // secs = Math.floor(secs / 1000);
        let hours   = Math.floor(secs / 3600);
        let minutes = Math.floor((secs - (hours * 3600)) / 60);
        let seconds = secs - (hours * 3600) - (minutes * 60);
        let hstring = `${hours}`;
        let sstring = `${seconds}`;
        let mstring = `${minutes}`;
        if (hours   < 10) {hstring   = '0' + hstring; }
        if (minutes < 10) {mstring = '0' + mstring; }
        if (seconds < 10) {sstring = '0' + sstring; }
        return `${hstring}:${mstring}:${sstring}`
    };
    
    render() {
        const { 
            timer,
            onStart,
            onStop,
            loadingId,
            onAddNote
        } = this.props;
        
        const dtxt = timer.active ? this.state.durTxt : this.getTimerDurationTxt();
        
        return (
            <Styled.CustomListItem
                button={true}
                onClick={this.props.onClick}
            >
                <TKConsumer>
                { (tk: TimeKeeperAssignment) =>
                <Styled.ListItemContent>
                    {!timer.active ?
                        <Tooltip title="Start">
                        <Fab
                            disabled={!!loadingId || !tk.writable}
                            onClick={e => {
                                e.stopPropagation();
                                onStart()
                            }}
                            size="medium"
                            style={{minWidth: '48px'}}
                            color={this.props.actionColor ? this.props.actionColor : 'primary'}
                            aria-label="Start"
                        >
                            <PlayArrow />
                            {(loadingId === timer.id) && <Styled.FabProgress size={55} />}
                        </Fab></Tooltip> :
                        <Tooltip title="Pause">
                        <Fab
                            disabled={!!loadingId}
                            onClick={e => {
                                e.stopPropagation();
                                onStop()
                            }}
                            size="medium"
                            style={{backgroundColor: 'rgb(255, 87, 34)', color: 'white', minWidth: '48px'}}
                            aria-label="Stop"
                        >
                            <Stop />
                            {(loadingId === timer.id) && <Styled.FabProgress size={55} />}
                        </Fab></Tooltip>}
                    <Styled.ListText
                        primary={timer.name}
                        secondary={dtxt}
                        primaryTypographyProps={{
                            style: {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: 'calc(100% - 40px)'
                            }
                        }}
                    />
                    {!!timer.active &&
                    <Styled.CustomSecondaryAction>
                        <div title="Add a narrative">
                            <IconButton
                                onClick={e => {
                                    e.stopPropagation();
                                    onAddNote!()
                                }}
                            >
                                <InsertDriveFile/>
                            </IconButton>
                        </div>
                    </Styled.CustomSecondaryAction>
                    }
                </Styled.ListItemContent>}
                </TKConsumer>
            </Styled.CustomListItem>
        );
    }
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        timerStore: rootStore.timerStore
    };
})

@observer
export class NavTimer extends React.Component<Props, State> {
    navTimerRef: React.RefObject<HTMLDivElement> = React.createRef();
    constructor(props: Props) {
        super(props);
        this.state = {
            MenuOpen: false
        }
    }
    
    toggleMenu = () => {
        this.setState({ MenuOpen: !this.state.MenuOpen })
    };
    
    render() {
        const {
            loadingTimer,
            startTimer,
            stopTimer,
            timers,
            addTimerNote,
            isActiveToday
        } = this.props.timerStore!;
        const includeSubmitted
            = this.props.timerStore!.rootStore.appStore.features.EpochConfigTimeSegmentsSubmittedVisible;
        const activeTimer = 
            timers.filter(timer => !!timer.active)[0] || 
            timers.slice().sort((a: ImmutableTimer, b: ImmutableTimer) => 
                (a.lastModified < b.lastModified) ? 1 : -1)[0];

        return (
             <Styled.NavTimerContainer 
                 innerRef={this.navTimerRef}
             >
                {!!activeTimer ? 
                    <NavHeaderTimer
                        timer={activeTimer}
                        onClick={() => this.toggleMenu()}
                        onStart={startTimer(activeTimer!)}
                        onStop={stopTimer(activeTimer ? activeTimer.id! : 0)}
                        onAddNote={addTimerNote(activeTimer!)}
                        loadingId={loadingTimer}
                        includeSubmitted={includeSubmitted}
                        actionColor="secondary"
                    /> 
                    : <Styled.NavTimerContainer/>
                }
                <Popover
                    open={this.state.MenuOpen}
                    onClose={() => this.toggleMenu()}
                    anchorEl={this.navTimerRef.current}
                    PaperProps={{style: {
                        marginLeft: '16px',
                        borderTopLeftRadius: '0',
                        borderTopRightRadius: '0',
                        maxHeight: '228px',
                        }}}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                >
                    <List
                        style={{
                            width: 280,
                            paddingTop: '0',
                            maxWidth: 280,
                            minHeight: 48
                        }}
                    >
                        {timers
                            .filter(t => t.id !== activeTimer.id)
                            .sort((thisTimer, thatTimer) => (thisTimer.lastModified < thatTimer.lastModified) ? 1 : -1)
                            .map((timer: ImmutableTimer) => {
                            return (
                                <TimerLink
                                    key={timer.id!.toString()}
                                    timer={timer}
                                    isActiveToday={isActiveToday(timer)}
                                    onStart={startTimer(timer)}
                                    onStop={stopTimer(timer.id!)}
                                    loadingId={loadingTimer}
                                    includeSubmitted={includeSubmitted}
                                    disableDelete={true}
                                />
                            );
                        })}
                    </List>
                </Popover>  
             </Styled.NavTimerContainer>
        );
    }
}
import TimeEntryAPI, { AssociateApiResult } from 'api/interfaces/TimeEntryAPI';
import BaseElectronImplementation from './Base.impl';
import { DateTime } from 'luxon';
import ImmutableTimeEntry, { SapStatus } from 'api/immutables/ImmutableTimeEntry';
import { TimeEntry } from './Dexie';
import ImmutableBaseEntry from 'api/immutables/ImmutableBaseEntry';
import { ApiResult } from 'api/util';
import { TimeEntryType, DayCount, TimerChunk, TimeCastSegment, Features } from '../../types/types';
import ImmutableTimer from '../../immutables/ImmutableTimer';

export default class TimeEntryImpl extends BaseElectronImplementation implements TimeEntryAPI {
    handlers: (((entries: ImmutableTimeEntry[]) => void) | null )[] = [];
    hydrateTimeEntry = async (timeEntry: TimeEntry): Promise<ImmutableTimeEntry> => {
        let entry = Object.assign(new ImmutableTimeEntry(), timeEntry);
        if (entry.sapStatus !== SapStatus.UNSUBMITTED) {
            return entry;
        }
        // todo parallelize this
        // if (entry.matterId) {
        //     let matter = await this.root.Matter.get(entry.matterId);
        //     entry = ImmutableBaseEntry.applyMatter<ImmutableTimeEntry>(entry, matter)
        // }
        // if (entry.phaseId) {
        //     let phase = await this.root.Code.get(entry.phaseId);
        //     entry = ImmutableBaseEntry.applyPhase<ImmutableTimeEntry>(entry, phase)
        // }
        // if (entry.taskCodeId) {
        //     let task = await this.root.Code.get(entry.taskCodeId);
        //     entry = ImmutableBaseEntry.applyTask<ImmutableTimeEntry>(entry, task)
        // }
        // if (entry.actCodeId) {
        //     let act = await this.root.Code.get(entry.actCodeId);
        //     entry = ImmutableBaseEntry.applyActivity<ImmutableTimeEntry>(entry, act)
        // }
        // if (entry.ffTaskCodeId) {
        //     let fftask = await this.root.Code.get(entry.ffTaskCodeId);
        //     entry = ImmutableBaseEntry.applyFFTask<ImmutableTimeEntry>(entry, fftask)
        // }
        // if (entry.ffActCodeId) {
        //     let ffact = await this.root.Code.get(entry.ffActCodeId);
        //     entry = ImmutableBaseEntry.applyFFActivity<ImmutableTimeEntry>(entry, ffact)
        // }
        // if (timeEntry.billingLang !== `EN`) {
        //     entry.billingLang = timeEntry.billingLang;
        //     entry.billingLangText = timeEntry.billingLangText;
        // }
        entry.dirty = false;
        return entry;
    }
    async getEntries(fromDate: DateTime, toDate: DateTime, tkId: number) {
        // this only gets called dates doesnt fall under retention dates
        this.preRetentionDateEntries(fromDate, toDate, tkId);
        return Promise.all((await this.root.db.timeEntries.filter((te) => {
            if (te.deleted) {
                return false;
            }
            if (te.timeKeeperId !== tkId) {
                return false;
            }
            let teD = DateTime.fromISO(te.workDateTime);
            return teD >= fromDate && teD <= toDate;
        }).toArray()).map(this.hydrateTimeEntry));
    }

    preRetentionDateEntries = async (fromDate: DateTime, toDate: DateTime, tkId: number) => {
        // If dates doesn't fall under the retention dates, set new retention dates and call getAll
        let retentionFromDate = DateTime.fromISO(
            JSON.parse(localStorage.getItem('retentionFromDate') || '') ||
            DateTime.local().toISO()
        );
        if (fromDate <= retentionFromDate) {
            const from = fromDate.minus({ months: 3 }).startOf('month');
            localStorage.setItem('retentionFromDate', JSON.stringify(from));
            try {
                const entries = await this.root.webImpl.TimeEntry.getEntries(from, retentionFromDate, tkId);
                const promArray = entries.map(async (entry: ImmutableTimeEntry) => {
                    let insertKey = await this.getWriteableId(entry);
                    let writeableEntry = entry.toWriteable() as TimeEntry
                    if (insertKey) {
                        writeableEntry.localId = insertKey;
                    }
                    return writeableEntry as TimeEntry;
                });
                const writable: TimeEntry[] = (await Promise.all(promArray)).map((ent) => ent as TimeEntry);
                await this.root.db.timeEntries.bulkPut(writable);
            } catch (e) {
                throw e;
            }
        }
    }
    
    async getEntry(id: number) {
       if (id < 0) {
         id = id * -1;
       } else {
            return await this.hydrateTimeEntry((await this.root.db.timeEntries.get({id}))!);
       }
       return await this.hydrateTimeEntry((await this.root.db.timeEntries.get(id))!);
    }

    async getEntryByLocalId(entry: TimeEntry) {
        if (entry.localId) {
            return await this.hydrateTimeEntry((await this.root.db.timeEntries.get({localId: entry.localId}))!);
        }
        return await this.hydrateTimeEntry(entry);
    }

    getWriteableId = async (entry: ImmutableBaseEntry): Promise<number | undefined> => {
        if (!entry.id) {
            return undefined;
        }
        if (entry.id < 0) {
            return entry.id * -1;
        }
        const localEntry = (await this.root.db.timeEntries.get({id: entry.id}));
        const returnVal = localEntry ? localEntry.localId : undefined;
        return returnVal;
    }
    trySaveOne = async (entry: ImmutableTimeEntry): Promise<ApiResult<ImmutableTimeEntry>> => {
        try {
            let insertKey = await this.getWriteableId(entry);
            let writeableEntry = entry.toWriteable() as TimeEntry;
            // if (writeableEntry.timeEntryType === TimeEntryType.COLLABORATE) {
            //     writeableEntry.timeEntryType = TimeEntryType.NORMAL;
            // }
            writeableEntry.sapStatus = entry.sapStatus;
            if (insertKey) {
                writeableEntry.localId = insertKey;
            }
            writeableEntry.serverDirty = true;
            let localId = await this.root.db.timeEntries.put(
                writeableEntry
            );

            let safeEntry = (await this.root.db.timeEntries.get(localId))!;

            if (safeEntry.deleted) {
                this.root.TimeCast.dissociateSegmentsFromTimeEntry(safeEntry.id)
            }
            if (writeableEntry.matterId) {
                this.root.Matter.updateLastUsedOnTkMappings(writeableEntry.matterId);
            }
            return {
                status: {
                    failed: false,
                    message: 'Success'
                },
                object: Object.assign(new ImmutableTimeEntry(), safeEntry)
            }

        } catch {
            return {
                status: {
                    failed: true,
                    message: 'Failed save'
                },
                object: entry
            }
        }
    }

    updateEntries = async (entries: ImmutableTimeEntry[]): Promise<ApiResult<ImmutableTimeEntry>[]> => {
        // Try saving the entries
        const ents = await Promise.all(entries.map(this.trySaveOne));
        this.root.Session.write();
        
        // Write if possible
        // let error = false
        // try {
        //     await this.root.Session.write();
        // } catch (e) {
        //     /* do nothing */
        //     error = true
        // }
        //
        // // Re-fetch entries after write (so `id` is updated if it was written successfully)
        // if (!error) {
        //     for (let i = 0; i < ents.length; i++) {
        //         const ent = ents[i];
        //         if (!ent.status.failed && (ent.object as TimeEntry).localId) {
        //             const localTE = await this.root.db.timeEntries.get(
        //             {localId: (ent.object as TimeEntry).localId!});
        //             ent.object = Object.assign(new ImmutableTimeEntry(), localTE);
        //         }
        //     }
        // }
        
        return ents;
    };
    
    registerReciever = (handler: (entries: ImmutableTimeEntry[]) => void) => {
        this.handlers.push(handler);
        const theIndex = this.handlers.length - 1;
        return  () => {
            this.handlers[theIndex] = null;
        }
    }
    getTotalForDateExclusive = async (date: string, excludeIds: number[]): Promise<number> => {
        // TODO: write this 
        let te: ImmutableTimeEntry[] = await this.getEntries(
            DateTime.fromISO(date), 
            DateTime.fromISO(date), 
            this.root.Session.currentTimeKeeper!);
        return te.reduce((prev, cur) => {
                if (cur.id && excludeIds.includes(cur.id) ) {
                    return prev;
                }
                return prev + cur.duration;
        }, 0);
    }
    recieve = async (timeEntries: TimeEntry[]): Promise<void> => {
        const promArray = timeEntries.map(async (te) => {
            if (te.id === undefined && te.localId && te.serverDirty) { // If recieved entries offline
                te.id = te.localId * -1;
                await this.root.db.timeEntries.put(te);
            }
            return this.root.db.timeEntries.get({ id: te.id! })
        });
        let localTes = await Promise.all(promArray);
        timeEntries.forEach((te, idx) => {
            if (localTes[idx]) {
                te.localId = localTes[idx]!.localId;
            }
        })
        await this.root.db.timeEntries.bulkPut(timeEntries);
        let tes = timeEntries.map(te => Object.assign(new ImmutableTimeEntry(), te));
        this.handlers.filter(h => h !== null).forEach(h => h!(tes))
    }
    write = async () => {
        let dirtyEntries = await this.root.db.timeEntries.filter(te => te.serverDirty || false).toArray();
        if ( dirtyEntries.length === 0) {
            return;
        }
        const sessionId = localStorage.getItem('sessionId');
        const emitEntries: ImmutableTimeEntry[] = [];
        const toWrite = dirtyEntries
            .map(te => { 
                let newte = Object.assign(new ImmutableTimeEntry(), te)
                if ( newte.id! < 0) {
                    // work location must be unique to troubleshoot duplicate timeentries
                    newte.workLocation = `${newte.localId}_${sessionId}`
                    newte.id = undefined;
                }
                return newte;
            });
        const results = await this.root
        .webImpl
        .TimeEntry
        .updateEntries(
            toWrite
        );
        let errorMessages: string[] = [];
        // tslint:disable-next-line:no-any
        let proms: Promise<any>[] = [];
        
        for (let i = 0; i < results.length; i++) {
            let curRes = results[i];
            let localEntry = dirtyEntries[i]!;
            const localEntryId = localEntry.localId! * -1;

            if (curRes.status.failed) {
                if (curRes.status.message) {
                    errorMessages.push(curRes.status.message);
                }
                // failed write, do something
            } else {
                (curRes.object as TimeEntry).localId = localEntry.localId;
                proms.push(this.root.db.timeEntries.put(curRes.object ));
                proms.push(
                    this.root.db.timerChunks.where({timeEntryId: localEntryId}).modify({timeEntryId: curRes.object.id})
                )
                // update localIds referenced by TimeCastSegments
                proms.push(
                    this.root.db.timecastSegments
                        .where({associatedTimeEntry: localEntryId})
                        .modify({associatedTimeEntry: curRes.object.id})
                )
                if (localEntry.id! < 0) {
                    localEntry.deleted = true;
                    // id less than 0, only local 
                    emitEntries.push( Object.assign(new ImmutableTimeEntry(), localEntry ) );
                }
                emitEntries.push( Object.assign(new ImmutableTimeEntry(), curRes.object ) )

            }
        }
        await Promise.all(proms);
        if (errorMessages.length > 0) {
            alert(`Server error: ${errorMessages.join()}`)
        }
        this.handlers.filter(h => h !== null).forEach(h => h!(emitEntries))
    }

    getTKHours = async (date: DateTime) => {
        let tkId = this.root.Session.currentTimeKeeper!;
        if (tkId) {
            const Prom = this.root.db.tkHours
                .where({ timeKeeperId: tkId })
                .and((d) => DateTime.fromISO(d.endDate) >= date && DateTime.fromISO(d.startDate) <= date)
                .first();
            return Prom;
        } else {
            return undefined;
        }
    }
    getServerEntries = async (tkId: number) => {
        let from = DateTime.local().minus({ months: 2 }).startOf('month');
        let to = DateTime.local().endOf('month');
        const entries: ImmutableTimeEntry[] = await this.root.webImpl.TimeEntry.getEntries(from, to, tkId);
        const promArray = entries.map(async (entry: ImmutableTimeEntry) => {
            let insertKey = await this.getWriteableId(entry);
            let writeableEntry = entry.toWriteable() as TimeEntry
            if (insertKey) {
                writeableEntry.localId = insertKey;
            }
            return writeableEntry as TimeEntry;
        });
        const writable: TimeEntry[] = (await Promise.all(promArray)).map((ent) => ent as TimeEntry);
        await this.root.db.timeEntries.bulkPut(writable);
    }

    getTimEntriesCount = async (fromDate: DateTime, toDate: DateTime) => {
        let tkId = this.root.Session.currentTimeKeeper!;
        // this only gets called dates doesnt fall under retention dates
        this.preRetentionDateEntries(fromDate, toDate, tkId);
        // Defined finalResult array of day counts
        let finalResult: DayCount[] = []
        await this.root.db.timeEntries.orderBy('workDateTime')
            .filter(d =>
                (DateTime.fromISO(d.workDateTime) >= fromDate && DateTime.fromISO(d.workDateTime) <= toDate) &&
                (d.timeKeeperId === tkId) && (!d.deleted)
            ).eachKey(date => {
                let dateInLoop = DateTime.fromISO(date.toLocaleString()).toISODate()
                // The result with the exact date in the loop
                if (!finalResult.find(el => el.workDate === dateInLoop)) {
                    // if already existing instance of date then add count
                    // finalResult.map(x => {
                    //     if (x.workDate === dateInLoop) {
                    //         x.count = x.count + 1
                    //     }
                    //    
                    //     return x
                    // })
                    finalResult.push({ workDate: dateInLoop, count: 1 })
                }
            });
        return finalResult
    }
    associateSegmentsToEntry = async (entry: ImmutableTimeEntry, chunks: TimerChunk[], tcSegs?: TimeCastSegment[]) => {
        const teApiResult = await this.trySaveOne(entry);
        const { object } = teApiResult;
        let timerChunkApis;
        let tcResult;
        if (chunks.length > 0) {
            const chunksToUpdate = chunks.map(chunk => {
                chunk.timeEntryId = object.id;
                if (object.sapStatus !== SapStatus.UNSUBMITTED) {
                    chunk.submitted = true;
                }
                return chunk;
            });
            timerChunkApis = await this.root.Timer.updateChunks(chunksToUpdate, [], true);
        }
        if (tcSegs) {
            const tcToUpdate = tcSegs.map(seg => {
                seg.associatedTimeEntry = object.id;
                return seg;
            });
            tcResult = await this.root.TimeCast.saveSegments(tcToUpdate);
        }
        let apiResult: AssociateApiResult = {
            TimeEntryApi: teApiResult
        };
        if (timerChunkApis) {
            apiResult.TimerChunkApis = timerChunkApis
        }
        if (tcResult) {
            apiResult.TimeCastApis = tcResult;
        }
        this.root.Session.write();
        return apiResult;
    }
    getNarrativeLength = async (matterId: number) => {
        return this.root.webImpl.TimeEntry.getNarrativeLength(matterId);
    }
}
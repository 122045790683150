import React from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab, IconButton,
    LinearProgress,
    Paper,
    Switch,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import { FlexDiv } from 'common/flex';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import TimeCastSettingsStore from 'store/timecastSettings.store';
import { Refresh, Save, Undo, Add, Delete } from '@material-ui/icons';
import { FabContainer } from '../Home/styled.desktop';
import * as Styled from './styled';
import NotificationTray from '../../images/TimeCast_NotificationTray.png';
import { Platform } from '../../util/Platform';
import { DesktopCaptureIcon } from 'components/TimeCastSegments/DesktopCaptureDescription';
import { TimeCastConnectionStatus } from '../../util/TimeCast';
import { sortByAlphaNumeric } from '../../util/utils';
import { TKConsumer } from 'common/TKProvider';
import { TimeKeeperAssignment } from 'api/types/types';
interface Props {
    rootStore?: RootStore
    store?: TimeCastSettingsStore
}

interface State {
    newURL: string
    // Error shown when URL is invalid. Note:
    //  1) string = error
    //  2) empty string = error
    //  3) null = no error
    newURLErrorMessage: string | null
    isDialogOpen: boolean
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        rootStore: rootStore,
        store: rootStore.timecastSettingsStore
    };
})
@observer
export class TimeCastSettingsContainer extends React.Component<Props, State> {
    state: State = {
        newURL: '',
        newURLErrorMessage: '',
        isDialogOpen: false,
    };

    connectTimeCast = () => {
        this.props.store!.connectToTimeCast();
    };
    
    disconnectTimeCast = () => {
        if (this.props.store!.connected === TimeCastConnectionStatus.DESKTOP) {
            this.props.store!.disconnectDesktopFromTimeCast()
        } else if (this.props.store!.connected === TimeCastConnectionStatus.SERVER) {
            this.props.store!.disconnectServerFromTimeCast()
        }
    }
    
    save = () => {
        this.props.store!.save();
    };

    componentDidMount(): void {
        this.props.store!.init();
    }

    render() {
        const {
            current,
            dirty,
            allPrograms,
            reset,
            update,
            connected,
            updateConnectionState
        } = this.props.store!;

        return (
            <TKConsumer>
            { (tk: TimeKeeperAssignment) =>
            <FlexDiv style={{ height: '100%', overflow: 'auto', position: 'relative' }}>
                {current ? (
                    <div style={{ display: 'flex', flex: 1, flexFlow: 'row nowrap' }}>
                        <div style={{ flex: 1 }}>
                            <Paper style={{ padding: '15px' }}>
                                <FlexDiv style={{ width: '100%' }} direction="column">
                                    <Typography variant="headline">
                                        TimeCast settings
                                    </Typography>
                                    <FlexDiv direction="row">
                                        <TextField
                                            label="System Idle Timer"
                                            value={current.getSystemIdleSeconds()}
                                            type={'number'}
                                            onChange={(e) => {
                                                update(current.setSystemIdleSeconds(+e.target.value));
                                            }}
                                            fullWidth={true}
                                        />
                                        <TextField
                                            label="Minimum Interval"
                                            value={current.getMinimumIntervalSeconds()}
                                            type={'number'}
                                            onChange={(e) => {
                                                update(current.setMinimumIntervalSeconds(+e.target.value));
                                            }}
                                            fullWidth={true}
                                        />
                                        <TextField
                                            label="Merge Interval"
                                            value={current.getMergeIntervalSeconds()}
                                            type={'number'}
                                            onChange={(e) => {
                                                update(current.setMergeIntervalSeconds(+e.target.value));
                                            }}
                                            fullWidth={true}
                                        />
                                    </FlexDiv>
                                    <Styled.OutlinedList>
                                        <div>
                                            <Styled.OutlinedListTitle>Allowed Programs</Styled.OutlinedListTitle>
                                            <Styled.OutlinedListTitle style={{ right: '55px', marginTop: '-26px' }}>
                                                <label style={{paddingRight: 5}}>Select All</label>
                                                <Checkbox
                                                    checked={allPrograms.every(p => !p.deleted &&
                                                        current.getAllowedPrograms().includes(p.code))}
                                                    onChange={(evt, checked) =>
                                                        this.toggleSelectAllPrograms(checked)}
                                                    style={{ padding: 0 }}
                                                />
                                            </Styled.OutlinedListTitle>
                                        </div>
                                        <div>
                                            {allPrograms.length === 0 &&
                                            <FlexDiv style={{ color: '#BCBCBC' }} direction="row">
                                                &lt;none&gt;
                                            </FlexDiv>}
                                            {allPrograms.filter(p => !p.deleted)
                                                .sort((a, b) => sortByAlphaNumeric(a.programName, b.programName))
                                                .map(program => (
                                                <FlexDiv direction="row" style={{ alignItems: 'center' }}>
                                                    <DesktopCaptureIcon
                                                        color={program.color}
                                                        program={program.code}
                                                    /> - {program.programName}
                                                    <FlexDiv flex={1}/>
                                                    <Checkbox
                                                        checked={current.getAllowedPrograms().includes(program.code)}
                                                        onChange={(e, checked) => {
                                                            if (checked) {
                                                                update(current.addAllowedProgram(program.code));
                                                            } else {
                                                                update(current.removeAllowedProgram(program.code));
                                                            }
                                                        }}
                                                    />
                                                </FlexDiv>
                                            ))}
                                        </div>
                                    </Styled.OutlinedList>

                                    <Styled.OutlinedList>
                                        <Styled.OutlinedListTitle>
                                            Allowed Websites
                                        </Styled.OutlinedListTitle>
                                        <Styled.OutlinedListTitle style={{ right: '50px', marginTop: '-26px' }}>
                                            <Tooltip title={'Add URL'}>
                                                <Fab
                                                    style={{
                                                        width: '35px',
                                                        height: '30px'
                                                    }}
                                                    color={'primary'}
                                                    onClick={() => this.setDialogOpen(true)}
                                                >
                                                    <Add />
                                                </Fab>
                                            </Tooltip>
                                        </Styled.OutlinedListTitle>
                                        {current.getAllowedURLs().length === 0 &&
                                            <FlexDiv style={{ color: '#BCBCBC' }} direction="row">
                                                &lt;none&gt;
                                        </FlexDiv>}
                                        {current.getAllowedURLs().map(url => (
                                            <FlexDiv direction="row" style={{ alignItems: 'center' }}>
                                                {url}
                                                <FlexDiv flex={1} />
                                                <IconButton
                                                    onClick={() => {
                                                        update(current.removeAllowedURL(url))
                                                    }}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </FlexDiv>
                                        ))}
                                    </Styled.OutlinedList>
                                </FlexDiv>
                                {Platform.isElectron() && <FlexDiv direction="row" style={{ alignItems: 'center' }}>
                                    Enable Server Push
                                    <FlexDiv flex={1}/>
                                    <Switch
                                        checked={current.isServerPushEnabled()}
                                        onChange={(_event, checked) => {
                                            update(current.setServerPushEnabled(checked));
                                        }}
                                    />
                                </FlexDiv>}
                                <Tooltip title="When this option is enabled, Windows activity will also be recorded when disconnected">
                                    <FlexDiv direction="row" style={{ alignItems: 'center' }}>
                                        Connect automatically after login/Relaunch
                                        <span style={{color: '#ff9800'}}>*</span>
                                        <FlexDiv flex={1}/>
                                        <Switch
                                            checked={current.shouldConnectAutomatically()}
                                            onChange={(_event, checked) => {
                                                update(current.setConnectAutomatically(checked));
                                            }}
                                        />
                                    </FlexDiv>
                                </Tooltip>
                                <FlexDiv
                                    direction="row"
                                    style={{ alignItems: 'center', height: '48px' }}
                                >
                                    Status
                                    <FlexDiv flex={1}/>
                                    <span
                                        onClick={() => updateConnectionState(0)}
                                        style={{ marginRight: '18px', cursor: 'pointer' }}
                                    >
                                        {connected === undefined && <CircularProgress/>}
                                        {connected === TimeCastConnectionStatus.DESKTOP && 
                                            <i style={{ color: '#00CA00' }}>
                                                Connected {Platform.isWeb() ? 'to desktop client' : ''}
                                            </i>
                                        }
                                        {connected === TimeCastConnectionStatus.SERVER && 
                                            <i style={{ color: '#00CA00' }}>
                                                Connected {Platform.isElectron() ? 'to server' : ''}
                                            </i>
                                        }
                                        {connected === TimeCastConnectionStatus.NONE && 
                                            <i style={{ color: '#919191' }}>Disconnected</i>
                                        }
                                    </span>
                                    <IconButton>
                                        <Refresh onClick={() => updateConnectionState(0)}/>
                                    </IconButton>
                                </FlexDiv>
                            </Paper>
                            <FabContainer
                                onScreen={true}
                            >
                                {dirty && (<>
                                    <Fab
                                        onClick={reset}
                                        variant="extended"
                                        color="secondary"
                                        aria-label="Revert"
                                    >
                                        <Undo/>
                                        Revert
                                    </Fab>
                                    <Fab
                                        onClick={this.save}
                                        variant="extended"
                                        aria-label="Save"
                                    >
                                        <Save/>
                                        Save
                                    </Fab>
                                </>)}
                                {(connected === undefined || connected === TimeCastConnectionStatus.NONE) && <Fab
                                    onClick={this.connectTimeCast}
                                    variant="extended"
                                    aria-label="Save"
                                >
                                    Connect
                                </Fab>}
                                {(connected !== undefined && connected !== TimeCastConnectionStatus.NONE) && <Fab
                                    onClick={this.disconnectTimeCast}
                                    variant="extended"
                                    aria-label="Save"
                                >
                                    Disconnect
                                </Fab>}
                            </FabContainer>

                            <Dialog open={this.state.isDialogOpen}>
                                <DialogTitle>Add Allowed URL</DialogTitle>
                                <DialogContent>
                                    <TextField
                                        type={'url'}
                                        value={this.state.newURL}
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                this.submitURL();
                                            }
                                        }}
                                        error={this.canAddURL()}
                                        helperText={this.state.newURLErrorMessage}
                                        onChange={e => this.setNewURL(e.target.value)}
                                        style={{minWidth: '375px'}}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={this.submitURL}
                                        disabled={this.canAddURL()}
                                    >
                                        Add
                                    </Button>
                                    <Button onClick={this.closeURLDialog}>
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </div>
                ) : (
                    <div>
                        <LinearProgress style={{ width: '100%' }}/>
                    </div>
                )}
            </FlexDiv>
            }
            </TKConsumer>
        );
    }

    private setDialogOpen = (b: boolean) => {
        this.setState({
            isDialogOpen: b
        });
    };

    private closeURLDialog = () => {
        this.setDialogOpen(false);
        this.setNewURL('');
        this.setState({ newURLErrorMessage: ''})
    }

    private canAddURL = (): boolean => {
        return this.state.newURLErrorMessage !== null;
    };

    private setNewURL = (url: string) => {
        this.setState({ newURL: url });

        if (!url || url.length === 0) {
            this.setState({ newURLErrorMessage: '' });
        } else if (!url.startsWith('http://') && !url.startsWith('https://')) {
            this.setState({ newURLErrorMessage: `Url must start with 'http://' or 'https://'` });
        } else if (this.props.store!.current.getAllowedURLs().includes(url.trim())) {
            this.setState({ newURLErrorMessage: `Url already exists.` });
        } else {
            this.setState({ newURLErrorMessage: null });
        }
    };

    private submitURL = () => {
        this.props.store!.update(this.props.store!.current.addAllowedURL(this.state.newURL.trim()));
        this.setNewURL('');
        this.setDialogOpen(false);
    };

    private toggleSelectAllPrograms(checked: boolean) {
        const store = this.props.store!;
        if (checked) {
            store.allPrograms.map(prog => store.update(store.current.addAllowedProgram(prog.code)));
        } else {
            store.allPrograms.map(prog => store.update(store.current.removeAllowedProgram(prog.code)));
        }
    }
}
import styled from 'styled-components';
import { IconButton, Typography } from '@material-ui/core';
import { PlayArrow } from '@material-ui/icons';
import { FlexDiv } from 'common/flex';

export const Container = styled.div`
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 5px;
    -webkit-app-region: drag;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 2;
`;

export const ActionButton = styled.div`
    flex: 1;
    text-align: center;
    -webkit-app-region: no-drag;
`;

export const PopOutListItem = styled.div<{selected?: boolean}>`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    background-color: ${props => props.selected && '#ccc'}
`;
export const TimerText = styled(Typography)<{selected?: boolean}>`
    flex: 2;
    color: ${props => props.selected && props.theme.secondary.contrastText};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 155px;
`;
export const DurText = styled(Typography)<{selected?: boolean}>`
    color: ${props => props.selected && 'fff'};
    padding-right: 5px;
`;

export const SearchContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: flex-end;
    margin-bottom: 5px;
    -webkit-app-region: no-drag;
`;

export const PlayBtn = styled(PlayArrow)`
    color: rgba(0, 0, 0, 0.54);
`;

export const TitleBar = styled.div`
    text-align: right;
`;
export const PopOverContainer = styled.div`
    padding: 8px;
    display: flex;
    flex-direction: column;
    -webkit-app-region: no-drag;
`;

export const FrameBtn = styled(IconButton)`
    -webkit-app-region: no-drag;
`;

export const SaveBtn = styled.div`
    text-align: right;
`
export const MainWrapper = styled(FlexDiv)`
    overflow-y: auto;
    -webkit-app-region: no-drag;
`;
export const ListWrapper = styled.div`
    height: 100%;
`;
export const ErrorMessageContainer = styled.div`
    font-size: 14px;
`;
import styled from 'common/styled-components';
import { IconButton, Paper, Typography } from '@material-ui/core';

export const SegmentPaper = styled(Paper)<{ hasEntry: boolean, submitted: boolean }>`
  width: 100%;
  flex-direction: row;
  display: flex;
  padding: 1px;
  border-left: 5px solid ${
    (props) => 
    props.hasEntry ? 
        props.submitted ?  props.theme.summary.green : props.theme.summary.orange : 
        '#9f9f9f'} ;
  align-items: center;
`
export const DateContainer = styled.div`
width: 100px;
 margin-left: 10px;
`
export const TimeContainer = styled.div`
  width: 80px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
`
export const NarrativeContainer = styled.div`
display: flex;
flex: 1;
backgroud: #0F0;
overflow: hidden;
`
export const ActionContainer = styled.div`
  width: 0px;
  white-space: nowrap;
  overflow: hidden;
  height: 30px;
  transition: 0.3s;
  text-align: center;
  ${SegmentPaper}:hover & {
    width: 52px;
  }
  ${SegmentPaper}:focus-within & {
    width: 52px;
  }
`;
export const Control = styled.div`
  margin-left: 8px;
`
export const CompactIconButton = styled(IconButton)`
  padding: 2px !important;
`
export const Popover = styled.div`
  padding: 2px !important;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  width: 100px;
`
export const RunningItemActionContainer = styled.div`
  width: 0px;
  white-space: nowrap;
  overflow: hidden;
  height: 30px;
  transition: 0.3s;
  ${SegmentPaper}:hover & {
    width: 45px;
  }
  ${SegmentPaper}:focus-within & {
    width: 45px;
  }
`;
export const Star = styled.span`
    color: #ff9800;
    padding: 10px;
`
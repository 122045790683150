import * as React from 'react';
import {
    AppBar,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Popover,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Toolbar, Tooltip,
    Checkbox
} from '@material-ui/core';
import {
    CallMerge,
    Check,
    Delete,
    Done, GetApp,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    Loop,
    PictureAsPdf, Print,
    Save,
    Search,
    Send,
    SwapHoriz,
    Today,
    Undo
} from '@material-ui/icons';
import { DateTime } from 'luxon';
import * as Styled from './styled';
import { Client, Features, Matter, MatterTypeText, PdfFormatType, TimeKeeperAssignment } from '../../api/types/types';
import RootAPI from '../../api/interfaces/RootAPI';
import { ApiConsumer } from 'common/ApiProvider';
import AutoCompleteField from 'components/AutoCompleteField/AutoCompleteField';
import TimeEntryStore, { MATTER_TYPE } from 'store/timeentry.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import TimeEntryList from 'components/TimeEntryList/TimeEntryList';
import TimeEntry, { SapStatus } from 'api/immutables/ImmutableTimeEntry';
import { InlineDatePicker } from 'material-ui-pickers';
import { FabContainer } from '../Home/styled.desktop';
import { StandardTextFieldProps } from '@material-ui/core/TextField';
import { FlexDiv } from 'common/flex';
import ImmutableTimeEntry from '../../api/immutables/ImmutableTimeEntry';
import Pagination from '../Pagination/Pagination';

import ExcelIcon from '../../images/excel.png';
import { exportToXLSX, toExcelFormat } from '../../util/ExportToExcel';
import { renderToStaticMarkup } from 'react-dom/server';
import PrintAsTimeEntryPdfExport from './PrintAsTimeEntryPdfExport';
import printJS from 'print-js';
import * as StyledPdf from './styled.pdf';
import { fileNameForExport } from '../../util/utils';
import { buildPdf } from '../../util/SaveAsTimeEntryPDF';
import { Platform } from '../../util/Platform';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { isoDate, getDateFormat } from '../../util/date';
import { FabContainerView } from 'components/FabContainer/FabContainerView';
import TimeKeepersList from 'components/TimeKeepersList/TimeKeepersList';
import { TKConsumer } from 'common/TKProvider';
const { STYLES_FOR_PDF } = StyledPdf;

interface State {
    expanded: boolean,
    selected: boolean,
    popOverPdfEl: HTMLElement | null,
    pdfFormatTypeValue: string
}

interface Props {
    timeEntryStore: TimeEntryStore;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        timeEntryStore: rootStore.timeEntryStore
    };
})

@observer
export default class TimeEntries extends React.Component<Props, State> {
    entriesListRef: React.RefObject<HTMLDivElement>;
    state = {
        expanded: false,
        selected: false,
        popOverPdfEl: null,
        pdfFormatTypeValue: PdfFormatType.DATETYPE
    };
    constructor(props: Props) {
        super(props);
        this.entriesListRef = React.createRef();
    }
    componentDidMount() {
        this.props.timeEntryStore!.setTimeEntryDateRange();
        this.props.timeEntryStore!.setTimeEntryRange(this.props.timeEntryStore!.fromDate,
            this.props.timeEntryStore!.untilDate);
        this.props.timeEntryStore!.getEntries();
        this.props.timeEntryStore!.expandedEntryIds = []
        this.props.timeEntryStore!.rootStore.collaboratees = [];
    }
    componentWillUnmount() {
        this.props.timeEntryStore!.resetTimeEntryStore();
    }
    handleFromDateChange = (d: Date) => {
        const workDate: DateTime = DateTime.local(
            d.getFullYear(),
            d.getMonth() + 1,
            d.getDate(),
            0,
            0,
            0
        );

        let fromDate = workDate;
        let untilDate = this.props.timeEntryStore!.untilDate;
        if (untilDate <= workDate) {
            untilDate = workDate;
        }
        this.props.timeEntryStore!.setTimeEntryRange(fromDate, untilDate);
    };
    handleUntilDateChange = (d: Date) => {
        const workDate: DateTime = DateTime.local(
            d.getFullYear(),
            d.getMonth() + 1,
            d.getDate(),
            0,
            0,
            0
        );

        let fromDate = this.props.timeEntryStore!.fromDate;
        let untilDate = workDate;
        if (fromDate >= untilDate) {
            fromDate = workDate;
        }
        this.props.timeEntryStore!.setTimeEntryRange(fromDate, untilDate);
    };
    handleClientChange = (c: Client | null | undefined) => {
        this.props.timeEntryStore!.setClient(c);
        this.handleMatterChange(null);
    };
    handleMatterChange = (m: Matter | null | undefined) => {
        this.changePage(1);
        this.props.timeEntryStore.selectedEntryIds = [];
        this.props.timeEntryStore!.setMatter(m);
        this.props.timeEntryStore!.setReferenceTE(null);
    };
    handleSearchTextChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.changePage(1);
        this.props.timeEntryStore!.setSearchText(evt.target.value);
    };
    handleStatusChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        this.changePage(1);
        this.props.timeEntryStore!.setStatus(evt.target.value);
    };
    handleMatterTypeChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        this.changePage(1);
        this.props.timeEntryStore!.setMatterType(evt.target.value);
    };
    reset = () => {
        let loc = DateTime.local();
        this.props.timeEntryStore!.setSearchText('');
        this.props.timeEntryStore!.setStatus('All');
        this.props.timeEntryStore!.setMatterType(MATTER_TYPE.All);
        this.props.timeEntryStore!.setTimeEntryDateRange();
        this.props.timeEntryStore!.setClient(null);
        this.handleMatterChange(null);
        this.props.timeEntryStore!.getEntries();
        this.props.timeEntryStore!.expandedEntryIds = []
        // resetting pagination values
        this.props.timeEntryStore!.setPageNum(1);
        this.props.timeEntryStore!.setEntriesPerPage(25);
    };
    search = () => {
        this.props.timeEntryStore!.getEntries();
        this.props.timeEntryStore!.setPageNum(1);
    }
    editEntry = async (t: TimeEntry) => {
        await this.props.timeEntryStore!.loadEntry(t.clone());
    }
    deleteEntry = (t: TimeEntry) => {
        this.props.timeEntryStore!.deleteEntries([t]);
    }
    saveEntry = (t: TimeEntry) => {
        const collabs = this.props.timeEntryStore!.rootStore.collaboratees;
        if (collabs.length > 0) {
            this.props.timeEntryStore!.rootStore.confirmCollaborateDialogStore
                .open()
                .then(() => this.props.timeEntryStore!.saveEntries([t]))
                .catch(() => {
                    return;
                })
        } else {
            this.props.timeEntryStore!.saveEntries([t]);
        }
    }
    postEntry = (t: TimeEntry) => {
        const collabs = this.props.timeEntryStore!.rootStore.collaboratees;
        if (collabs.length > 0) {
            this.props.timeEntryStore!.rootStore.confirmCollaborateDialogStore
                .open()
                .then(() => this._postEntry(t))
                .catch(() => {
                    return;
                })
        } else {
            this._postEntry(t);
        }
        
    }
    _postEntry = async (t: TimeEntry) => {
        let expandedIds = this.props.timeEntryStore!.expandedEntryIds;
        if (!expandedIds.includes(t.id!)) {
            await this.props.timeEntryStore!.determineCodeSets(t.id!);
        }
        this.props.timeEntryStore!.postEntries([t.id!]);
    }
    clearSelectedEntries = () => {
        this.props.timeEntryStore!.setSelectedTimeEntries([]);
        this.props.timeEntryStore!.rootStore.collaboratees = [];
    }
    checkText = (str: string|undefined|null, ifUndefined: string = '') => {
        str = str === null ? ifUndefined : str;
        str = str!.replace(/"/g, '""');
        return str;
    }
    fetchRefs = async (text: string) => {
        const {
            timeEntriesMap,
            serverTimeEntries
        } = this.props.timeEntryStore!;
        
        let ret: TimeEntry[] = [];
        const uniqueRet = new Map();
        
        serverTimeEntries.forEach((t) => {
            uniqueRet.set(t.reference, t);
        })
        uniqueRet.forEach((te, k) => {
            if (k) { ret.push(te); }
        })
        return ret;
    }
    exportToExcel = async () => {
        const ffConfigEnabled = this.props.timeEntryStore!.rootStore.appStore.features.EpochConfigFlatFeeCodesEnabled;
        const actCodeEnabled = this.props.timeEntryStore!.rootStore.appStore.features.EpochConfigActionCodesRequired;
        const currentTimeKeeper = this.props.timeEntryStore!.rootStore.appStore.getActiveTimeKeeper();
        
        const fileName = fileNameForExport(currentTimeKeeper, 'Time Entries Report');
        
        let filteredEntries = this.props.timeEntryStore!.filteredEntries;
        const sortByDateEntries = filteredEntries.sort((e1, e2) => {
            if (DateTime.fromISO(e1.workDateTime) > DateTime.fromISO(e2.workDateTime)) { return 1; }
            if (DateTime.fromISO(e1.workDateTime) < DateTime.fromISO(e2.workDateTime)) { return -1; }
            return 0;
        }) 
        
        const excelEntries = toExcelFormat(
            sortByDateEntries, 
            ffConfigEnabled, 
            actCodeEnabled
        );
        
        exportToXLSX(excelEntries, fileName);
        
    }
    customizeHeadersForPdf = (): Map<string, ImmutableTimeEntry[]> => {
        let timeEntriesMap = new Map<string, ImmutableTimeEntry[]>();

        switch (this.state.pdfFormatTypeValue) {
            case PdfFormatType.CLIENTTYPE:
                timeEntriesMap = this.props.timeEntryStore!.groupEntriesByClient;
                break;
            case PdfFormatType.MATTERTYPE:
                timeEntriesMap = this.props.timeEntryStore!.groupEntriesByMatter;
                break;
            case PdfFormatType.DATETYPE:
                [...this.props.timeEntryStore!.timeEntriesMapForPDF.entries()].sort()
                    .forEach(([date, tentries]) => {
                        let d = DateTime.fromISO(date).toFormat('DDDD');
                        timeEntriesMap.set(d, tentries)
                    });
                break;
            default:
                break;
        }
        
        return timeEntriesMap;
    }
    printPDF = () => {
        const currentTimeKeeper = this.props.timeEntryStore!.rootStore.appStore.getActiveTimeKeeper();
        const fileName = fileNameForExport(currentTimeKeeper, 'Time Entries Report')

        const ffConfigEnabled = this.props.timeEntryStore!.rootStore.appStore.features.EpochConfigFlatFeeCodesEnabled;
        const actionEnabled = this.props.timeEntryStore!.rootStore.appStore.features.EpochConfigActionCodesRequired;
        let timeEntriesMap = this.customizeHeadersForPdf();

        let html = renderToStaticMarkup(
            <PrintAsTimeEntryPdfExport
                timeEntriesMap={timeEntriesMap}
                ffConfigEnabled={ffConfigEnabled}
                actionEnabled={actionEnabled}
                exportType={this.state.pdfFormatTypeValue}
                timeKeeper={currentTimeKeeper ? currentTimeKeeper.name : ''}
            />);
        
        let pdfDiv = new DOMParser().parseFromString(html, 'text/html');
        let pdfContainer = document.createElement('div');
        
        if (pdfDiv.body.firstChild) {
            pdfContainer.appendChild(pdfDiv.body.firstChild);
            pdfContainer.setAttribute('style', 'visibility: hidden')
            document.body.appendChild(pdfContainer);
            printJS({
                printable: 'printjs-form',
                type: 'html',
                documentTitle: 'Time Entries report',
                style: STYLES_FOR_PDF,
                onLoadingStart: () => { document.title = fileName; },
                onPrintDialogClose: () => { document.title = 'Epoch'; },
                honorColor: true,
                honorMarginPadding: true
            });
            
            document.body.removeChild(pdfContainer);
        }
        
        this.handleClosePdfPopOver();
    }
    saveAsPDF = async () => {
        const currentTimeKeeper = this.props.timeEntryStore!.rootStore.appStore.getActiveTimeKeeper();
        const timeEntriesMap = this.customizeHeadersForPdf();
        const ffConfigEnabled = this.props.timeEntryStore!.rootStore.appStore.features.EpochConfigFlatFeeCodesEnabled;
        const actCodeEnabled = this.props.timeEntryStore!.rootStore.appStore.features.EpochConfigActionCodesRequired;
        const fileName = fileNameForExport(currentTimeKeeper, 'Time Entries Report');

        let doc = buildPdf(timeEntriesMap, ffConfigEnabled, actCodeEnabled,
             this.state.pdfFormatTypeValue, currentTimeKeeper ? currentTimeKeeper.name : '');
        
        if (Platform.isElectron()) {
            let pdfString = doc.output('datauristring')
            const {dialog} = require('electron').remote;
            const fs = require('fs-jetpack');
            let dataUriToBuffer = require('data-uri-to-buffer');
            const path = require('path');
            const bufferData = dataUriToBuffer(pdfString);

            dialog.showSaveDialog({
                    defaultPath: fileName,
                    filters: [
                        {
                            name: 'pdf',
                            extensions: ['pdf']
                        }
                    ]
                }, (file: string) => {
                    if (file === undefined) {
                        return;
                    }
                    fs.write(
                        path.normalize(file),
                        bufferData
                    )
                }
            )
        } else {
            doc.save(fileName);
        }
        
        this.handleClosePdfPopOver();
    }
    openPdfPopOver = async (evt: React.MouseEvent<HTMLElement>) => {
        this.setState({
            popOverPdfEl: evt.currentTarget
        })
    }
    handleClosePdfPopOver = () => {
        this.setState({
            popOverPdfEl: null
        })
    }
    handlePdfFormatTypeChange = (event: React.ChangeEvent<HTMLFormElement>, val: string) => {
        this.setState({
            pdfFormatTypeValue: val
        })
    }
    changePage = (pageNum: number) => {
        this.props.timeEntryStore!.setPageNum(pageNum);
        this.entriesListRef.current!.scrollTop = 0;
    }
    changeEntriesPerPage = (n: number) => {
        this.props.timeEntryStore.setEntriesPerPage(n);
        this.changePage(1);
    }
    fetchMatters = (api: RootAPI) => async (searchText: string, features: Features, clientId: number | undefined) => {
        let  results: Matter[] = []
        if (features.EpochConfigTrackedMatterClientsEnabled) { 
            return await api.Matter.searchMatters(
                searchText, true, clientId!,
            );
        } else {
            results = await api.Matter.searchMatters(
                searchText, true, clientId!
            );
            if (results.length === 0) {
                results = await api.Matter.getAvailableMatters(
                    searchText,
                    false,
                    clientId!
                )
            }
        }
        return results;
    }

    render() {
        const {
            timeEntriesMap,
            fromDate,
            untilDate,
            status,
            matterType,
            selectedEntryIds,
            expandedEntryIds,
            searchText,
            client,
            matter,
            loading,
            noEntries,
            validationState,
            referenceTE,
            setReferenceTE,
            durVstate,
            allSelectedEntries,
            toggleSelectAllFlag,
            copyTimeEntry,
            selectedIdsForCollab,
            filteredEntries,
            currentPage,
            entriesPerPage,
            workDateNonCollaborated
        } = this.props.timeEntryStore!;
        const collaboratees = this.props.timeEntryStore!.rootStore.collaboratees.length;
        let fieldSize: 3 | 4 = (matter && matter.id === -1) ? 3 : 4;
        const { popOverPdfEl, pdfFormatTypeValue } = this.state;
        const openedPdfEl = Boolean(popOverPdfEl);
        const { getAllTimeKeepers, setTkSearchText, filteredAllTimekeepersList, online } = this.props.timeEntryStore!.rootStore.appStore;
        
        return (
            <FeaturesConsumer>
            { (features: Features) =>
            <ApiConsumer>
                {(api: RootAPI) =>
                    <>
                        <AppBar position={'static'}>
                            <Toolbar variant={'regular'}>
                                <Grid container={true} xs={12}>
                                    <Styled.StyledGrid
                                        container={true}
                                        xs={12}
                                        spacing={8}
                                    >
                                        <Grid item={true} xs={2}>
                                            <TextField
                                                id={'input-with-icon-textfield'}
                                                label={'Search'}
                                                placeholder={'Enter Search Text'}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position={'start'}>
                                                            <Search/>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                value={searchText}
                                                fullWidth={true}
                                                onChange={this.handleSearchTextChange}
                                            />
                                        </Grid>
                                        <Grid item={true} xs={2}>
                                            <InlineDatePicker
                                                format={fromDate.toFormat(getDateFormat())}
                                                value={isoDate(fromDate)}
                                                label={'From'}
                                                onChange={this.handleFromDateChange}
                                                leftArrowIcon={<KeyboardArrowLeft/>}
                                                rightArrowIcon={<KeyboardArrowRight/>}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position={'end'}>
                                                            <Today/>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                TextFieldComponent={
                                                    (props: StandardTextFieldProps) => 
                                                        <TextField {...props} fullWidth={true}/>
                                                }
                                            />
                                        </Grid>
                                        <Grid item={true} xs={2}>
                                            <InlineDatePicker
                                                format={untilDate.toFormat(getDateFormat())}
                                                value={isoDate(untilDate)}
                                                label={'Until'}
                                                onChange={this.handleUntilDateChange}
                                                leftArrowIcon={<KeyboardArrowLeft/>}
                                                rightArrowIcon={<KeyboardArrowRight/>}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position={'end'}>
                                                            <Today/>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                TextFieldComponent={
                                                    (props: StandardTextFieldProps) =>
                                                        <TextField {...props} fullWidth={true}/>
                                                }
                                            />
                                        </Grid>
                                        <Grid item={true}>
                                            <FlexDiv style={{marginTop: 7}}>
                                                <IconButton title={'Reset'} onClick={this.reset}>
                                                    <Loop />
                                                </IconButton>
                                                <IconButton title={'Apply Date Range'} onClick={this.search}>
                                                    <Done />
                                                </IconButton>
                                                <IconButton
                                                    key={'csv'}
                                                    onClick={this.exportToExcel}
                                                    title={'Download Excel'}
                                                    disabled={noEntries}
                                                >
                                                    <img src={ExcelIcon}/>
                                                </IconButton>
                                                <IconButton
                                                    key={'pdf'}
                                                    onClick={this.openPdfPopOver}
                                                    title={'Print PDF'}
                                                    disabled={noEntries}
                                                >
                                                    <PictureAsPdf/>
                                                </IconButton>
                                                <Popover
                                                    id="download-pdf-pop"
                                                    open={openedPdfEl}
                                                    anchorEl={popOverPdfEl}
                                                    onClose={this.handleClosePdfPopOver}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <div>
                                                        <FormControl style={{width: '140px', padding: '10px'}}>
                                                            <FormLabel>Group By:</FormLabel>
                                                            <RadioGroup
                                                                aria-label={'Group by'}
                                                                name={'groupBy'}
                                                                value={pdfFormatTypeValue}
                                                                onChange={this.handlePdfFormatTypeChange}
                                                            >
                                                                {Object.keys(PdfFormatType).map((key) => (
                                                                    <FormControlLabel
                                                                        key={key}
                                                                        control={
                                                                            <Radio/>
                                                                        } 
                                                                        label={PdfFormatType[key]}
                                                                        value={PdfFormatType[key]}
                                                                    />
                                                                ))}
                                                            </RadioGroup>
                                                            <Styled.PrintIcons>
                                                                <Tooltip title={'Print'}>
                                                                    <IconButton
                                                                        onClick={this.printPDF}
                                                                        aria-label="Print"
                                                                    >
                                                                        <Print/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title={'Download PDF'}>
                                                                    <IconButton
                                                                        onClick={this.saveAsPDF}
                                                                        aria-label="Download PDF"
                                                                    >
                                                                        <GetApp/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Styled.PrintIcons>
                                                        </FormControl>
                                                    </div>
                                                </Popover>
                                            </FlexDiv>
                                        </Grid>
                                        <TKConsumer>
                                            { (tk: TimeKeeperAssignment) => 
                                        <Grid item={true} xs={2}>
                                            {matter && matter.id === -1 ?
                                                <FlexDiv
                                                    fillContainer={true}
                                                    style={{ marginTop: 5, alignItems: 'center' }}
                                                >
                                                    <Tooltip title={'Select All'}>
                                                        <Checkbox
                                                            checked={allSelectedEntries}
                                                            onChange={toggleSelectAllFlag}
                                                            style={{ padding: 0 }}
                                                            disabled={!tk.writable}
                                                        />
                                                    </Tooltip>
                                                    &nbsp;
                                                    { tk.writable &&
                                                    <span> Select All </span>
                                                    }
                                                </FlexDiv>
                                                :
                                                null
                                            }
                                        </Grid>
                                        }
                                        </TKConsumer>
                                    </Styled.StyledGrid>
                                    <Styled.StyledGrid
                                        container={true}
                                        xs={12}
                                        spacing={8}
                                    >
                                        <Grid item={true} xs={2}>
                                            <FormControl
                                                fullWidth={true}
                                            >
                                                <InputLabel shrink={true}>
                                                    Status
                                                </InputLabel>
                                                <Select
                                                    input={<Input name={'status'} id={'status-label-placeholder'}/>}
                                                    displayEmpty={true}
                                                    name={'select-status'}
                                                    value={status}
                                                    onChange={this.handleStatusChange}
                                                >
                                                    <MenuItem value={'All'}>All</MenuItem>
                                                    <MenuItem value={SapStatus.POSTED}>Posted</MenuItem>
                                                    <MenuItem value={SapStatus.UNSUBMITTED}>Draft</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item={true} xs={2}>
                                            <FormControl
                                                fullWidth={true}
                                            >
                                                <InputLabel shrink={true}>
                                                    Matter Type
                                                </InputLabel>
                                                <Select
                                                    input={<Input name={'matter'} id={'matter-label-placeholder'}/>}
                                                    displayEmpty={true}
                                                    name={'select-matter-type'}
                                                    value={matterType}
                                                    onChange={this.handleMatterTypeChange}
                                                >
                                                    {Object.keys(MATTER_TYPE).map((key) => (
                                                        <MenuItem
                                                            value={key}
                                                        >
                                                            {MATTER_TYPE[key]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item={true} xs={3} lg={fieldSize} md={fieldSize}>
                                            <AutoCompleteField
                                                label={'Client'}
                                                fetch={api.Client.searchClients}
                                                currentItem={client}
                                                getItemText={(c: Client) => `${c.number} - ${c.name}`}
                                                clearable={true}
                                                onSelect={this.handleClientChange}
                                                onClear={() => this.handleClientChange(null)}
                                            />
                                        </Grid>
                                        <Grid item={true} xs={3} lg={fieldSize} md={fieldSize}>
                                            <AutoCompleteField
                                                label={'Matter'}
                                                fetch={(text: string) =>
                                                    this.fetchMatters(api)(text, features,
                                                         client ? client.id : undefined)
                                                        .then((res) => {
                                                            let blank: Matter = {
                                                                id: -1,
                                                                number: '',
                                                                name: 'Blank',
                                                                description: '',
                                                                clientId: -1,
                                                                isPhaseCode: false,
                                                                isActCode: false,
                                                                isFfTaskCode: false,
                                                                clientName: '',
                                                                clientNumber: '',
                                                                status: '',
                                                                language: '',
                                                                languageText: '',
                                                                timeEntryUnit: '',
                                                                entryType: '',
                                                                type: '',
                                                                typeText: MatterTypeText.NON_BILLABLE,
                                                                statusDescription: '',
                                                                lastModified: '',
                                                                startDate: '',
                                                                endDate: '',
                                                                bannedWords: [],
                                                                blockBillingWords: [],
                                                            };
                                                            if (!client && !text) {
                                                                res.unshift(blank)
                                                            }
                                                            return res;
                                                    })}
                                                currentItem={matter}
                                                getItemText={(m: Matter) => {
                                                    if (m.id === -1) {
                                                        return m.name;
                                                    }
                                                    return `${m.number} - ${m.name}`;
                                                }}
                                                clearable={true}
                                                onSelect={this.handleMatterChange}
                                                onClear={() => this.handleMatterChange(null)}
                                                tooltip={(m: Matter) => m.description}
                                            />
                                        </Grid>
                                        { matter && matter.id === -1 &&
                                            <Grid item={true} xs={2} lg={2} md={2}>
                                                <AutoCompleteField
                                                    label={'Reference'}
                                                    fetch={this.fetchRefs}
                                                    currentItem={referenceTE}
                                                    getItemText={(t: TimeEntry) => t.reference!} 
                                                    clearable={true}
                                                    onSelect={setReferenceTE}
                                                    onClear={() => setReferenceTE(null)}
                                                />
                                            </Grid>
                                        }
                                    </Styled.StyledGrid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                        <Pagination
                            entriesPerPage={entriesPerPage}
                            currentPage={currentPage}
                            entriesLength={filteredEntries.length}
                            changeEntriesPerPage={this.changeEntriesPerPage}
                            onPageChange={this.changePage}
                        />
                        <Styled.TimeEntryList innerRef={this.entriesListRef}>
                            {[...timeEntriesMap].map(([date, entries]) => (
                                <TimeEntryList
                                    entries={entries}
                                    validationMap={validationState}
                                    durValidationMap={durVstate}
                                    date={DateTime.fromISO(date)}
                                    onChange={this.props.timeEntryStore!.changeEntry}
                                    selected={selectedEntryIds}
                                    onSelect={this.props.timeEntryStore!.setSelectedTimeEntries}
                                    expanded={expandedEntryIds}
                                    onExpand={this.props.timeEntryStore!.setExpandedTimeEntries}
                                    onCreateEntry={this.props.timeEntryStore!.openNewTimeEntryDialog}
                                    onPost={this.postEntry}
                                    onSave={this.saveEntry}
                                    onDelete={this.deleteEntry}
                                    onCopy={copyTimeEntry}
                                    onEdit={this.editEntry}
                                    onCancel={this.props.timeEntryStore!.revertEntry}
                                    onSplit={this.props.timeEntryStore!.splitEntry}
                                    onMerge={this.props.timeEntryStore!.mergeEntries}
                                    onTransfer={this.props.timeEntryStore!.transferEntries}
                                    buildCodeSets={this.props.timeEntryStore!.determineCodeSets}
                                    aggregateTotals={this.props.timeEntryStore!.getAggregateTotalsFor}
                                />
                            ))}
                            {loading && <Styled.Loading>
                                <div style={{top: '-15%', position: 'relative'}}>
                                    <CircularProgress size={100} />
                                </div>
                            </Styled.Loading>}
                            {noEntries && <Styled.NoEntry>No matching entries found.</Styled.NoEntry>}
                        </Styled.TimeEntryList>
                        <FabContainerView
                            dirty={this.props.timeEntryStore!.dirty}
                            saveDirtyEntries={this.props.timeEntryStore!.saveDirtyEntries}
                            clearSelectedEntries={this.clearSelectedEntries}
                            postSelectedEntries={this.props.timeEntryStore!.postSelectedEntries}
                            moveSelectedEntries={this.props.timeEntryStore!.moveSelectedEntries}
                            mergeEntries={this.props.timeEntryStore!.mergeEntries}
                            transferEntries={this.props.timeEntryStore!.transferEntries}
                            deleteSelectedEntries={this.props.timeEntryStore!.deleteSelectedEntries}
                            selectedEntryIds={selectedEntryIds}
                            online={online}
                            getAllTimeKeepers={getAllTimeKeepers}
                            filteredAllTimekeepersList={filteredAllTimekeepersList}
                            selectedIdsForCollab={selectedIdsForCollab}
                            workDate={workDateNonCollaborated}
                            setTkSearchText={setTkSearchText}
                        />
                    </>}
            </ApiConsumer>
            }
            </FeaturesConsumer>
        );
    }
}
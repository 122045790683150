// tslint:disable:max-line-length
import React, { ReactNode } from 'react'
import { Checkbox, Paper, Tooltip } from '@material-ui/core'
import * as Styled from './styled.desktop';
import { TimeCastSegmentGap, TimeCastSegmentGroup } from 'store/home.store';
import {
    CalendarEventData, ConversationHistoryData, DeprecatedDesktopCaptureData,
    DesktopCaptureData,
    PhoneCallData,
    SentMailData, TimeCastProgram,
    TimeCastSegment, TimerChunk
} from '../../api/types/types';
import { PhoneCallDescription } from 'components/TimeCastSegments/PhoneCallDescription'
import { CalendarEventDescription } from 'components/TimeCastSegments/CalendarEventDescription'
import { DesktopCaptureDescription } from 'components/TimeCastSegments/DesktopCaptureDescription';
import { SentMailDescription } from 'components/TimeCastSegments/SentMailDescription';
import { ConversationHistoryDescription } from '../../components/TimeCastSegments/ConversationHistoryDescription';
import { getDurStringBetween } from 'components/TimerChunkItem/TimerChunkItem';
import { ViewHeadline } from '@material-ui/icons';
import { TimeKeeperAssignment } from '../../api/types/types';
import { TKConsumer } from 'common/TKProvider';
export interface MergedTimeCastSegmentProps {
    groupOrGap?: TimeCastSegmentGroup | TimeCastSegmentGap
    oneLine?: boolean
    toggleSelection: () => void
    isSelected: boolean
    handleDoubleClick?: () => void
    getProgramColor?: (code: string) => string
    timerSegment?: TimerChunk
    timeEntryOpen?: (chunk: TimerChunk) => void;
}

const segmentTimeFormat = { hour: '2-digit', minute: '2-digit' };

export class MergedTimeCastSegmentComponent extends React.Component<MergedTimeCastSegmentProps> {
    renderGap = () => {
        const gap = this.props.groupOrGap as TimeCastSegmentGap;

        const {
            oneLine,
            isSelected
        } = this.props;

        return (
            <TKConsumer>
            {(tk: TimeKeeperAssignment) =>
            <Styled.Gap oneLine={!!oneLine} writable={!tk.writable} onClick={this.props.toggleSelection}>
                <Styled.Segment gap={true} oneLine={oneLine}>
                    <Styled.SegmentCheckbox
                        disabled={!tk.writable}
                        checked={isSelected}
                        // onChange={this.props.toggleSelection}
                    />
                    <Styled.SegmentContent oneLine={oneLine}>
                        <Styled.SegmentTime>
                            {gap.startTime.toLocaleString(segmentTimeFormat)}
                            —
                            {gap.endTime.toLocaleString(segmentTimeFormat)}
                        </Styled.SegmentTime>
                        <Styled.SegmentTitle gap={true}>
                            No Captured Data
                        </Styled.SegmentTitle>
                    </Styled.SegmentContent>
                </Styled.Segment>
            </Styled.Gap>
            }
            </TKConsumer>
        );
    };

    renderGroup = () => {
        const { oneLine, isSelected, handleDoubleClick, getProgramColor } = this.props;
        const group = this.props.groupOrGap as TimeCastSegmentGroup;
        
        let isConversationHistory = false;
        let isSentEmail = false;
        let isPhoneCall = false;
        let isCalendarEvent = false;
        let isAllDay = false;
        let isDesktopCapture = false;
        let calendarEventResponse = undefined;
        let tooltipTitle = '';
        let tooltipEntries = new Set<ReactNode>();

        if (group.type === 'CALENDAR_EVENT' || group.type === 'VIRTUAL_CALENDAR_EVENT') {
            isCalendarEvent = true;
            
            const groupSeconds = group.endTime.diff(group.startTime, 'seconds').get('seconds')
            isAllDay = groupSeconds >= 86400; /* seconds in a day */

            tooltipTitle = 'Calendar events'
        }
        
        // Generate tooltip message
        group.segments.forEach((seg: TimeCastSegment) => {
            if (seg.type === 'DESKTOP_CAPTURE') {
                tooltipTitle = 'Windows';
                isDesktopCapture = true;
                const tooltip = (seg.data as DesktopCaptureData).title
                             || (seg.data as DeprecatedDesktopCaptureData).windowTitle;
                tooltipEntries.add(tooltip);
            } else if (seg.type === 'VIRTUAL_CALENDAR_EVENT' || seg.type === 'CALENDAR_EVENT') {
                const data = seg.data as CalendarEventData;
                calendarEventResponse = data.myMeetingResponse;
                tooltipEntries.add(data.subject);
            } else if (seg.type === 'PHONE_CALL' || seg.type === 'VIRTUAL_PHONE_CALL') {
                tooltipTitle = 'Phone calls'
                const data = seg.data as PhoneCallData;
                isPhoneCall = true;
                if (data.otherParty) {
                    tooltipEntries.add((
                        <>
                            <div>
                                {data.initiator ? `Call to ${data.toNumber}` : `Call from ${data.fromNumber}`}
                                &nbsp;
                                ({data.otherParty.fullName})
                            </div>
                            <div>{data.otherParty.email}</div>
                        </>
                    ))
                } else {
                    tooltipEntries.add(data.initiator ? `Call to ${data.toNumber}` : `Call from ${data.fromNumber}`)
                }
            } else if (seg.type === 'SENT_EMAIL' || seg.type === 'VIRTUAL_SENT_EMAIL') {
                isSentEmail = true;
                tooltipTitle = 'Recipients'
                const data = seg.data as SentMailData;
                // add all recipients as a tooltip line
                (data.toRecipients || ['Unknown recipient(s)']).forEach(r => tooltipEntries.add(r))
            } else if (seg.type === 'CONVERSATION_HISTORY') {
                isConversationHistory = true;
                tooltipTitle = (seg.data as ConversationHistoryData).subject;
            }
        });
        
        return (
            <TKConsumer>
            { (tk: TimeKeeperAssignment) =>
            <Styled.Group oneLine={!!oneLine} writable={!tk.writable} onClick={this.props.toggleSelection} onDoubleClick={handleDoubleClick}>
                {/*<Styled.Divider/>*/}
                <Styled.Segment>
                    {group.segments.every(segment => !!segment.associatedTimeEntry) ? (
                        <Tooltip title={'All segments have been assigned to a time entry.'}>
                            <Styled.SegmentCheckbox
                                checked={true}
                                disabled={true}
                            />
                        </Tooltip>
                    ) : (
                        <Styled.SegmentCheckbox
                            disabled={!tk.writable}
                            checked={isSelected}
                            // onChange={this.props.toggleSelection}
                            indeterminate={group.segments.some(segment => !!segment.associatedTimeEntry)}
                        />
                    )}
                    <Tooltip
                        title={(
                            <div>
                                <u>{tooltipTitle}</u><br/>
                                {[...tooltipEntries].map((windowTitle, index, segArray) =>
                                    <>
                                        {windowTitle}
                                        {index !== segArray.length - 1 && <br/>}
                                    </>
                                )}
                            </div>
                        )}
                    >
                        <Styled.SegmentContent oneLine={oneLine}>
                            <Styled.SegmentTime>
                                {isAllDay 
                                    ? 'All day'
                                    : isSentEmail
                                    ? (
                                        <>
                                            Sent at&nbsp;{(new Date(group.startTime.toISO())).toLocaleString('local', segmentTimeFormat)}
                                        </>
                                      ) 
                                    : (
                                        <>
                                            {(new Date(group.startTime.toISO())).toLocaleString('local', segmentTimeFormat)}
                                            —
                                            {(new Date(group.endTime.toISO())).toLocaleString('local', segmentTimeFormat)}
                                        </>
                                    )}
                            </Styled.SegmentTime>
                            <Styled.SegmentTitle>
                                {isPhoneCall &&
                                    <PhoneCallDescription data={(group.segments[0].data as PhoneCallData)} />
                                }
                                {isCalendarEvent &&
                                    <CalendarEventDescription data={(group.segments[0].data as CalendarEventData)}/>
                                }
                                {isDesktopCapture &&
                                    <DesktopCaptureDescription group={group} color={getProgramColor && getProgramColor(group.application)} />
                                }
                                {isSentEmail &&
                                    <SentMailDescription data={group.segments[0].data as SentMailData} />
                                }
                                {isConversationHistory &&
                                    <ConversationHistoryDescription data={group.segments[0].data as ConversationHistoryData}/>
                                }
                            </Styled.SegmentTitle>
                        </Styled.SegmentContent>
                    </Tooltip>
                </Styled.Segment>
            </Styled.Group>
            }
            </TKConsumer>
        );
    };
    
    renderTimerSegment = () => {
        const { timerSegment, isSelected, timeEntryOpen } = this.props;

        return (
            <TKConsumer>
                {(tk: TimeKeeperAssignment) =>
            <Styled.Group oneLine={true} writable={!tk.writable}>
                <Styled.Segment>
                    {timerSegment && timerSegment.timeEntryId ?
                    <Styled.SegmentViewHeadLine>
                        <ViewHeadline
                            onClick={() => timeEntryOpen ? timeEntryOpen(timerSegment) : undefined}
                            style={{
                                color: timerSegment.submitted ? '#4bb361' : '#ff9800'
                            }}
                        />
                    </Styled.SegmentViewHeadLine>
                    :
                    <Styled.SegmentCheckbox
                        disabled={!tk.writable}
                        checked={isSelected}
                        indeterminate={timerSegment ? !!timerSegment.timeEntryId : false}
                        onClick={this.props.toggleSelection}
                    />
                    }
                    <Styled.SegmentContent 
                        oneLine={true} 
                        onClick={timerSegment && timerSegment.timeEntryId ? undefined : this.props.toggleSelection}
                    >
                        <Styled.SegmentTime>
                            {timerSegment && (new Date(timerSegment.startTime)).toLocaleString('local', segmentTimeFormat)}
                            —
                            {timerSegment && (new Date(timerSegment.endTime)).toLocaleString('local', segmentTimeFormat)}
                        </Styled.SegmentTime>
                        <Styled.SegmentTitle>
                            {timerSegment && getDurStringBetween(new Date(timerSegment.startTime), new Date(timerSegment.endTime))}
                        </Styled.SegmentTitle>
                    </Styled.SegmentContent>
                </Styled.Segment>
            </Styled.Group>}
            </TKConsumer>
        )
    }

    render() {
        const { groupOrGap } = this.props;
        if (groupOrGap) {
            return groupOrGap.gap ? this.renderGap() : this.renderGroup();
        } else {
            return this.renderTimerSegment();
        }
    }
}
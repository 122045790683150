export default class EntityCache<T, U> {
    cache = new Map<U, T>();
    inFlightMap = new Map<U, Promise<T>>();
    fetchFunction: (identifier: U) => Promise<T>;
    constructor( fetch: (identifier: U) => Promise<T>) {
        this.fetchFunction = fetch;
    }
    get = async (identifier: U) => {
        if (this.cache.get(identifier)) {
            this.fetchFunction(identifier).then((value: T) => {
                this.cache.set(identifier, value);
                return value;
            });
            return this.cache.get(identifier)!;
        } 
        if (this.inFlightMap.get(identifier)) {
            let existingProm = this.inFlightMap.get(identifier)!.then((val: T) => {
                return val;
            });

            this.inFlightMap.set(identifier, existingProm)
            return existingProm;
        }
        let prom =  this.fetchFunction(identifier).then((value: T) => {
            this.cache.set(identifier, value);
            this.inFlightMap.delete(identifier);
            return value;
        });
        this.inFlightMap.set(identifier, prom);
        return prom;

        // return from cache if present, otherwise execute fetch and return value / update cache
    }
}
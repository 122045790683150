import { action, observable } from 'mobx';
import { Narrative } from '../api/types/types';
import { onOpen, onSave } from '@fulcrumgt/mobx-store-utils';
import { DialogRootStore } from 'store/dialog.root.store';
import { sortByAlphaNumeric } from '../util/utils';

export default class NarrativeCodesDialogStore extends DialogRootStore {
    @observable selectedNarrative: Narrative;
    @observable narratives: Narrative[] = [];
    @observable narrative: Narrative;
    
    @action setNarrative(narrative: Narrative) {
        this.selectedNarrative = narrative;
    }
    
    @onOpen()
    getNarratives() {
        this.rootStore.api.Narrative.getAllNarratives().then((narrativeCodes) => {
            // Local Narratives must always be on top
            const localNarratives = (narrativeCodes.filter(n => !n.global))
                .sort((alpha, beta) => sortByAlphaNumeric(alpha.key, beta.key));
            const globalNarratives = (narrativeCodes.filter(n => n.global))
                .sort((alpha, beta) => sortByAlphaNumeric(alpha.key, beta.key));
            
            this.narratives = [...localNarratives, ...globalNarratives];
        })
    };
    @action setNarratives(narratives: Narrative[]) {
        this.narratives = narratives;
    }
    
    selectNarrative(narrative: Narrative) {
        this.narrative = narrative;
    }
    
    @onSave()
    saveNarrative(narrative: Narrative) {
        return this.narrative;
    }
}
import { DialogRootStore } from 'store/dialog.root.store';
import { onOpen, onSave } from '@fulcrumgt/mobx-store-utils';
import { action, computed, observable } from 'mobx';
import ImmutableTimer from '../api/immutables/ImmutableTimer';

export default class NarrativeDialogStore extends DialogRootStore {
    @observable timerNote: string = '';
    @observable justOpened: boolean = false;
    
    setJustOpened = (val: boolean) => {
        this.justOpened = val;
    }
    
    @action.bound setNote(timerNote: string) {
        this.timerNote = timerNote;
    }
    @onOpen() @action.bound showDialog() {
        this.timerNote = this.rootStore.timerStore.activeTimer.notes!;
        this.setJustOpened(true);
    }
    doSave = async () => {
        await this.rootStore.timerStore.saveTimerNote(this.timerNote);
        // @ts-ignore
        this.resolveAndClose(this.timerNote as object)
    }
 }

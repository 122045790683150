import React, { Component } from 'react';
import * as Styled from 'components/OfflineEntries/styled';
import { Tooltip } from '@material-ui/core';
import { HeaderItem } from 'components/TimeEntryPanel/styled';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { MenuProvider } from 'react-contexify';
import ImmutableTimer from '../../api/immutables/ImmutableTimer';
import TimerPanelItemStore from 'store/Timer/timerPanelItem.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import { parseCode } from '../../util/utils';
import { TimerChunk } from '../../api/types/types';
import { getDurStringBetween } from 'components/TimerChunkItem/TimerChunkItem';
import * as ReactDOM from 'react-dom';
import scrollIntoView from 'scroll-into-view-if-needed';
import TimerStore from 'store/Timer/timer.store';

interface PanelProps {
    timer: ImmutableTimer;
    timerChunks: TimerChunk[] | undefined;
    timerPanelItemStore?: TimerPanelItemStore;
    timerStore?: TimerStore;
}
interface TimerState {
    // tslint:disable-next-line:no-any
    interval?: any;
    durTxt: string;
}
@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        timerPanelItemStore: rootStore.timerPanelItemStore,
        timerStore: rootStore.timerStore
    }
})

@observer
class TimerPanelItem extends Component<PanelProps, TimerState> {
    constructor(props: PanelProps) {
        super(props);
        this.state = {
            durTxt: this.getTimerDurationTxt(),
            interval: props.timer.active ? setInterval(
                this.timerTick,
                250) : undefined
        }
    }
    
    async componentWillMount() {
        const tempId = this.props.timer.templateId;
        const matterId = this.props.timer.matterId;
        if (tempId) {
            await this.props.timerPanelItemStore!.setTimerTemplate(tempId);
        }
        if (matterId) {
            await this.props.timerPanelItemStore!.setTimerMatter(matterId);
        }
    }
    
    expandTimerPanel = (id: number | undefined) => {
        if (id) {
            let expanded = this.props.timerPanelItemStore!.expandedTimerPanelIds.slice();
            if (expanded.includes(id)) {
                expanded = expanded.filter((s) => s !== id)
            } else {
                expanded.push(id);
            }
            this.props.timerPanelItemStore!.setExpendedTimerPanels(expanded);
            if (expanded.includes(id)) {
                setTimeout(() => {
                    let elem: HTMLElement = ReactDOM.findDOMNode(this) as HTMLElement;
                    scrollIntoView(elem, { behavior: 'smooth', scrollMode: 'if-needed' })
                }, 0);
            }
        } else {
            return;
        }
    }

    timerTick = () => {
        this.setState({durTxt: this.getTimerDurationTxt()})
    }
    
    getTimerDurationTxt = () => {
        const timer = this.props.timer;
        let secs = timer.totalDuration || 0;
        const includeSubmitted
            = this.props.timerStore!.rootStore.appStore.features.EpochConfigTimeSegmentsSubmittedVisible;
        if (includeSubmitted) {
            secs = secs - (timer.convertedDuration || 0);
        }
        if (timer.active) {
            let da = Math.floor((new Date().getTime() - new Date(timer.startedOn!).getTime()) / 1000);
            secs += da;
        }
        // secs = Math.floor(secs / 1000);
        let hours   = Math.floor(secs / 3600);
        let minutes = Math.floor((secs - (hours * 3600)) / 60);
        let seconds = secs - (hours * 3600) - (minutes * 60);
        let hstring = `${hours}`;
        let sstring = `${seconds}`;
        let mstring = `${minutes}`;
        if (hours   < 10) {hstring   = '0' + hstring; }
        if (minutes < 10) {mstring = '0' + mstring; }
        if (seconds < 10) {sstring = '0' + sstring; }
        return `${hstring}:${mstring}:${sstring}`
    }
    
    render() {
        const { timer, timerChunks } = this.props;
        const { timerTemplate, timerMatter, expandedTimerPanelIds } = this.props.timerPanelItemStore!;
        const templateName = timerTemplate ? timerTemplate.name : '';
        const matterDetails = timerMatter ? parseCode(timerMatter.number, timerMatter.name) : '';
        const expanded = expandedTimerPanelIds.includes(timer.id!);
        const timerText = timer.active ? this.state.durTxt : this.getTimerDurationTxt();
        
        return (
            <div>
                <Styled.Container
                    elevation={1}
                    square={true}
                    expanded={expanded}
                    type={'timer'}
                >
                    <MenuProvider id={`menu_${timer.id}`} >
                        <Styled.OfflineHeader
                            type={'timer'}
                            onClick={() => this.expandTimerPanel(timer.id)}
                        >
                            <Tooltip title={timer.name}>
                                <HeaderItem>{timer.name}</HeaderItem>
                            </Tooltip>
                            <HeaderItem>{timerText}</HeaderItem>
                            <Tooltip title={templateName}>
                                <HeaderItem>{templateName}</HeaderItem>
                            </Tooltip>
                            <Tooltip title={matterDetails}>
                                <HeaderItem>{matterDetails}</HeaderItem>
                            </Tooltip>
                            {expanded ? <div title="Collapse"><KeyboardArrowUp/></div>
                                : <div title="Expand"><KeyboardArrowDown/></div>}
    
                        </Styled.OfflineHeader>
                    </MenuProvider>
                </Styled.Container>
                {expanded &&
                <Styled.TimerChunksContainer square={true}>
                    {timerChunks ?
                        <div>
                            {
                                timerChunks.map((chunk) =>
                                    <Styled.OfflineHeader type={'chunk'} key={chunk.id as number}>
                                        <HeaderItem>
                                            {(new Date(chunk.startTime)).toLocaleDateString()}
                                        </HeaderItem>
                                        <HeaderItem>
                                            {getDurStringBetween(new Date(chunk.startTime), new Date(chunk.endTime))}
                                        </HeaderItem>
                                        <Tooltip title={chunk.description}>
                                            <HeaderItem>
                                                {chunk.description}
                                            </HeaderItem>
                                        </Tooltip>
                                    </Styled.OfflineHeader>
                                )
                            }
                        </div> :
                        <></>
                    }
                </Styled.TimerChunksContainer>
                }
            </div>
        );
    }
}

export default TimerPanelItem;
// import { observable, action } from 'mobx';
import { Narrative } from 'api/types/types'
import { onOpen } from '@fulcrumgt/mobx-store-utils'
import { DialogRootStore } from './dialog.root.store';
import { observable, action } from 'mobx';

export default class CreateNarrativeCodeDialogStore extends DialogRootStore {
    @observable narrativeKey: string = ''
    @observable narrativeReplacement: string = ''
    @observable invalid: Record<string, string | boolean> = {};
    @observable loading = false
    private narratives: Narrative[] = []
    
    @action
    setNarrativeKey = (key: string) => {
        this.narrativeKey = key;
        this.resetInvalid()
    }
    
    @action
    setNarrativeReplacement = (rep: string) => {
        this.narrativeReplacement = rep;
        this.resetInvalid()
    }
    
    @action
    validate() {
        this.invalid.narrativeReplacement = this.narrativeReplacement.trim() === '' ? 'Required field' : false
        this.invalid.narrativeKey = 
            this.narrativeKey === '' ? 'Required field' :
                this.narrativeKey.length < 2 ? 'Narrative Code cannot be less than 2 characters' :
            ( this.narrativeKey.indexOf(' ') > -1 ? 'No spaces allowed' : 
            ( this.narratives.length > 0 && this.isKeyDuplicate(this.narrativeKey) ? 'Duplicate code' : false))
        
        return !this.invalid.narrativeReplacement && !this.invalid.narrativeKey;
    }
    
    @action validateGlobalDuplicate(key: string): boolean {
        let obj = this.narratives.find(n =>
            n.key.toLowerCase() === key.toLowerCase())
        if (obj) {
            if (!obj.global) {
                return true;
            } else {
                this.invalid.narrativeKey = '\\' + obj.key + ' will override existing global narrative';
                return false;
            }
        } else {
            return false;
        }
    }

    @onOpen()
    dialogOpen() {
        this.resetInvalid()
        this.fetchNarratives()
    }
    
    @action
    private resetInvalid() {
        this.invalid = { 
            narrativeReplacement: false,
            narrativeKey: false
        };
    }
    
    @action
    private fetchNarratives() {
        this.loading = true
        this.rootStore.api.Narrative.getAllNarratives().then(
            narratives => { 
                this.narratives = narratives
                this.loading = false
            }
        )
    }
    
    private isKeyDuplicate(key: string): boolean {
        return this.narratives.findIndex(n =>
            (n.key.toLowerCase() === key.toLowerCase()) && !n.global) > -1;
    }
    
}